import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { useConferenceList } from '../../../../server';
import { REFRESH_ASSIGNMENT_LIST } from '../../../DST/DSTL001/constant';
import { REFRESH_POSTS_LIST } from '../../../DST/DSTSL001/constant';
import { goToConference } from '../../../DST/DSTT001/funtions';
import { updateMenu } from '../../../menu/action';
import { getIsTeacher, getUserInfo } from '../../account';
import { ClassesDropDown } from '../../classes';
import { getClasses, getHiddenMenus } from '../../classes/functions';
import { TOGGLE_POPUP } from '../../popup';
import { LiveEnquiries } from './LiveEnquiries';
const ProfileStyled = styled.div `
	& {
		padding: ${({ $isMobile }) => ($isMobile ? '40px 0' : '80px 0')};
		display: flex;
		flex-direction: ${({ $isMobile }) => ($isMobile ? 'column' : 'row')};
		align-items: center;
		justify-content: space-evenly;
		align-self: stretch;
		flex: 1;
		position: relative;
		${({ $isMobile }) => $isMobile &&
    `
			gap: 24px;
			width: 100%;
    		overflow: hidden;
		`}
	}

	${({ $isTablet }) => $isTablet &&
    `
		width: 80%;
	`}

	.left {
		z-index: 1;
		width: 504px;
		min-height: 182px;
		min-width: 60%;

		${({ $isMobile }) => $isMobile &&
    `
			padding: 0 20px;
		display: flex;
		justify-content: center;
		max-width: 100%;
		`}

		${({ $isTablet }) => $isTablet &&
    `
			min-width: 40%;
			max-width: 50%;
		`}
	}

	.profile {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: ${({ $isMobile, $isTablet }) => ($isMobile ? '8px' : $isTablet ? '12px' : '24px')};
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		max-width: ${({ $isMobile }) => ($isMobile ? '90%' : '620px')};
		${({ $isMobile }) => $isMobile && 'width: 100%;'}

		.name-info {
			display: flex;
			flex-direction: row;
			gap: 9px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}

		.dropdown .placeholder {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: ${({ $isTablet, $isMobile }) => $isMobile ? '20px' : $isTablet ? '30px' : '36px'};
			line-height: 46px;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
		}

		.alram-today {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.info {
				display: flex;
				flex-direction: row;
				gap: 16px;
				align-items: center;
				justify-content: flex-start;
				align-self: stretch;
				flex-shrink: 0;
				position: relative;

				p {
					color: var(--color-white, #ffffff);
					text-align: left;
					font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
					font-size: var(--h5-font-size, 16px);
					line-height: var(--h5-line-height, 24px);
					letter-spacing: var(--h5-letter-spacing, -0.02em);
					font-weight: var(--h5-font-weight, 700);
					position: relative;
					flex: 1;
				}
			}
		}

		.btn-today {
			background: #2c6273;
			border-radius: 100px;
			padding: ${({ $isTablet, $isMobile }) => $isTablet || $isMobile ? '4px 16px' : '8px 16px'};
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
		}

		.txt {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
			${({ $isTablet, $isMobile }) => $isTablet || $isMobile
    ? `
					font-size: var(--h5-font-size, 16px);
					line-height: var(--h5-line-height, 24px);
					letter-spacing: var(--h5-letter-spacing, -0.02em);
					font-weight: var(--h5-font-weight, 700);
					`
    : `
				
				font-size: var(--h3-font-size, 24px);
				line-height: var(--h3-line-height, 36px);
				letter-spacing: var(--h3-letter-spacing, -0.02em);
				font-weight: var(--h3-font-weight, 700);
			`}

			position: relative;
			flex: 1;
		}

		.today {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: ${({ $isTablet, $isMobile }) => $isTablet || $isMobile
    ? "var(--b4-font-family, 'NotoSansKr-Bold', sans-serif)"
    : "var(--h5-font-family, 'NotoSansKr-Bold', sans-serif)"};
			font-size: ${({ $isTablet, $isMobile }) => $isTablet || $isMobile ? 'var(--b4-font-size, 14px)' : 'var(--h5-font-size, 16px)'};
			line-height: ${({ $isTablet, $isMobile }) => $isTablet || $isMobile
    ? 'var(--b4-line-height, 28px)'
    : 'var(--h5-line-height, 24px)'};
			letter-spacing: -0.02em;
			font-weight: ${({ $isTablet, $isMobile }) => $isTablet || $isMobile
    ? 'var(--b4-font-weight, 700)'
    : 'var(--h5-font-weight, 700)'};
			position: relative;
		}

		.rectangle-13218 {
			background: #7a8b96;
			flex-shrink: 0;
			width: 1px;
			height: 28px;
			position: relative;
		}

		.btn-today2 {
			cursor: pointer;
			border-radius: 100px;
			border-style: solid;
			border-color: var(--color-white, #ffffff);
			border-width: ${props => (props.$isMobile ? '1px' : '2px')};
			padding: 4px 16px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		}

		.div {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: ${({ $isTablet, $isMobile }) => $isTablet || $isMobile
    ? "var(--b4-font-family, 'NotoSansKr-Regular', sans-serif)"
    : "var(--b3-font-family, 'NotoSansKr-Regular', sans-serif)"};
			font-size: ${({ $isTablet, $isMobile }) => $isTablet || $isMobile ? 'var(--b4-font-size, 14px)' : 'var(--b3-font-size, 16px)'};
			line-height: ${({ $isTablet, $isMobile }) => $isTablet || $isMobile
    ? 'var(--b4-line-height, 28px)'
    : 'var(--b3-line-height, 32px)'};
			letter-spacing: -0.02em;
			font-weight: ${({ $isTablet }) => $isTablet ? 'var(--b4-font-weight, 400)' : 'var(--b3-font-weight, 400)'};
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.ic-back {
			flex-shrink: 0;
			width: 16px;
			height: 16px;
			position: relative;
			overflow: visible;
		}
	}

	.class-info {
		background: rgba(16, 16, 16, 0.3);
		border-radius: 16px;
		padding: ${props => (props.$isMobile ? '24px' : '12px 24px')};
		display: flex;
		flex-direction: column;
		gap: 4px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		box-shadow: 0px 0px 12px 0px rgba(32, 32, 32, 0.25);
		overflow: hidden;

		.subject {
			display: flex;
			flex-direction: row;
			gap: 19px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.title {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: var(--b3-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			letter-spacing: -0.02em;
			font-weight: var(--b3-font-weight, 700);
			position: relative;
			width: 64px;
		}

		.txt2,
		.txt3 {
			color: var(--color-white, #ffffff);
			text-align: left;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			letter-spacing: -0.02em;
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			width: ${({ $isMobile, $isTablet }) => $isMobile ? '200px' : $isTablet ? 'auto' : '302px'};
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-end;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		${({ $isMobile }) => $isMobile &&
    `
		    flex: 1;
			width: 80%;
			gap: 16px;
		`}

		${({ $isTablet }) => $isTablet &&
    `
			min-width: 33%;
		`}

		.background {
			background: #217081;
			border-radius: 50%;
			flex-shrink: 0;
			height: ${({ $isTablet, $isMobile }) => ($isMobile ? '139px' : '241px;')};
			position: relative;
			filter: blur(80px);
			position: absolute;
			width: 100%;
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
		}
	}

	.btn-classroom-in {
		background: linear-gradient(94.13deg, rgba(70, 148, 157, 1) 0%, rgba(37, 132, 143, 1) 100%);
		border-radius: ${({ $isTablet, $isMobile }) => ($isMobile ? '8px' : '16px')};
		padding: ${({ $isTablet, $isMobile }) => $isMobile ? '16px 0' : $isTablet ? '32px 10px 32px 10px;' : ' 46px 68px 46px 68px;'};
		display: flex;
		flex-direction: row;
		gap: 9px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		${({ $isMobile, $isTablet }) => ($isMobile || $isTablet) &&
    `
			width: 100%;
		`}

		&.on {
			background: linear-gradient(
				94.13deg,
				rgba(70, 148, 157, 1) 0%,
				rgba(37, 132, 143, 1) 100%
			);

			p {
				color: var(--color-white, #ffffff);
			}
		}

		&.disabled {
			cursor: default;
			background: var(--color-w_grey, #83868a);
			p {
				color: var(--color-white, #ffffff);
			}
		}

		p {
			text-align: center;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: ${({ $isTablet, $isMobile }) => $isMobile ? '20px' : $isTablet ? '24px;' : ' 40px;'};
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
			// width: 290px;
		}
	}

	.btn-classroom-personal {
		border-radius: ${({ $isTablet, $isMobile }) => ($isMobile ? '8px' : '14px')};
		padding: ${({ $isTablet, $isMobile }) => $isMobile ? '16px 0' : $isTablet ? ' 10px;' : ' 16px 68px 16px 68px;'};
		display: flex;
		flex-direction: row;
		gap: 9px;
		align-items: flex-start;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		${({ $isMobile, $isTablet }) => ($isMobile || $isTablet) &&
    `
			width: 100%;
		`}

		&.on {
			background: linear-gradient(
				91.14deg,
				rgba(243, 244, 248, 1) 0%,
				rgba(218, 221, 237, 1) 100%
			);
			p {
				color: var(--color-grey, #666666);
			}
		}

		&.disabled {
			cursor: default;
			background: var(--color-w_grey, #83868a);
			p {
				color: var(--color-white, #ffffff);
			}
		}

		p {
			text-align: center;
			font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: ${({ $isTablet, $isMobile }) => $isMobile ? '20px' : $isTablet ? '18px;' : '24px;'};
			line-height: 150%;
			letter-spacing: var(--h3-letter-spacing, -0.02em);
			font-weight: var(--h3-font-weight, 700);
			position: relative;
			// width: 290px;
		}
	}
`;
export const Profile = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { classesId, isMobile, isTablet } = useGlobalState();
    const user = useSelector((state) => getUserInfo(state));
    const classes_data = useSelector((state) => getClasses(state));
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const hiddenMenus = useSelector((state) => getHiddenMenus(state));
    const [classes, setClasses] = useState(classesId);
    const [liveRoomName, setLiveRoomName] = useState();
    const [liveRoomId, setLiveRoomId] = useState();
    const [indviualLive, setIndviualLive] = useState([]);
    const [isOpnenLive, setIsOpnenLive] = useState(false);
    // 현재 시간
    const startOfDay = useMemo(() => moment().startOf('day').format('YYYY-MM-DDTHH:mm:ssZ'), []);
    // 오늘의 끝 시간 (23:59:59)
    const endOfDay = useMemo(() => moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ'), []);
    const { data, refetch } = useConferenceList({
        start_before: endOfDay,
        start_after: startOfDay,
        orderby: ['startline DESC'],
    });
    useEffect(() => {
        APP.eventManager.publish(REFRESH_POSTS_LIST);
        APP.eventManager.publish('REFETCH_QNA_LIST');
        APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
        APP.eventManager.publish('REFRESH_REPLY_LIST');
    }, [classesId]);
    useEffect(() => {
        refetch();
    }, [classesId, endOfDay, startOfDay]);
    useEffect(() => {
        if (data) {
            const { total_count, items } = data;
            const now = moment();
            if (total_count > 0) {
                //setIsLiveRoom(moment().isSame(items.start_time, 'day'));
                //1:1 질의응답 활성화
                // setIsOpenEnquiries(items[total_count - 1].status === 'closed' ? true : false);
                const closed_items = items.filter(i => ['closed'].includes(i.status));
                setIndviualLive(closed_items);
                const filter_items = items.filter(i => ['held', 'booked'].includes(i.status));
                // setOpenLive(filter_items);
                if (filter_items && filter_items.length > 0) {
                    // 현재 시간
                    // `start_time`이 현재 시간과 가장 가까운 값을 찾기
                    const closestData = filter_items.reduce((closest, current) => {
                        const currentDiff = Math.abs(moment(current.start_time)
                            .subtract(current.pre_entering_duration, 'minutes')
                            .diff(now));
                        const closestDiff = Math.abs(moment(closest.start_time)
                            .subtract(closest.pre_entering_duration, 'minutes')
                            .diff(now));
                        return currentDiff < closestDiff ? current : closest;
                    });
                    // 시간 차이를 밀리초 단위로 계산
                    const diffMilliseconds = moment(closestData.start_time).diff(now, 'minutes');
                    const isOpen = diffMilliseconds < closestData.pre_entering_duration &&
                        closestData.status !== 'closed'
                        ? true
                        : false;
                    setLiveRoomId(closestData.id);
                    setIsOpnenLive(isOpen);
                    setLiveRoomName(closestData.title);
                }
                return;
            }
        }
        setLiveRoomId(undefined);
        setLiveRoomName(undefined);
    }, [data]);
    // const { userName, userId } = useMemo(() => {
    // 	if (!user) {
    // 		return {
    // 			userName: '',
    // 			userId: '',
    // 		};
    // 	} else {
    // 		return {
    // 			userName: user.nickname,
    // 			userId: hideName(user.auth_name),
    // 		};
    // 	}
    // }, [user]);
    // const { classesTitle, classesDuration } = useMemo(() => {
    // 	if (!classes_data) {
    // 		return {
    // 			classesTitle: '',
    // 			classesDuration: '',
    // 		};
    // 	}
    // 	return {
    // 		classesTitle:
    // 			classes_data.title + (classes_data.subject ? '(' + classes_data.subject + ')' : ''),
    // 		classesDuration:
    // 			moment(classes_data.startline).format('YY/MM/DD (dd)') +
    // 			' ~ ' +
    // 			moment(classes_data.deadline).format('YY/MM/DD (dd)'),
    // 	};
    // }, [classes_data]);
    const openEnquiries = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            className: `${indviualLive.length === 0 ? 'home-live-empty' : 'home-live'} ${isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop'}`,
            component: LiveEnquiries,
            componentProps: {
                liveRoom: indviualLive,
            },
            title: '1:1 Live 문의',
            height: 'auto',
        });
    };
    const goToLiveRoom = () => {
        dispatch(updateMenu('liveRoom', {
            menu: 'reservation',
            detail: 'list',
        }));
    };
    useEffect(() => {
        if (!classes || classesId === classes || classes === 'select')
            return;
        navigate(`/class/${classes}`);
    }, [classes]);
    if (!classes_data)
        return null;
    return (React.createElement(ProfileStyled, { "$isMobile": isMobile, "$isTablet": isTablet },
        React.createElement("div", { className: "left" },
            React.createElement("div", { className: "profile" },
                React.createElement("div", { className: "name-info" },
                    React.createElement(ClassesDropDown, { userId: user.id, userTenantId: user.tenant_id, defaultClasses: classesId || 'select', setClasses: setClasses })),
                !hiddenMenus.includes('reservation') && (React.createElement("div", { className: "class-info" },
                    React.createElement("div", { className: "alram-today" },
                        React.createElement("div", { className: "btn-today" },
                            React.createElement("div", { className: "today" }, "TODAY")),
                        React.createElement("div", { className: "info" },
                            React.createElement("div", { className: "txt overText2" }, liveRoomName ? liveRoomName : '예정된 강의가 없습니다'),
                            liveRoomName && (React.createElement("div", { className: "btn-today2", onClick: goToLiveRoom },
                                React.createElement("div", { className: "div" }, "\uB354\uBCF4\uAE30"),
                                React.createElement(Icon, { className: "arrow-right", icon: "arrow", size: 24, fill: "#ffffff" }))))))))),
        React.createElement("div", { className: "right" }, !hiddenMenus.includes('reservation') && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "background" }),
            React.createElement(Button, { className: `btn-classroom-in ${(isTeacher || isOpnenLive) && liveRoomId ? 'on' : 'disabled'}`, text: "Live \uAC15\uC758\uC2E4 \uC785\uC7A5", onClick: () => (isTeacher || isOpnenLive) &&
                    liveRoomId &&
                    goToConference(liveRoomId) }),
            React.createElement(Button, { className: `btn-classroom-personal ${indviualLive.length > 0 ? 'on' : 'disabled'}`, text: "1:1 \uB77C\uC774\uBE0C \uC9C8\uC758\uC751\uB2F5", onClick: () => indviualLive.length > 0 && openEnquiries() }))))));
});
