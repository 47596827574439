import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../../../../common';
import { closeWindow } from '../../app';
import { getUserAuthName } from '../functions';
import { deleteCookie } from './Login';
import styled from 'styled-components';
const MyPageDropDownStyled = styled.div `
	.user {
		background: var(--color-white, #ffffff);
		border-radius: 20px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		padding: 6px 20px 6px 20px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		p {
			color: var(--color-black, #101010);
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b5-font-size, 12px) !important;
			line-height: var(--b5-line-height, 22px) !important;
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			min-width: unset;
		}
	}
`;
const MyPageDropDown = React.memo(({ isSidebar = false }) => {
    const [dropValue, setDropValue] = React.useState(undefined);
    const user_name = useSelector((state) => getUserAuthName(state));
    useEffect(() => {
        if (!dropValue)
            return;
        if (dropValue.id === 'account.logout') {
            localStorage.clear();
            deleteCookie('refresh_token');
            closeWindow();
        }
    }, [dropValue]);
    return (React.createElement(MyPageDropDownStyled, null,
        React.createElement(Button, { className: "user", text: "\uB85C\uADF8\uC544\uC6C3", onClick: () => {
                localStorage.clear();
                deleteCookie('refresh_token');
                closeWindow();
            } })));
    // return (
    // 	<DropDown
    // 		className="user dropdown-mini"
    // 		value={{ id: user_name, title: '로그아웃' }}
    // 		list={[
    // 			// { id: 'account.information', title: 'account.information' },
    // 			{ id: 'account.logout', title: 'account.logout' },
    // 		]}
    // 		setDropValue={setDropValue}
    // 		styles={{
    // 			dropWrap: {
    // 				width: 130,
    // 			},
    // 			bodyItem: {
    // 				width: 143,
    // 			},
    // 		}}
    // 		option={{
    // 			downIcon: {
    // 				size: 10,
    // 			},
    // 		}}
    // 		isSidebar={isSidebar}
    // 	/>
    // );
});
export default MyPageDropDown;
