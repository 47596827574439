import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import { Icon } from '../../../../common';
/**
 * DateRangeSelector 컴포넌트
 */
const DateRangeSelector = ({ defaultStartDate, defaultEndDate, setStartDate, setEndDate }) => {
    // const [dateRangeDisplay, setDateRangeDisplay] = useState('날짜를 선택해주세요.');
    const startRef = useRef(null);
    const endRef = useRef(null);
    // 기본 시작 및 종료 날짜가 있는 경우 입력 값을 초기화
    useEffect(() => {
        if (defaultStartDate) {
            startRef.current.value = defaultStartDate;
            handlerCheckStartDate(defaultStartDate);
        }
        if (defaultEndDate) {
            endRef.current.value = defaultEndDate;
            handlerCheckEndDate(defaultEndDate);
        }
    }, [defaultStartDate, defaultEndDate]);
    // 시작 날짜가 변경될 때 호출
    const handlerCheckStartDate = newStartDate => {
        const today = moment().startOf('day');
        const newStartMoment = moment(newStartDate, 'YYYY-MM-DD');
        const endDateMoment = endRef.current.value
            ? moment(endRef.current.value, 'YYYY-MM-DD')
            : null;
        // 시작 날짜가 오늘 이후일 경우 오늘 날짜로 설정
        if (newStartMoment.isAfter(today)) {
            newStartMoment.set(today.toObject());
        }
        // 종료 날짜보다 시작 날짜가 클 경우 종료 날짜를 시작 날짜로 맞춤
        if (endDateMoment && newStartMoment.isAfter(endDateMoment)) {
            setEndDate(newStartDate);
            endRef.current.value = newStartDate;
        }
        const formattedStartDate = newStartMoment.format('YYYY-MM-DD');
        setStartDate(formattedStartDate);
        startRef.current.value = formattedStartDate;
        // updateDateRangeDisplay(formattedStartDate, endRef.current.value);
    };
    // 종료 날짜가 변경될 때 호출
    const handlerCheckEndDate = newEndDate => {
        const today = moment().startOf('day');
        const startDateMoment = startRef.current.value
            ? moment(startRef.current.value, 'YYYY-MM-DD')
            : null;
        let newEndMoment = moment(newEndDate, 'YYYY-MM-DD');
        // 종료 날짜가 오늘 이후일 경우 오늘 날짜로 설정
        if (newEndMoment.isAfter(today)) {
            newEndMoment = today.clone();
        }
        // 시작 날짜보다 종료 날짜가 작을 경우 시작 날짜로 설정
        if (startDateMoment && newEndMoment.isBefore(startDateMoment)) {
            newEndMoment = startDateMoment.clone();
        }
        const formattedEndDate = newEndMoment.format('YYYY-MM-DD');
        setEndDate(formattedEndDate);
        endRef.current.value = formattedEndDate;
        // updateDateRangeDisplay(startRef.current.value, formattedEndDate);
    };
    // // 날짜 범위를 화면에 표시
    // const updateDateRangeDisplay = (start, end) => {
    // 	if (start && end) {
    // 		const startMoment = moment(start, 'YYYY-MM-DD');
    // 		const endMoment = moment(end, 'YYYY-MM-DD');
    // 		const daysDiff = endMoment.diff(startMoment, 'days');
    // 		if (daysDiff < 0) {
    // 			setDateRangeDisplay('유효하지 않은 날짜 범위입니다.');
    // 		} else if (daysDiff === 0) {
    // 			setDateRangeDisplay(`선택된 날짜: ${startMoment.format('YYYY년 MM월 DD일')}`);
    // 		} else {
    // 			setDateRangeDisplay(
    // 				`${startMoment.format('YYYY년 MM월 DD일')} - ${endMoment.format(
    // 					'YYYY년 MM월 DD일'
    // 				)} (${daysDiff + 1}일)`
    // 			);
    // 		}
    // 	} else {
    // 		setDateRangeDisplay('날짜를 선택해주세요.');
    // 	}
    // };
    return (React.createElement(React.Fragment, null,
        React.createElement("input", { type: "date", ref: startRef, onChange: e => handlerCheckStartDate(e.target.value), min: "2000-01-01", max: "2100-12-31" }),
        React.createElement(Icon, { icon: "dash", width: 12 }),
        React.createElement("input", { type: "date", ref: endRef, onChange: e => handlerCheckEndDate(e.target.value), min: "2000-01-01", max: "2100-12-31" })));
};
export default React.memo(DateRangeSelector);
