import React from 'react';
import { DropDown } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const TYPE_DROPDOWN_LIST = [
    { id: 'select', title: 'assignment.questionType' },
    { id: 'OMR', title: 'assignment.OMR' },
    { id: 'OMR-free', title: 'assignment.OMR-free' },
    { id: 'attachment', title: 'assignment.attachment' },
    { id: 'survey', title: 'assignment.survey' },
];
export const AssignmentTypeDropdown = React.memo(({ type, setType }) => {
    const { isTablet, isMobile } = useGlobalState();
    const [value, setValue] = React.useState({
        id: 'select',
        title: 'assignment.questionType',
    });
    React.useEffect(() => {
        if (type !== value.id)
            setValue(TYPE_DROPDOWN_LIST.find(item => item.id === type));
    }, [type]);
    const handlerUpdateDrop = (item) => {
        setType(item.id);
        setValue(item);
    };
    return (React.createElement(DropDown, { className: "dropdown-tablet-small", value: value, list: TYPE_DROPDOWN_LIST, styles: {
            ...(isMobile ? {} : { dropWrap: { width: isTablet ? 120 : 150 } }),
            itemWrap: {
                top: isTablet ? '50px' : '45px',
            },
        }, setDropValue: handlerUpdateDrop }));
});
