import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DropDown } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { getClasses } from '../../../base/classes';
export const GradeDropDown = React.memo(({ setDropValue }) => {
    const { isMobile } = useGlobalState();
    const startline = useSelector((state) => getClasses(state)?.startline);
    const [dropList, setDropList] = React.useState([]);
    const [value, setValue] = React.useState();
    useEffect(() => {
        const today = moment();
        const months = [];
        let currentDate = moment(startline);
        while (currentDate.isSameOrBefore(today, 'month')) {
            const diffMonths = today.diff(currentDate, 'months');
            const title = `${currentDate.year()}년 ${currentDate.month() + 1}월 ${currentDate.isSame(today, 'month') ? '' : `(${diffMonths + 1}개월 전)`}`;
            months.push({ id: currentDate.unix(), title });
            // Move to the next month
            currentDate.add(1, 'month');
        }
        setDropList(months.reverse()); // 최신 달부터 과거로 정렬
        setValue(months[0]); // 최신 달로 초기화
    }, [startline]);
    useEffect(() => {
        setDropValue(value?.id);
    }, [value]);
    return (React.createElement(DropDown, { className: "dropdown-small", list: dropList, value: value, setDropValue: setValue, styles: {
            titleWrap: {
                ...(isMobile ? { width: '100%' } : { minWidth: 200, maxWidth: 200 }),
            },
        } }));
});
