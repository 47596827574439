import _ from 'lodash';
import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../../Colors';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button, Icon, Table } from '../../../../../common';
import { useContents } from '../../../../../server';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { AttachContentButton } from '../../../DSTS006/DSTS006_List_Child/AttachContentButton';
import { ContentTable } from '../../../DSTS006/DSTS006_List_Child/ContentTable';
import { TestSetting } from './TestSetting';
const StudySettingStyle = styled.div `
	width: 100%;
	display: flex;
	flex-direction: column;

	.underbar_line {
		cursor: pointer;
		color: var(--color-accent, #e7424c);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 14px;
		line-height: 28px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		width: 38px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	flex: 1;
	height: 100%;
	gap: 6px;

	.no-question-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b1-font-size, 20px);
				line-height: var(--b1-line-height, 42px);
				font-weight: var(--b1-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}
	.live-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		flex: 1;
		overflow: hidden;
	}
	.scroll-content {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow-y: auto;
	}

	.item {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 1px 0px 0px 0px;
		padding: 12px 0px 12px 0px;
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		flex: 1;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&:nth-child(1) {
			border-width: 0px;
		}

		&:nth-last-child(1) {
			border-width: 0px;
		}
	}

	.bold {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		align-self: stretch;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.underbar_line {
		text-align: end;
		width: 100%;
	}

	.btn-container {
		width: 100%;
		padding-bottom: 4px;
		button {
			flex: 1;
		}
		label {
			display: flex;
			flex: 1;
		}
	}
`;
const desktopStyles = css `
	flex: 1;
	height: 100%;
	min-width: 320px;
	gap: 13px;

	.no-question-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b1-font-size, 20px);
				line-height: var(--b1-line-height, 42px);
				font-weight: var(--b1-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	.live-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;
		flex: 1;
		overflow: hidden;
	}

	.table-header {
		background: var(--color-silver, #f3f4f8);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px 0px 0px 0px;
		padding: 10px 16px 10px 16px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		color: var(--color-grey, #666666);
		text-align: left;
		font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		font-weight: var(--b3-font-weight, 400);
	}

	.scroll-content {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow-y: auto;
	}

	.item {
		padding: 8px 16px 8px 16px;
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
`;
export const StudySettingTable = React.memo(({ isDetail, isModify, mode, initItem, setItem, setBackground, }) => {
    const { t } = useTranslation();
    const { isMobile, isDesktop } = useGlobalState();
    const [updateCheckList, setUpdateCheckList] = useState([]);
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [count, setCount] = useState(10);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // [자료명, 비고] : 예약 페이지
        // [자료명] : 상세보기 페이지
        const headTitleList = [
            { title: 'dataName', space: 2, sort: false, maxWidth: 200 },
            { title: 'type', space: 1, sort: false },
            ...(isDetail ? [] : [{ title: 'etc', space: 1, sort: false }]),
        ];
        const tableReadDataKeys = [
            { title: 'link', space: 2, sort: false, maxWidth: 200 },
            { title: 'type', space: 1, sort: false },
            ...(isDetail ? [] : [{ title: 'etc', space: 1, sort: false }]),
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    useEffect(() => {
        if (isDetail)
            setUpdateCheckList(initItem);
    }, [initItem]);
    useEffect(() => {
        if (!isDetail)
            setUpdateCheckList(initItem);
    }, []);
    useEffect(() => {
        if (mode !== 'mock_test') {
            const testDataMap = new Map(testData.map(i => [i.id, i.type]));
            updateCheckList.map(item => {
                if (testDataMap.get(item) === 'background')
                    deleteCheckList(item);
            });
        }
    }, [mode, testData]);
    useEffect(() => {
        if (!isDetail || (isDesktop && isModify)) {
            // Use Set for efficient lookups
            const testDataMap = new Map(testData.map(i => [i.id, i.type]));
            // Find the first item in updateCheckList that matches the 'background' type
            const filterBack = updateCheckList.filter(item => testDataMap.get(item) === 'background');
            setBackground(filterBack);
            setItem(updateCheckList);
        }
    }, [testData, updateCheckList, isDetail, isModify]);
    const { data, refetch } = useContents({
        id: updateCheckList,
        offset: page === 0 ? 0 : page * count,
        limit: count,
        status: ['converted', 'uploaded'],
        orderby: ['creation_time desc'],
    });
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    const [saveUpdateCheckList, setSaveUpdateCheckList] = useState([]);
    const [saveTestData, setSaveTestData] = useState([]);
    useEffect(() => {
        if (updateCheckList && updateCheckList.length > 0) {
            if (_.isEqual(updateCheckList, saveUpdateCheckList)) {
                setTestData(saveTestData);
            }
            else {
                setSaveUpdateCheckList(updateCheckList);
            }
            refetchList();
        }
        else {
            setTestData([]);
            setTotalCount(0);
        }
    }, [updateCheckList, page]);
    useEffect(() => {
        if (data) {
            const { total_count, found_count, items } = data;
            if (total_count > 0 && found_count === 0) {
                const maxPage = Math.max(0, Math.ceil(total_count / 10) - 1);
                setPage(maxPage);
                return;
            }
            if (total_count > 0) {
                setTestData(items);
                setSaveTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        setTestData([]);
        setTotalCount(0);
    }, [data]);
    const deleteCheckList = (id) => {
        setUpdateCheckList(prev => prev.filter(item => item !== id));
    };
    //학습자료 불러오기 가져오기 버튼 클릭 시 POPUP
    const openClassDataAdd = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: ContentTable,
            componentProps: {
                isReserve: true,
                defaultCheckList: updateCheckList,
                setUpdateCheckList,
                isPopUp: true,
                typeList: mode === 'mock_test'
                    ? ['document', 'video', 'background']
                    : ['document', 'video'],
            },
            width: 757,
            title: t('conference.loadClassesData'),
        });
    };
    const renderTitle = useMemo(() => (React.createElement(TestSetting, { tableName: t('conference.dataSetting') }, !isDetail && (React.createElement(React.Fragment, null,
        React.createElement(AttachContentButton, { menu: "document", isReserve: true, setUpdateCheckList: setUpdateCheckList }),
        React.createElement(Button, { className: "btn_default sm", text: t('conference.classDataEnroll'), onClick: openClassDataAdd }))))), [testData, isDetail, openClassDataAdd]);
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, style: {
                flex: !isMobile && headItem.space,
                maxWidth: headItem?.maxWidth,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, headItem.title === 'etc' ? (React.createElement("p", { className: "underbar_line", onClick: () => deleteCheckList(item.id) }, t('common.delete'))) : headItem.title === 'link' ? (React.createElement("p", { className: "overText2", style: { maxWidth: isMobile ? '150px' : 'unset' } }, item.name)) : headItem.title === 'type' ? (React.createElement("p", { className: "overText2" }, t(`supportRoom.${item.type}`))) : (React.createElement("p", { className: "overText2" }, item[headItem.title])))));
    };
    const renderEmpty = useMemo(() => (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } },
        React.createElement(Icon, { icon: "detail-delete", size: 36, fill: Colors.w_grey }),
        React.createElement("p", null, t('supportRoom.contentsEmpty')))), []);
    const handlerMoreData = (page) => {
        setPage(page);
    };
    return (React.createElement(StudySettingStyle, { "$isMobile": isMobile, className: "study-setting-table" },
        React.createElement(Table, { className: "live-list-002", showCheckBox: false, showIndex: isMobile ? false : true, isInifinite: false, data: testData, totalCount: totalCount, page: page, sortDefault: "id", headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, forceShowheader: isMobile ? false : true, renderEmpty: isDetail && totalCount <= 0 && renderEmpty, handlerMoreData: handlerMoreData })));
});
