import React, { useMemo } from 'react';
import { Colors } from '../../Colors';
import { Button } from './Button';
import { useGlobalState } from '../../GlobalStateProvider';
const Paging = React.memo(({ defaultShowCount, showCount, page, totalCount, handlerMoreData }) => {
    const { isMobile } = useGlobalState();
    // 한 페이지에 표시할 페이지 수
    const pageSize = defaultShowCount ? defaultShowCount : isMobile ? 5 : 10;
    // 전체 페이지 수 계산
    const totalPages = useMemo(() => Math.ceil(totalCount / showCount), [totalCount, showCount, page]);
    // 현재 페이지 그룹의 첫 번째 페이지 번호
    const startPage = useMemo(() => Math.floor(page / pageSize) * pageSize, [page, pageSize]);
    // 페이지 이동 핸들러
    const handlePageChange = (newPage) => {
        handlerMoreData(newPage);
    };
    // 페이지 버튼 렌더링
    const renderPageButtons = () => {
        const buttons = [];
        for (let i = startPage; i < Math.min(startPage + pageSize, totalPages); i++) {
            buttons.push(React.createElement(Button, { key: i, className: i === page ? 'active' : 'unactive', text: String(i + 1), onClick: () => handlePageChange(i) }));
        }
        return buttons;
    };
    if (totalPages <= 1)
        return null;
    return (React.createElement("div", { className: "flex row paging" },
        React.createElement(Button, { className: `arrow ${startPage + 1 - pageSize > 0 ? 'active' : 'unactive'}`, option: {
                buttonBeforeIcon: {
                    show: true,
                    name: 'twoarrow',
                    size: 24,
                    color: Colors.w_grey,
                },
            }, onClick: startPage + 1 - pageSize > 0
                ? () => handlePageChange(startPage - pageSize)
                : undefined }),
        React.createElement(Button, { className: `arrow ${page > 0 ? 'active' : 'unactive'}`, option: {
                buttonBeforeIcon: {
                    show: true,
                    name: 'arrow',
                    size: 20,
                    color: Colors.w_grey,
                },
            }, onClick: page - 1 >= 0 ? () => handlePageChange(page - 1) : undefined }),
        renderPageButtons(),
        React.createElement(Button, { className: `arrow ${page + 1 < totalPages ? 'active' : 'unactive'}`, option: {
                buttonBeforeIcon: {
                    show: true,
                    className: 'arrow-right',
                    name: 'arrow',
                    size: 20,
                    color: Colors.w_grey,
                },
            }, onClick: page + 1 < totalPages ? () => handlePageChange(page + 1) : undefined }),
        React.createElement(Button, { className: `arrow ${startPage + pageSize < totalPages ? 'active' : 'unactive'}`, option: {
                buttonBeforeIcon: {
                    show: true,
                    className: 'arrow-right',
                    name: 'twoarrow',
                    size: 24,
                    color: Colors.w_grey,
                },
            }, onClick: startPage + pageSize < totalPages
                ? () => handlePageChange(startPage + pageSize)
                : undefined })));
});
export default Paging;
