import { throttle } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { useAnswerList, useAssignmentList } from '../../../server';
import { getIsTeacher, getUserId } from '../../base/account';
import { MobileTabLayout } from '../../base/layout';
import { InfoTitle, TaskInfoContainer } from '../../DST/DSTL001/component/DSTL001_Detail_Child';
import { TaskInfoTeacherContainer } from '../../DST/DSTL001/component/DSTL001_Detail_Child/TaskInfoTeacherContainer';
import { changeMobileMenuName } from '../../menu/action';
import { ErrorContainer, SubmitResult } from './DSSL001_Detail_Child';
import { SubmissionPopup } from './DSSL001_Detail_Child/SubmissionPopup';
const DSSL001DetailStyled = styled.div `
	.task-isMobile {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
	&.dss001_detail {
		display: flex;
		gap: 20px;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.task-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;
	}

	input:disabled,
	textarea:disabled {
		background: var(--color-white, #ffffff);
	}

	.warp-001 {
		display: flex;
		flex-direction: column;
		gap: ${({ $isMobile }) => ($isMobile ? '20px' : '48px')};
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		${props => props.$isTablet &&
    `
			width: 100%;
			height: 100%;
			padding: 20px;
		`}
	}
	.sub-info {
		border-style: dashed;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: ${({ $isMobile }) => ($isMobile ? '0' : ' 0px 0px 1px 0px;')};
		padding: ${({ $isMobile }) => ($isMobile ? '0px 0px 20px 0px;' : '0px 0px 28px 0px')};
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;
	}
	${({ $isMobile }) => !$isMobile &&
    `.status-panel {
		background: #feffff;
		border-radius: 8px;
		border-style: solid;
		border-color: var(--tur, #3ab2bf);
		border-width: 1px;
		padding: 20px 0;
		display: flex;
		flex-direction:  row;
		gap: 20px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;
		width: 80%;
		margin: 0 auto;
	}`}

	.frame-987345 {
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		padding-bottom: 16px;
		${props => props.$isMobile && `height: 100%;`}
		${props => props.$isTablet && `min-height: 100%;`}
	}

	.wrong-rate-list {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		// height: 100%;
		${({ $isTablet }) => $isTablet &&
    `
		min-height: 100%;
		`}
	}
	${({ $isTablet }) => $isTablet &&
    `
		.empty_data {
			flex: 1;
		}
	`}
`;
export const DSSL001_Detail = React.memo(({ isTest = false, test_id, props }) => {
    const { isDesktop, isTablet, isMobile, classesId } = useGlobalState();
    const dispatch = useDispatch();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const member_id = useSelector((state) => getUserId(state));
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [item, setItem] = React.useState(null);
    const { data, refetch } = useAssignmentList({ id: test_id, class_id: classesId });
    const { data: answerData, refetch: answerRefetch } = useAnswerList({
        test_id,
        from: member_id,
        orderby: 'value',
    });
    const refetchList = throttle(answerRefetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        APP.eventManager.subscribe('ANSWER_REFETCH', refetchList);
        return () => {
            APP.eventManager.unsubscribe('ANSWER_REFETCH', refetchList);
        };
    }, []);
    useEffect(() => {
        if (item && !isDesktop) {
            dispatch(changeMobileMenuName(item.title));
        }
    }, [item, isDesktop, isTablet, isMobile]);
    useEffect(() => {
        refetch();
    }, [test_id]);
    useEffect(() => {
        refetchList();
    }, [member_id, test_id]);
    useEffect(() => {
        if (!data)
            return;
        if (data.found_count > 0) {
            const item = data.items[0];
            setItem(item);
        }
    }, [data]);
    useEffect(() => {
        if (!answerData)
            return;
        if (answerData.found_count > 0) {
            setIsSubmit(true);
        }
        else {
            setIsSubmit(false);
        }
    }, [answerData]);
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: undefined,
            id: undefined,
        });
    };
    const renderTaskInfo = useMemo(() => isMobile ? (React.createElement("div", { className: "task-isMobile" },
        React.createElement(TaskInfoTeacherContainer, { isTest: isTest, item: item }))) : (React.createElement(TaskInfoContainer, { key: "task-info", isTest: isTest, item: item })), [item, isMobile, isTest]);
    const renderSubmissionStatusTable = useMemo(() => (React.createElement(SubmissionPopup, { key: "submission-info", test_id: test_id, member_id: member_id, item: item, props: {
            isTest,
            subject_name: item?.subject,
            isDetail: true,
            test_id,
            member_id,
            isTeacher,
        } })), [item, isTest, test_id, member_id, isTeacher]);
    const renderSubmissionGraph = useMemo(() => (React.createElement(SubmitResult, { key: "submission-info", isSubmit: isSubmit, test_id: test_id, member_id: member_id })), [isSubmit, test_id, member_id]);
    const renderErrorContainer = useMemo(() => (React.createElement(ErrorContainer, { key: "error-info", item: item, isTest: isTest, isSubmit: isSubmit, test_id: test_id })), [item, isTest, isSubmit, test_id]);
    if (!item)
        return null;
    if (isMobile) {
        return (React.createElement(DSSL001DetailStyled, { className: "dss001_detail", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { isTabMode: true, tabList: [
                    {
                        id: 1,
                        title: '기본 정보',
                        component: [renderTaskInfo],
                    },
                    {
                        id: 2,
                        title: '제출결과',
                        component: [renderSubmissionGraph],
                        styles: {
                            paddingBottom: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                    {
                        id: 3,
                        title: '문항별 상세',
                        component: [renderSubmissionStatusTable],
                    },
                    {
                        id: 4,
                        title: '오답률',
                        component: renderErrorContainer,
                        styles: {
                            height: '100%',
                        },
                    },
                ], showNav: false, showPageButton: false, showPageTab: true, submitAssignment: previousPage, mobilePage: props?.mobilePage || undefined })));
    }
    if (isTablet) {
        return (React.createElement(DSSL001DetailStyled, { className: "dss001_detail", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { isTabMode: true, tabList: [
                    {
                        id: 1,
                        title: '기본 정보 / 제출결과',
                        component: [renderTaskInfo, renderSubmissionGraph],
                        styles: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                    {
                        id: 3,
                        title: '문항별 상세',
                        component: [renderSubmissionStatusTable],
                    },
                    {
                        id: 4,
                        title: '오답률',
                        component: renderErrorContainer,
                        styles: {
                            height: '100%',
                        },
                    },
                ], showNav: false, showPageButton: false, showPageTab: true, submitAssignment: previousPage, mobilePage: props?.mobilePage || undefined })));
    }
    return (React.createElement(DSSL001DetailStyled, { className: "dss001_detail", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        isDesktop && (React.createElement(InfoTitle, { isTeacher: false, title: item.title, test_id: test_id, isTest: isTest, previousPage: previousPage })),
        React.createElement("div", { className: "warp-001" },
            renderTaskInfo,
            React.createElement(SubmitResult, { isSubmit: isSubmit, test_id: test_id, member_id: member_id }),
            React.createElement(SubmissionPopup, { test_id: test_id, member_id: member_id, item: item, props: {
                    isTest,
                    subject_name: item?.subject,
                    isDetail: true,
                    test_id,
                    member_id,
                    isTeacher,
                } }),
            React.createElement(ErrorContainer, { item: item, isTest: isTest, isSubmit: isSubmit, test_id: test_id }))));
});
