import React from 'react';
import { Button } from '../../../../common';
import { objectToQueryString } from '../../../../server/functions';
export const AttandancesDown = React.memo(({ test_id }) => {
    const time = objectToQueryString({ download_tz: '+09:00' });
    const download = () => {
        const url = `/api/mimac/v1/conferences/${test_id}/attendance?&${time}`;
        const a = document.createElement('a');
        a.href = url;
        a.download = 'download'; // 다운로드 파일명 설정
        document.body.appendChild(a); // a 요소를 문서에 추가
        a.click();
        document.body.removeChild(a); // a 요소를 문서에서 제거
    };
    return (React.createElement(Button, { className: "btn_default xmd down_btn", text: "common.download", onClick: () => download() }));
});
