import { css, styled } from 'styled-components';
export const QnAAnswerStyled = styled.div `
	display: flex;
	flex-direction: row;
	gap: 28px;
	align-items: flex-start;
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 48%;

	.question-wrapper {
		background: var(--color-white, #ffffff);
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}
	.attach-wrapper {
		background: var(--color-white, #ffffff);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		width: 100%;
	}

	.attach-files {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		position: relative;
	}

	.question-content {
		display: flex;
		flex-direction: column;
		gap: 10px;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		min-height: 280px;
		flex: 1;

		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 11px;
		flex: 1;
		width: 100%;
		box-sizing: border-box;
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		.img_wrap {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			padding-top: 10px;
			position: relative;
			.img {
				border-radius: 4px;
				flex-shrink: 0;
				width: 100px;
				height: 100px;
				position: relative;
				object-fit: cover;
				background: #000;
			}
		}
	}
`;
export const DSTS004AnswerStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 28px;
	align-items: flex-start;
	justify-content: flex-start;
	flex: 1;
	position: relative;
	width: 100%;
	height: 100%;

	.ql-editor {
		padding: 4px 12px;
	}

	.default_file {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.content-regist-width {
		display: flex;
		flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
		gap: 10px;
		align-items: flex-start;

		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		${props => props.$isMobile &&
    `
		
				.attach_file {
					button {
						font-size: 12px;
					}
				} 
			

		`}
	}

	.scroll-content {
		height: 100%;
	}

	.task-label {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
		letter-spacing: var(--h5-letter-spacing, -0.02em);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		min-width: fit-content;

		${({ $isTablet, $isMobile }) => $isMobile
    ? css ``
    : $isTablet
        ? css `
						font-size: 14px;
						line-height: 150%;
				  `
        : css `
						font-size: var(--h5-font-size, 16px);
						line-height: var(--h5-line-height, 24px);
				  `}
	}

	${({ $isMobile }) => $isMobile &&
    css `
			.quill_wrap {
				flex-direction: column;
			}
		`}

	.qna-contents {
		display: flex;
		flex-direction: row;
		gap: 28px;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		align-self: stretch;
		padding-bottom: 15px;
	}

	.wrap_content {
		background: var(--color-white, #ffffff);
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 1px;
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		box-sizing: border-box;
		flex: 1;
		overflow: hidden;

		.field-global-001 {
			align-items: flex-start;
		}
	}

	.answer_wrap_content {
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-pink, #f46790);
		border-width: 1px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		box-sizing: border-box;
		flex: 1;
		height: 100%;
		overflow: hidden;

		.fext-field {
			width: 100%;
		}

		.field-global-001 {
			align-items: flex-start;
		}

		.task-label {
			min-width: 70px;
		}
	}

	.wrap_content {
		.task-label {
			min-width: 70px;
		}
	}

	.btn_pink {
		box-shadow: none;
	}

	.btn-container {
		padding-bottom: 32px;
	}

	.disable-text {
		p {
			font-size: 14px;
		}
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	padding: 20px;
	.answer_wrap {
		min-height: 450px;
		overflow: hidden;
		height: 100%;
		flex: 1;
	}

	.fext-field {
		font-size: 14px;
	}

	&.dsts004-popup {
		.qna-list-001 > .scroll-content {
			padding: 0;
		}
	}

	.answer-info-wrap {
		display: flex;
		gap: 24px;
		flex-direction: column;
		width: 100%;
	}

	.answer_wrap_content {
		padding: 10px;
		min-height: 450px;
	}

	.attach_file {
		width: 100%;
	}
`;
const tabletStyles = css `
	padding: 20px;
	height: min-content;
	.answer_wrap {
		min-height: unset;
		overflow: unset;
		flex: 1;
	}

	.answer-info-wrap {
		display: flex;
		gap: 24px;
		flex-direction: column;
		width: 100%;
		flex: 1;
	}

	.answer_wrap_content {
		padding: 20px;
	}

	.wrap_content {
		height: 100%;
	}
`;
const desktopStyles = css `
	.answer_wrap {
		flex: 1;
	}

	.answer-info-wrap {
		display: flex;
		gap: 24px;
		flex-direction: column;
		width: 100%;
		height: 100%;
	}

	.task-label {
		min-width: 70px !important;
		max-width: 70px !important;
	}

	.answer_wrap_content {
		padding: 24px;
		override: hidden;
	}

	.wrap_content {
		height: 100%;
	}
`;
