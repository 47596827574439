import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styled from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { getIsTeacher } from '../../base/account';
import { MobileTabLayout } from '../../base/layout';
import { GradeDropDown, StatusTable } from './DSSL007_List_Child';
import { Button } from '../../../common';
const DSSL007ListStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 20px;
	align-items: center;
	justify-content: flex-start;
	flex: 1;
	position: relative;
	overflow-y: ${props => (props.$isAdmin ? '' : 'auto')};
	width: 100%;
	height: 100%;

	${({ $isTablet }) => $isTablet && `padding: 20px;`}

	${({ $isMobile }) => $isMobile &&
    `
		.empty_data {
			min-height: 64px;
		}	
	`}

	.pdf-download {
		display: flex;
		flex-direction: column;
		width: 100%;
		${({ $isTablet, $isDesktop }) => $isTablet ? `gap: 24px;` : $isDesktop ? `gap: 36px;` : ``}
	}

	.download-btn {
		padding: 12px 24px;
	}

	.divider {
		background: var(--color-grey-c, #e6e8ed);
		align-self: stretch;
		flex-shrink: 0;
		height: 1px;
		position: relative;
	}

	.select-bar {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		padding: ${props => (props.$isDesktop ? ' 0px 0px 20px 0px' : ' 0px 0px 20px 0px')};
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		${({ $isMobile }) => $isMobile
    ? `
			.dropdown {
				align-items: flex-end;
			}
			.dropdown-field {
				width:	126px;
				height: 46px;
			}
		`
    : `
			.dropdown {
				width: unset!important;
			}
		`}

		.field-short {
			justify-content: center;
			align-items: center;
			${props => props.$isMobile && `flex-direction: column;`}

			.field-global-001 {
				flex: 0;
				justify-content: center;

				${props => props.$isMobile
    ? `width: 100%; flex-direction: column; align-items: flex-start;
			
					
			`
    : ``}

				.task-label {
					white-space: break-spaces;
					${props => (props.$isDesktop ? `width: 130px;` : `width: 100px`)}
				}

				.fext-field {
					${props => (props.$isMobile ? `width: 100%;` : `width: auto; flex: 0;`)}
				}
			}
		}
	}

	.attend-container {
		border-radius: 12px;

		display: flex;

		align-items: flex-end;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		overflow-y: auto;
		${({ $isDesktop, $isTablet }) => $isDesktop
    ? `
		gap: 60px;
		padding: 24px 32px 24px 32px;
		`
    : `
			flex-direction: column;

			.frame-987334 {

				width: 100%;
			}
		`}

		.label {
			color: #000000;
			text-align: left;
			font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: ${props => props.$isTablet ? 'var(--h4-font-size, 20px)' : 'var(--h3-font-size, 24px)'};
			line-height: ${props => props.$isTablet ? 'var(--h4-line-height, 32px)' : 'var(--h3-line-height, 36px)'};
			letter-spacing: var(--h3-letter-spacing, -0.02em);
			font-weight: var(--h3-font-weight, 700);
			position: relative;
			align-self: stretch;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
		}

		.frame-987334 {
			display: flex;
			flex-direction: column;
			gap: 32px;
			align-items: flex-end;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			flex: 1;
			overflow: hidden;
		}
	}
`;
const LIST = [
    {
        title: 'attend',
        divider: true,
        color: '#f46790',
    },
    { title: 'test', color: '#3ab2bf', divider: true },
    { title: 'task', color: '#458c94', dvider: false },
    { title: 'reply', color: '#458c94' },
];
export const DSSL007_List = React.memo(({ isAdmin = false, classId, selectUserId, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const [selectDay, setSelectDay] = useState('');
    const renderStatus = item => (React.createElement(StatusTable, { key: "status_table", ...item, classId: classId, selectUserId: selectUserId, selectDay: selectDay }));
    const renderSelectDate = useMemo(() => (React.createElement("div", { className: "select-bar", key: "select-bar" },
        React.createElement(GradeDropDown, { setDropValue: setSelectDay }))), [setSelectDay]);
    if (isMobile) {
        return (React.createElement(DSSL007ListStyled, { "$isAdmin": isAdmin, "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
            React.createElement(MobileTabLayout, { isTabMode: true, tabList: [
                    {
                        id: 1,
                        title: '출결 현황',
                        component: [renderSelectDate, renderStatus(LIST[0])],
                        styles: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                    {
                        id: 2,
                        title: '테스트 성적',
                        component: [renderSelectDate, renderStatus(LIST[1])],
                        styles: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                    {
                        id: 3,
                        title: '과제 성적',
                        component: [renderSelectDate, renderStatus(LIST[2])],
                        styles: {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '24px',
                        },
                    },
                ], showNav: false, showPageButton: false, showPageTab: true })));
    }
    // pdf 다운로드	기능
    const downloadPdf = async () => {
        const pdfContent = document.querySelector('.pdf-download');
        if (pdfContent) {
            const canvas = await html2canvas(pdfContent, {
                scale: 2,
                useCORS: true,
            });
            const contentWidth = canvas.width;
            const contentHeight = canvas.height;
            const pdf = new jsPDF('p', 'mm', 'a4');
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const scaleX = pageWidth / contentWidth;
            const scaleY = pageHeight / contentHeight;
            const scale = Math.min(scaleX, scaleY);
            const imgWidth = contentWidth * scale;
            const imgHeight = contentHeight * scale;
            const x = (pageWidth - imgWidth) / 2;
            const y = (pageHeight - imgHeight) / 2;
            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);
            pdf.save('download.pdf');
        }
        else {
            console.error('The element was not found');
        }
    };
    return (React.createElement(DSSL007ListStyled, { "$isAdmin": isAdmin, "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        React.createElement("div", { className: "select-bar" },
            React.createElement(GradeDropDown, { setDropValue: setSelectDay }),
            React.createElement(Button, { className: "btn_default xmd download-btn", text: "common.pdfDownload", onClick: downloadPdf })),
        React.createElement("div", { className: "pdf-download" }, LIST.map((item, index) => !isTeacher && item.title === 'reply' ? null : (React.createElement(Fragment, { key: index },
            React.createElement(StatusTable, { ...item, classId: classId, selectUserId: selectUserId, selectDay: selectDay }),
            item.divider && React.createElement("div", { className: "divider" })))))));
});
