import React from 'react';
import { DropDown } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
const categoryDropdownList = [
    { id: 'select', title: 'supportRoom.category' },
    { id: 'lesson', title: 'supportRoom.lesson' },
    { id: 'subject', title: 'supportRoom.subject' },
    { id: 'textbook', title: 'supportRoom.textbook' },
    { id: 'attendance', title: 'supportRoom.attendance' },
    { id: 'etc', title: 'supportRoom.etc' },
    { id: 'preview', title: 'supportRoom.preview' },
    { id: 'system', title: 'supportRoom.system' },
];
export const QnACategoryDropDown = React.memo(({ category, setCategroy }) => {
    const { isTablet, isMobile } = useGlobalState();
    const [value, setValue] = React.useState({
        id: 'select',
        title: 'supportRoom.category',
    });
    React.useEffect(() => {
        if (category !== value.id)
            setValue(categoryDropdownList.find(item => item.id === category) ||
                categoryDropdownList[0]);
    }, [category]);
    const handlerUpdateDrop = (item) => {
        setValue(item);
        setCategroy(item.id);
    };
    return (React.createElement(DropDown, { className: "dropdown-tablet-small", list: categoryDropdownList, value: value, styles: {
            ...(!isMobile && { dropWrap: { width: 150 } }),
            itemWrap: {
                top: isTablet ? '50px' : '45px',
            },
        }, setDropValue: handlerUpdateDrop }));
});
