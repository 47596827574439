import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DropDown, SearchBar } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { AttandancesDown } from './AttandancesDown';
const AttandancesTitleStyled = styled.div `
	${props => props.$isMobile
    ? `width: 100%;
	
		background: var(--color-silver, #f3f4f8);
		padding: 20px;
	`
    : ``}

	.attandances-title {
		display: flex;
		flex-direction: row;
		gap: 14px;
		align-items: center;
		justify-content: ${props => (props.$isMobile ? 'flex-start' : 'flex-end')};
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;

		${props => (props.$isMobile ? `width: 100%;` : ``)}
	}
`;
const STATE_DROP_LIST = [
    { id: 'all', title: 'common.all' },
    { id: 'attendance', title: 'conference.attendance' },
    { id: 'late', title: 'conference.late' },
    { id: 'nonAttendance', title: 'conference.nonAttendance' },
];
export const AttandancesTitle = React.memo(({ test_id, defaultStatus, setStatus, handlerFilterList, }) => {
    const { isMobile, isTablet } = useGlobalState();
    const [value, setValue] = React.useState({
        id: 'all',
        title: 'common.all',
    });
    useEffect(() => {
        if (defaultStatus !== value.id)
            setValue(STATE_DROP_LIST.find(item => item.id === defaultStatus));
    }, [defaultStatus]);
    useEffect(() => {
        setStatus(value.id);
    }, [value]);
    return (React.createElement(AttandancesTitleStyled, { "$isMobile": isMobile },
        React.createElement("div", { className: "attandances-title" },
            React.createElement(DropDown, { className: "dropdown-tablet-small", list: STATE_DROP_LIST, value: value, styles: {
                    ...(!isMobile && { dropWrap: { width: isTablet ? 120 : 150 } }),
                    itemWrap: {
                        top: isTablet ? '50px' : '45px',
                    },
                }, setDropValue: setValue }),
            React.createElement(SearchBar, { className: "search-bar-tablet", filterData: "member", handlerFilterList: handlerFilterList }),
            isMobile && React.createElement(AttandancesDown, { test_id: test_id }))));
});
