import React from 'react';
import { Button } from '../../../../common';
import { Colors } from '../../../../Colors';
import { useGlobalState } from '../../../../GlobalStateProvider';
export const QnADropDown = React.memo(({ buttonTitle, icon, isOpen, handlerOnClick, }) => {
    const { isMobile } = useGlobalState();
    return (React.createElement(Button, { key: "renderQuestionList", className: `${icon === 'question' ? 'btn_line_dark_tur' : 'btn_line_pink'} mobile-full`, text: buttonTitle, option: {
            buttonBeforeIcon: {
                show: true,
                name: icon,
                size: 18,
                color: `${icon === 'question'
                    ? isMobile
                        ? Colors.dark_tur
                        : Colors.tur
                    : Colors.pink}`,
            },
            buttonAfterIcon: {
                show: true,
                name: 'arrow',
                className: isOpen ? 'arrow-up' : 'arrow-down',
                size: 25,
                color: `${icon === 'question' ? Colors.dark_tur : Colors.pink}`,
            },
        }, styles: {
            buttonWrap: {
                background: `${icon === 'question' ? '#f8fcfc' : '#fffdfe'}`,
            },
        }, onClick: () => handlerOnClick(icon) }));
});
