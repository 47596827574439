export const getAssignmentTableProps = (isTest, isHome, isReserve, isTeacher, isBackOffice, isMobile, isTablet) => {
    const headTitleList = isTeacher && isMobile
        ? []
        : isHome
            ? []
            : isReserve
                ? // 예약 페이지
                    [
                        { title: 'type', space: 1, sort: false },
                        { title: isTest ? 'test_name' : 'title', space: 2, sort: false },
                        ...(isTeacher ? [{ title: 'author_name', space: 1, sort: false }] : []),
                    ]
                : isBackOffice
                    ? // 관리자 페이지
                        []
                    : isTablet
                        ? // 태블릿 페이지
                            [
                                { title: 'creation_time', space: 1, sort: false },
                                ...(isTest
                                    ? [
                                        {
                                            title: 'class_name',
                                            space: 1,
                                            sort: false,
                                        },
                                    ]
                                    : []),
                                {
                                    title: `type_and_${isTest ? 'test' : 'subject'}_name`,
                                    space: 2,
                                    sort: false,
                                },
                                ...(!isTest
                                    ? [
                                        { title: 'status', space: 1, sort: false },
                                        { title: 'startline', space: 2, sort: false },
                                        { title: 'deadline', space: 2, sort: false },
                                    ]
                                    : [
                                        { title: 'status', space: 1, sort: false },
                                        { title: 'progressdate', space: 2, sort: false },
                                        { title: 'duration', space: 1, sort: false },
                                    ]),
                                ...(isTeacher
                                    ? [
                                        { title: 'author_name', space: 1, sort: false },
                                        { title: 'etc', space: 1, sort: false },
                                    ]
                                    : []),
                            ]
                        : // 그 외
                            [
                                { title: 'creation_time', space: 1, sort: false },
                                { title: 'type', space: 1, sort: false },
                                {
                                    title: isTest ? 'class_name' : 'title',
                                    space: 2,
                                    sort: false,
                                },
                                { title: 'status', space: 1, sort: false },
                                ...(!isTest
                                    ? [
                                        { title: 'startline', space: 2, sort: false },
                                        { title: 'deadline', space: 2, sort: false },
                                    ]
                                    : [
                                        { title: 'test_name', space: 1, sort: false },
                                        { title: 'progressdate', space: 2, sort: false },
                                        { title: 'duration', space: 1, sort: false },
                                    ]),
                                ...(isTeacher
                                    ? [
                                        { title: 'author_name', space: 1, sort: false },
                                        { title: 'etc', space: 1, sort: false },
                                    ]
                                    : []),
                            ];
    const tableReadDataKeys = isHome
        ? isTest
            ? [
                { title: 'statusAndTitle', space: 4, sort: false },
                { title: 'creation_time', space: 1, sort: false },
            ]
            : [
                { title: 'title', space: 4, sort: false },
                { title: 'submitDate', space: 1, sort: false },
            ]
        : isReserve
            ? // 예약 페이지
                [
                    ...(isMobile
                        ? [
                            {
                                title: `type_and_${isTest ? 'test' : 'subject'}_name`,
                                space: 2,
                                sort: false,
                            },
                        ]
                        : [
                            { title: 'type', space: 1, sort: false },
                            { title: 'title', space: 2, sort: false },
                        ]),
                    ...(isTeacher ? [{ title: 'author_name', space: 1, sort: false }] : []),
                ]
            : isBackOffice
                ? // 관리자 페이지
                    []
                : isMobile
                    ? [
                        ...(isTeacher ? [{ title: 'etc', space: 1, sort: false }] : []),
                        { title: 'title', space: 1, sort: false },
                        { title: 'type', space: 1, sort: false },
                        // { title: 'startline', space: 1, sort: false },
                        { title: 'deadline', space: 1, sort: false },
                        // { title: 'duration', space: 1, sort: false },
                        { title: 'author_name', space: 1, sort: false },
                    ]
                    : isTablet
                        ? // 태블릿 페이지
                            [
                                { title: 'creation_time', space: 1, sort: false },
                                ...(isTest
                                    ? [
                                        {
                                            title: 'conference_name',
                                            space: 1,
                                            sort: false,
                                        },
                                    ]
                                    : []),
                                {
                                    title: `type_and_${isTest ? 'test' : 'subject'}_name`,
                                    space: 2,
                                    sort: false,
                                },
                                ...(!isTest
                                    ? [
                                        { title: 'status', space: 1, sort: false },
                                        { title: 'startline', space: 2, sort: false },
                                        { title: 'deadline', space: 2, sort: false },
                                    ]
                                    : [
                                        { title: 'status', space: 1, sort: false },
                                        { title: 'progressdate', space: 2, sort: false },
                                        { title: 'duration', space: 1, sort: false },
                                    ]),
                                ...(isTeacher
                                    ? [
                                        { title: 'author_name', space: 1, sort: false },
                                        { title: 'etc', space: 1, sort: false },
                                    ]
                                    : []),
                            ]
                        : // 그 외
                            [
                                { title: 'creation_time', space: 1, sort: false },
                                { title: 'type', space: 1, sort: false },
                                {
                                    title: isTest ? 'conference_name' : 'title',
                                    space: 2,
                                    sort: false,
                                },
                                { title: 'status', space: 1, sort: false },
                                ...(!isTest
                                    ? [
                                        { title: 'startline', space: 2, sort: false },
                                        { title: 'deadline', space: 2, sort: false },
                                    ]
                                    : [
                                        { title: 'title', space: 1, sort: false },
                                        { title: 'progressdate', space: 2, sort: false },
                                        { title: 'duration', space: 1, sort: false },
                                    ]),
                                ...(isTeacher
                                    ? [
                                        { title: 'author_name', space: 1, sort: false },
                                        { title: 'etc', space: 1, sort: false },
                                    ]
                                    : []),
                            ];
    return {
        headTitleList,
        tableReadDataKeys,
    };
};
