import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../../Colors';
import { Button } from '../../../common';
import { useGlobalState } from '../../../GlobalStateProvider';
import { NavbarContainer } from '../../DST';
import { goToPrevMenu, updateMenu } from '../../menu/action';
import { getDepths, getMenuData, getMobileName, menuItems } from '../../menu/function';
import { MenuBox } from '../../menu/MenuBox';
import { getIsTeacher, getUserAuthName } from '../account';
import { closeWindow } from '../app';
import { getClassesTitle, getHiddenMenus } from '../classes';
import { DSBody, DSLayoutHeader } from './DSLayoutComponent';
import { HomeLayout } from './HomeLayout';
import MobileGNB from './MobileLayout_Child/MobileGNB';
export const MainClasses = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classesTitle = useSelector((state) => getClassesTitle(state));
    const user = useSelector((state) => getUserAuthName(state));
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const hiddenMenus = useSelector((state) => getHiddenMenus(state));
    const depths = useSelector((state) => getDepths(state));
    const menu = useSelector((state) => getMenuData(state), shallowEqual);
    const [isScrolled, setIsScrolled] = useState(false);
    const handlerUpdateMenu = (menu, isMain = false) => {
        if (menu === 'logout') {
            closeWindow();
            return;
        }
        else if (menu === 'accept') {
            navigate('/accept');
            closeWindow();
            return;
        }
        else if (menu === 'error') {
            navigate('/error');
            closeWindow();
            return;
        }
        const menuList = Object.entries(menuItems)
            .filter(([key]) => key === menu) // depths에 해당하는 항목만 필터링
            .flatMap(([_, menus]) => {
            return menus.filter(menu => !hiddenMenus?.includes(menu)); // hiddenMenus에 없는 메뉴만 필터링
        });
        dispatch(updateMenu(menu, {
            menu: menuList && menuList.length > 0 ? menuList[0] : 'home',
            detail: 'list',
        }));
    };
    useEffect(() => {
        const throttleOut = throttle(() => {
            // @ts-ignore
            if (!user)
                location.href = '/error';
        }, 1000, { leading: false, trailing: true });
        throttleOut();
    }, [user]);
    const { name, menuList } = useMemo(() => {
        const filterMenu = (menuList) => {
            return menuList.filter(menu => !hiddenMenus.includes(menu));
        };
        switch (depths) {
            case 'home':
                return {
                    name: 'home',
                    menuList: filterMenu(['home']),
                };
            case 'learningManagement':
                return {
                    name: 'assignment',
                    menuList: filterMenu(['test', 'assignment', 'attendanceStatus', 'sendSMS']),
                };
            case 'myRoom':
                return {
                    name: 'assignment',
                    menuList: filterMenu(['test', 'assignment', 'gradeCard']),
                };
            case 'liveRoom':
                return {
                    name: 'reservation',
                    menuList: filterMenu([
                        'reservation',
                        'previous-conference',
                        'reply',
                        'content',
                    ]),
                };
            case 'learningSupportRoom':
                return {
                    name: 'learningSupportRoom',
                    menuList: isTeacher
                        ? filterMenu(['notice', 'qna', 'resource'])
                        : filterMenu(['notice', 'qna', 'resource']),
                };
            default:
                return {
                    name: 'home',
                    menuList: filterMenu(['home']),
                };
        }
    }, [depths, hiddenMenus, isTeacher]);
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [isHovered, setIsHovered] = React.useState(false);
    // const isWhite = useMemo(() => depths === 'home' || !isTeacher && depths === 'liveRoom', [menu, isTeacher, depths]);
    const isWhite = useMemo(() => depths === 'home', [depths]);
    const hideMenu = useMemo(() => !isTeacher && depths === 'liveRoom', [isTeacher, depths]);
    const mobileName = useSelector((state) => getMobileName(state));
    if (!user || !menu)
        return null;
    return (React.createElement("div", { className: `ds-layout
			${isWhite ? 'box-white' : ''}
			${isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop'}` },
        React.createElement(DSLayoutHeader, { isTeacher: isTeacher, title: isDesktop && classesTitle, isHome: depths === 'home', isScrolled: isScrolled, isHovered: isHovered, selectedMenu: menu?.menu?.menu, setIsHovered: setIsHovered, setMenu: handlerUpdateMenu }, isDesktop ? (React.createElement(NavbarContainer, { isTeacher: isTeacher, menu: depths, setIsHovered: setIsHovered, setMenu: menu => handlerUpdateMenu(menu, true) })) : (React.createElement("div", { className: "mobile_nav" },
            React.createElement(Button, { option: {
                    buttonBeforeIcon: {
                        show: depths !== 'home',
                        name: 'arrow',
                        size: 32,
                        color: isWhite ? Colors.white : Colors.light_grey,
                    },
                }, onClick: depths !== 'home'
                    ? () => {
                        dispatch(goToPrevMenu());
                    }
                    : undefined }),
            React.createElement("p", { className: "title-name-gnb overText" }, depths !== 'home' ? mobileName : ''),
            React.createElement(MobileGNB, { isWhite: isWhite && !isScrolled, isTeacher: isTeacher, selectedMenu: menu?.menu.menu })))),
        depths === 'home' ? (React.createElement(HomeLayout, { isTeacher: isTeacher, setIsScrolled: setIsScrolled })) : (React.createElement(DSBody, { title: isTablet ||
                (isMobile && menu.menu.detail !== 'detail') ||
                ((menu.menu.menu === 'assignment' || menu.menu.menu === 'reservation') &&
                    menu.menu.detail === 'regist')
                ? classesTitle
                : undefined, isWhite: isWhite, name: name, menuList: menuList, hideBody: true },
            React.createElement(MenuBox, { isTeacher: isTeacher, name: name, menuList: menuList, hideMenu: hideMenu }))),
        (isMobile || isTablet) && (React.createElement("div", { className: "botom-nav" },
            React.createElement(NavbarContainer, { isFooter: true, isTeacher: isTeacher, menu: depths, setMenu: menu => {
                    if (menu === 'logout') {
                        closeWindow();
                        return;
                    }
                    handlerUpdateMenu(menu, true);
                } })))));
});
