import React from 'react';
import { useGlobalState } from '../../../GlobalStateProvider';
import { RegistButton } from '../RegistButton';
import { AssignmentTable } from './component/DSTL001_List_Child';
export const DSTL001_List = React.memo(({ isTeacher, isTest, isBackOffice, }) => {
    const { isDesktop, isMobile } = useGlobalState();
    // const handlerButtonClick = () => {
    // 	isMobile
    // 		? APP.eventManager.publish('UPDATE_DETAIL_MENU', {
    // 			menuDetail: 'regist',
    // 			id: undefined,
    // 		})
    // 		: APP.eventManager.publish(TOGGLE_POPUP, {
    // 			component: DSTP001_Regist,
    // 			componentProps: { isTest, isTeacher: isTeacher, isBackOffice },
    // 			width: isDesktop ? 800 : 600,
    // 			height: 'unset',
    // 			title: isTest ? '테스트 등록' : '과제 등록',
    // 		});
    // };
    const handlerButtonClick = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: 'regist',
            id: undefined,
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(AssignmentTable, { isTeacher: isTeacher, isTest: isTest, isBackOffice: isBackOffice }),
        isTeacher && React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick })));
});
