import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import '../../../assest/css/quill.scss';
import { Button, CheckBoxElement, InputElement } from '../../../common';
import { FileAttach } from '../../../common/option';
import { useGlobalState } from '../../../GlobalStateProvider';
import { postFile, postUpdatePosts, registPosts, } from '../../../server';
import { DetailTitleInfo } from '../../base/layout';
import { goToPrevMenu, updateMenu } from '../../menu/action';
import { REFRESH_POSTS_LIST } from './constant';
import { RegistContent } from './DSTSL001_Regist_Child/RegistContent';
const DSTL001Styled = styled.div `
	background: var(--color-white, #ffffff);
	border-radius: 0px 0px 8px 8px;
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isMobile ? '12px' : '20px')};
	justify-content: flex-start;
	flex-shrink: 0;
	position: relative;
	width: 100%;
	flex: 1;
	padding: ${props => (props.$isMobile || props.$isTablet ? '20px' : '0px')};

	.field-global-001 {
		flex-direction: column;
		align-items: flex-start;

		.field-short {
			gap: 10px;
		}
	}

	.ql-editor {
		padding: 4px 12px;
	}

	.field-quill {
		display: inline-flex;
		flex-direction: row;
		gap: 10px;
		justify-content: flex-start;
		position: relative;
		width: 100%;
	}

	.learning-center-mw {
		width: 100%;
		height: 100%;
	}

	.content-regist-info {
		width: 100%;
	}

	.content-regist-width {
		display: flex;
		width: 100%;
		height: 100%;

		${props => props.$isMobile &&
    `
		
			.attach_file {
				button {
					padding: 10px;
					font-size: 12px;
				}
			} 

			.file {
				flex-direction: column-reverse;
			}
		`}

	.must-read {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		font-weight: var(--b4-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.content-label {
		display: flex;
		gap: 4px;
		justify-content: flex-start;
		align-items: flex-end;
	}

	${props => props.$isMobile && mobileStyles}
	${props => props.$isTablet && tabletStyles}
`;
const tabletStyles = styled.div `
	${props => !props.$isPopup && `padding: 20px`};
	.content-regist-info {
		.task-label {
			font-size: 16px;
		}
	}
`;
const mobileStyles = `

	.btn-container {
		gap: 6px;
		margin-bottom: 10px;

		.button {
			height: 42px;
		}
	}
`;
export const DSTSL001_Regist = React.memo(({ item, isModify = false, isTeacher, isResource, isPopup = false, }) => {
    const { classesId, isMobile, isDesktop, isTablet } = useGlobalState();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [value, setValue] = useState('');
    const [fileList, setFileList] = useState();
    const [isImportance, setIsImportance] = useState(false);
    const fileMutation = useMutation(postFile);
    const updatePostsMutation = useMutation(postUpdatePosts);
    const registPostMutation = useMutation(registPosts);
    const handlerChecked = (id, checked) => {
        setIsImportance(checked);
    };
    useEffect(() => {
        if (item) {
            setTitle(item.title);
            setValue(item.content);
            setIsImportance(item.importance);
            setFileList(item.files || []);
        }
    }, [item]);
    //팝업창 바깥쪽 영역 클릭 시 팝업창 닫힘
    const popupRef = useRef(null);
    //게시글 등록, 수정
    const handlerSubmit = async () => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            APP.eventManager.publish('MENU_MOVE_ACTIVE', true);
            if (!title || title.trim() === '') {
                alert(t('supportRoom.titleAlert'));
                return;
            }
            if (value.replace(/<[^>]*>/g, '').trim().length === 0 || value.trim() === '') {
                alert(t('supportRoom.contentAlert'));
                return;
            }
            let convertAttachFileList = [];
            // 첨부 파일이 있는 경우
            if (fileList && fileList.length > 0) {
                const alredyFile = fileList.filter(file => typeof file === 'string');
                const filterFile = fileList.filter(file => file instanceof File);
                // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                if (filterFile && filterFile.length > 0) {
                    const data = await fileMutation.mutateAsync(filterFile);
                    convertAttachFileList = [...data.result.link, ...alredyFile];
                }
                else {
                    convertAttachFileList = alredyFile;
                }
            }
            if (isModify) {
                const data = {
                    content: value,
                    files: convertAttachFileList,
                    importance: isImportance,
                    title,
                };
                await updatePostsMutation.mutateAsync({
                    id: item.id,
                    props: data,
                });
                handlerPreviousPage();
                handlerDetailPage();
            }
            else {
                const data = {
                    class_id: classesId,
                    content: value,
                    files: convertAttachFileList,
                    importance: isImportance,
                    title,
                    type: isResource ? 'study_data' : 'notice',
                };
                await registPostMutation.mutateAsync(data);
                handlerPreviousPage();
                APP.eventManager.publish(REFRESH_POSTS_LIST);
            }
        }
        catch (error) {
            alert(error);
        }
        finally {
            APP.eventManager.publish('MENU_MOVE_ACTIVE', false);
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    const handlerPreviousPage = () => {
        dispatch(goToPrevMenu());
    };
    const handlerDetailPage = () => {
        dispatch(updateMenu('learningSupportRoom', { menu: isResource ? 'resource' : 'notice', detail: 'detail' }, item.id, {
            item,
            isResource,
            selectedId: item.id,
        }));
    };
    /**
     * Q&A 답변 상단 타이틀
     */
    const renderTitleInfo = useMemo(() => (React.createElement(DetailTitleInfo, { title: t(`menu.${isResource ? 'resource' : 'notice'}${isModify ? 'Modify' : 'Regist'}`), previousPage: handlerPreviousPage })), [isResource]);
    return (React.createElement(DSTL001Styled, { className: "dstl001_regist", "$isMobile": isMobile, "$isTablet": isTablet, "$isPopup": isPopup, ref: popupRef },
        isDesktop && renderTitleInfo,
        React.createElement("div", { className: "field-global-001", style: { height: '100%', borderBottom: '1px dotted #B6BAC0', overflow: 'auto' } },
            React.createElement("div", { className: "content-regist-info", style: { borderBottom: '1px dotted #B6BAC0' } },
                React.createElement("div", { className: "field-short content-regist-width", style: {
                        alignItems: isMobile ? 'start' : 'center',
                        paddingBottom: '20px',
                        flexDirection: isMobile ? 'column' : 'row',
                    } }, isMobile ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "task-label" }, t('supportRoom.title')),
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '10px',
                            width: '100%',
                        } },
                        React.createElement("div", { className: "fext-field" },
                            React.createElement(InputElement, { type: "text", defaultValue: item && item.title, onChange: setTitle, placeholder: t('supportRoom.titlePlaceHolder') })),
                        React.createElement(CheckBoxElement, { id: "importance", title: "\uD544\uB3C5", checked: isImportance, handlerCheck: handlerChecked, className: "must-read" })))) : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "task-label" }, t('supportRoom.title')),
                    React.createElement("div", { className: "fext-field" },
                        React.createElement(InputElement, { type: "text", defaultValue: item && item.title, onChange: setTitle, placeholder: t('supportRoom.titlePlaceHolder') })),
                    React.createElement(CheckBoxElement, { id: "importance", title: "\uD544\uB3C5", checked: isImportance, handlerCheck: handlerChecked, className: "must-read" }))))),
            React.createElement("div", { className: "content-regist-info", style: {
                    paddingTop: '24px',
                    overflow: 'auto',
                    height: '100%',
                    minHeight: 265,
                } },
                React.createElement("div", { className: "content-regist-width" },
                    React.createElement(RegistContent, { contentName: t('supportRoom.content'), defaultValue: item?.content, setValue: setValue, 
                        // handlerTextLength={setTextLength}
                        isTeacher: isTeacher, containerRef: popupRef }))),
            React.createElement("div", { className: "content-regist-info", style: { padding: isMobile ? '24px 0px' : '20px 0px' } },
                React.createElement("div", { className: "content-regist-width" },
                    React.createElement(FileAttach, { id: "content_regist_file", isRegist: true, isNotice: true, fileList: fileList, setAttachFileList: setFileList })))),
        React.createElement("div", { className: "btn-container", style: { padding: '0px', width: isMobile ? '100%' : 'unset' } },
            React.createElement(Button, { className: `btn_white ${isMobile ? 'sm' : 'xmd'}`, text: "common.cancel", onClick: isPopup ? handlerDetailPage : handlerPreviousPage, styles: { buttonWrap: { width: isMobile ? '100%' : 'unset' } } }),
            React.createElement(Button, { className: `btn_default ${isMobile ? 'sm' : 'xmd'}`, text: "common.okay", onClick: handlerSubmit, styles: { buttonWrap: { width: isMobile ? '100%' : 'unset' } } }))));
});
