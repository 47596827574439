import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { CheckBox, CheckBoxElement, DropDown, } from '../../../../common';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../../GlobalStateProvider';
const STATE_CHECKBOX_LIST = [
    { id: 'lesson', title: 'supportRoom.lesson', checked: false },
    { id: 'subject', title: 'supportRoom.subject', checked: false },
    { id: 'textbook', title: 'supportRoom.textbook', checked: false },
    { id: 'attendance', title: 'supportRoom.attendance', checked: false },
    { id: 'preview', title: 'supportRoom.preview', checked: false },
    { id: 'system', title: 'supportRoom.system', checked: false },
    { id: 'etc', title: 'supportRoom.etc', checked: false },
];
const STATE_DROPDOWN_LIST = [
    { id: 'select', title: 'common.select' },
    { id: 'lesson', title: 'supportRoom.lesson' },
    { id: 'subject', title: 'supportRoom.subject' },
    { id: 'textbook', title: 'supportRoom.textbook' },
    { id: 'attendance', title: 'supportRoom.attendance' },
    { id: 'preview', title: 'supportRoom.preview' },
    { id: 'system', title: 'supportRoom.system' },
    { id: 'etc', title: 'supportRoom.etc' },
];
const LOCK_CHECKBOX = {
    id: 'type',
    title: 'supportRoom.lockSetting',
    checked: false,
};
export const CategoryRadio = React.memo(({ defaultCategory, setCategory, defaultPrivate, setPrivate }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const [list, setList] = useState(STATE_CHECKBOX_LIST);
    const [state, setState] = useState(STATE_DROPDOWN_LIST[0]);
    const [lock, setLock] = useState(LOCK_CHECKBOX);
    useEffect(() => {
        const updatedList = list.map(item => item.id === defaultCategory
            ? { ...item, checked: true }
            : { ...item, checked: false });
        const updateState = STATE_DROPDOWN_LIST.filter(item => item.id === defaultCategory);
        isMobile
            ? setState(updateState.length === 0 ? STATE_DROPDOWN_LIST[0] : updateState[0])
            : setList(updatedList);
    }, [defaultCategory]);
    useEffect(() => {
        setLock(prevLock => ({ ...prevLock, checked: defaultPrivate }));
    }, [defaultPrivate]);
    useEffect(() => {
        const checkedItem = isMobile ? state : list.find(item => item.checked);
        if (checkedItem) {
            setCategory(checkedItem.id);
        }
    }, [list, state, setCategory]);
    useEffect(() => {
        setPrivate(lock.checked);
    }, [lock.checked, setPrivate]);
    const handlerCheckRadio = useCallback((id) => {
        setList(prevList => prevList.map(item => item.id === id ? { ...item, checked: true } : { ...item, checked: false }));
    }, []);
    const handlerCheck = useCallback(() => {
        setLock(prevLock => ({ ...prevLock, checked: !prevLock.checked }));
    }, []);
    const renderCheckBox = useMemo(() => (React.createElement(CheckBoxElement, { key: lock.id, ...lock, handlerCheck: handlerCheck, style: { paddingTop: '2px' } })), [lock, handlerCheck]);
    return (React.createElement("div", { className: "field-global-001", style: { flexDirection: isMobile ? 'column' : 'row' } },
        React.createElement("div", { className: "field-short", style: {
                alignItems: isMobile ? 'center' : 'flex-start',
            } },
            React.createElement("div", { className: "task-label" }, t('supportRoom.category')),
            isMobile ? (React.createElement("div", { style: {
                    width: '100%',
                    gap: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                } },
                React.createElement(DropDown, { className: "dropdown-mobile-small", value: state, list: STATE_DROPDOWN_LIST, setDropValue: setState }))) : (React.createElement(React.Fragment, null,
                React.createElement(CheckBox, { isRadioButton: true, list: list, handlerCheck: handlerCheckRadio }),
                React.createElement("div", { style: {
                        background: 'var(--color-grey-c, #e6e8ed)',
                        width: '1px',
                        height: '28px',
                    } }),
                renderCheckBox))),
        isMobile && React.createElement("div", { style: { alignSelf: 'start' } }, renderCheckBox)));
});
