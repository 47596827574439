import React from 'react';
import { DropDown } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const STATE_DROPDOWN_LIST = [
    { id: 'select', title: 'assignment.assignmentStatus' },
    { id: 'closed', title: 'assignment.closed' },
    { id: 'open', title: 'assignment.open' },
    { id: 'created', title: 'assignment.created' },
];
export const AssignmentStateDropdown = React.memo(({ status, setStatus }) => {
    const { isTablet, isMobile } = useGlobalState();
    const [value, setValue] = React.useState({
        id: 'select',
        title: 'assignment.assignmentStatus',
    });
    React.useEffect(() => {
        if (status !== value.id)
            setValue(STATE_DROPDOWN_LIST.find(item => item.id === status));
    }, [status]);
    const handlerUpdateDrop = (item) => {
        setStatus(item.id);
        setValue(item);
    };
    return (React.createElement(DropDown, { className: "dropdown-tablet-small", value: value, list: STATE_DROPDOWN_LIST, styles: {
            ...(isMobile ? {} : { dropWrap: { width: isTablet ? 120 : 150 } }),
            itemWrap: {
                top: isTablet ? '50px' : '45px',
            },
        }, setDropValue: handlerUpdateDrop }));
});
