import { css, styled } from 'styled-components';
export const QnAListHeaderStyled = styled.div `
	display: flex;
	flex-direction: column;
	width: 100%;

	gap: ${props => (props.$isDesktop ? '14px' : '12px')};

	.active-bar {
		display: flex;
		flex-direction: row;
		gap: 14px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;
		flex: 1;
	}

	.btn_light_grey {
		padding: 8px 10px;
	}

	button {
		p {
			font-size: var(--b5-font-size, 12px);
			line-height: var(--b5-line-height, 22px);
			letter-spacing: var(--b5-letter-spacing, -0.02em);
		}
	}

	.info-wrap {
		display: inline-flex;
		${props => props.$isMobile
    ? css `
						gap: 8px;
						flex-direction: column;

						> * {
							width: 100%;
						}
				  `
    : props.$isTablet
        ? css `
						gap: 12px;
						width: 100%;
						height: 44px;
						flex: 1;
						justify-content: flex-end;
						flex-wrap: wrap;
				  `
        : css `
						gap: 12px;
						width: unset;
				  `}
	}

	.fext-field {
		input {
			border-color: var(--color-grey-w, #e6e8ed) !important;
		}
	}

	.qna-check {
		.checkbox-item {
			svg {
				width: 22px;
				height: 22px;
			}
			span {
				font-size: var(--b5-font-size, 12px);
				line-height: var(--b5-line-height, 22px);
			}
		}
	}

	.btn_line_pink {
		p {
			font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
			font-weight: var(--h5-font-weight, 700);
			${props => props.$isDesktop &&
    `
					font-size: var(--h5-font-size, 16px);
					line-height: var(--h5-line-height, 24px);
					letter-spacing: var(--h5-letter-spacing, -0.02em);
				`}
		}
	}

	.question-info {
		display: flex;
		flex-direction: row;
		gap: 8px;

		.grey {
			color: var(--color-grey-l, #83868a);
		}

		.pur {
			color: var(--pur-d, #8268d9);
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-weight: 700;
		}

		.pink {
			color: var(--color-pink, #f46790);
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-weight: 700;
		}

		p {
			${props => props.$isMobile
    ? css `
							font-size: 10px;
							line-height: 150%;
							letter-spacing: -0.02em;
							font-weight: 400;
					  `
    : css `
							font-size: var(--b4-font-size, 14px);
							line-height: var(--b4-line-height, 28px);
							letter-spacing: var(--b4-letter-spacing, -0.02em);
							font-weight: var(--b4-font-weight, 400);
					  `}
		}
	}

	${props => props.$isDesktop &&
    css `
			.search-bar-qna {
				input {
					font-size: var(--b4-font-size, 14px) !important;
					line-height: var(--b4-line-height, 28px);
					letter-spacing: var(--b4-letter-spacing, -0.02em);
					font-weight: var(--b4-font-weight, 400);
				}
			}
		`}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : '')}
`;
const mobileStyles = css `
	.drop-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.question-count-btn {
		border-radius: 50px;
		padding: 6px 10px 6px 10px;
		p {
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 10px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
		}
	}

	.btn-container {
		width: 100%;
		.mobile-full {
			p {
				justify-content: center;
			}
		}
	}
`;
const tabletStyles = css `
	.drop-wrap {
		display: flex;
		gap: 6px;
	}
	.qna-check {
		gap: 6px;
		label {
			span {
				font-size: 12px;
			}
		}
	}
`;
