import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../../../../../common';
import { AverageBox } from './AverageBox';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { UpperMemberList } from './UpperMemberList';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const UpperAverageStyled = styled.div `
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.fixed {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		align-self: stretch;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.score-container {
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: flex-end;
		position: relative;
	}
	.score {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 20px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.fixed2 {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 16px);
		line-height: var(--h4-line-height, 32px);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.btn-view-user {
		background: var(--tur-l, #458c94);
		border-radius: 10px;
		padding: 6px 12px 6px 12px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}
	.txt {
		color: var(--color-white, #ffffff);
		text-align: right;
		font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.score-wrap {
		width: 100%;
		justify-content: space-between;
	}
	.show-wrap {
		width: 100%;
	}
`;
const desktopStyles = css `
	.fixed {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b2-font-size, 18px);
		line-height: var(--b2-line-height, 36px);
		font-weight: var(--b2-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.score-container {
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		justify-content: flex-end;
		position: relative;
	}
	.score {
		display: flex;
		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
	}
	.value {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h3-font-size, 24px);
		line-height: var(--h3-line-height, 36px);
		font-weight: var(--h3-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.fixed2 {
		color: var(--color-black, #101010);
		text-align: right;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.btn-view-user {
		background: var(--tur-l, #458c94);
		border-radius: 10px;
		padding: 6px 12px 6px 12px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
	}
	.txt {
		color: var(--color-white, #ffffff);
		text-align: right;
		font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h5-font-size, 16px);
		line-height: var(--h5-line-height, 24px);
		font-weight: var(--h5-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.score-wrap {
		width: 100%;
	}
`;
export const UpperAverage = React.memo(({ test_id, subject_item, average, }) => {
    const { isTablet, isMobile } = useGlobalState();
    const onClick = (precedence) => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: UpperMemberList,
            componentProps: { precedence, subject_item, test_id },
            title: `상위 ${precedence}% 인원보기`,
            width: 564,
            height: isTablet && 'unset',
        });
    };
    const renderItem = useMemo(() => (React.createElement(UpperAverageStyled, { "$isMobile": isMobile, className: "upper-average box-wrap" }, ['10%', '50%'].map(percentage => (React.createElement("div", { className: "box-wrap-item", key: percentage, style: isMobile ? { flexDirection: 'column' } : {} },
        React.createElement("div", { className: "score-wrap" },
            React.createElement("div", { className: "fixed" },
                "\uC0C1\uC704 ",
                percentage,
                " \uD3C9\uADE0"),
            React.createElement("div", { className: "score-container" },
                React.createElement("div", { className: "score" },
                    React.createElement("div", { className: "value" }, average[percentage].score === -1
                        ? '-'
                        : average[percentage].score),
                    React.createElement("div", { className: "fixed2" }, "\uC810")),
                average[percentage].score !== -1 && !isMobile && (React.createElement(Button, { className: "btn_tur xs", text: "\uC778\uC6D0\uBCF4\uAE30", onClick: () => onClick(parseInt(percentage)) })))),
        average[percentage].score !== -1 && isMobile && (React.createElement("div", { className: "show-wrap" },
            React.createElement(Button, { className: "btn_tur xs", text: "\uC778\uC6D0\uBCF4\uAE30", onClick: () => onClick(parseInt(percentage)), styles: {
                    buttonWrap: {
                        width: '100%',
                        padding: '10px 12px',
                    },
                } })))))))), [average, isMobile]);
    return React.createElement(AverageBox, { title: "assignment.upperAverage", renderItem: renderItem });
});
