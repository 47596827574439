export const KLASS_MENU_LIST = [
    {
        id: 'assignment',
        value: '과제',
    },
    {
        id: 'test',
        value: '테스트',
    },
    {
        id: 'attendanceStatus',
        value: '출석현황',
    },
    {
        id: 'gradeCard',
        value: '성적표',
    },
    {
        id: 'notice',
        value: '공지사항',
    },
    {
        id: 'qna',
        value: 'Q&A 게시판',
    },
    {
        id: 'resource',
        value: '학습 자료',
    },
    {
        id: 'sendSMS',
        value: 'SMS 발송',
    },
    {
        id: 'content',
        value: '콘텐츠 관리',
    },
    {
        id: 'reservation',
        value: '강의 예약',
    },
    {
        id: 'previous-conference',
        value: '지난 강의',
    },
    {
        id: 'reply',
        value: '다시보기',
    },
];
export const DEFAULT_KLASS_MENU = [
    'assginment',
    'test',
    'attendance',
    'gradeCard',
    'notice',
    'qna',
    'learningSupportRoom',
    'sms',
    'content',
    'reservation',
    'previous-conference',
    'reply',
];
