import React, { useEffect } from 'react';
import { DropDown } from '../../../common';
import { useClassesList } from '../../../server/classes';
import { useGlobalState } from '../../../GlobalStateProvider';
import { useSelector } from 'react-redux';
import { getIsManager } from '../account';
import { Colors } from '../../../Colors';
export const ClassesDropDown = React.memo(({ userId, userTenantId, defaultClasses, setClasses, setTenantId, }) => {
    const { isTablet, isMobile } = useGlobalState();
    const isManager = useSelector((state) => getIsManager(state));
    const [dropValue, setDropValue] = React.useState({ id: 'select', title: 'common.select' });
    const [dropList, setDropList] = React.useState([]);
    const [dropDownItems, setDropDownItems] = React.useState([]);
    const { data, refetch } = useClassesList(!isManager && userId
        ? {
            belong_any: userId,
        }
        : {});
    React.useEffect(() => {
        refetch();
    }, [userId]);
    React.useEffect(() => {
        if (setTenantId && dropDownItems.length > 0) {
            const newItem = dropDownItems.filter(item => item.id === dropValue.id);
            setTenantId(newItem[0].tenant_id);
        }
        setClasses(dropValue.id);
    }, [dropValue]);
    React.useEffect(() => {
        if (!data)
            return;
        const default_select = userId ? undefined : { id: 'select', title: 'common.select' };
        const { items, total_count } = data;
        if (total_count > 0) {
            if (isManager && userTenantId !== 'default') {
                setDropDownItems(items.filter(item => item.tenant_id === userTenantId));
            }
            else {
                setDropDownItems(items);
            }
            setDropList(prevdata => {
                let newList = [];
                default_select && newList.push(default_select);
                const filteredItems = isManager && userTenantId !== 'default'
                    ? items.filter(item => item.tenant_id === userTenantId)
                    : items;
                filteredItems.map((item) => newList.push({
                    id: item.id,
                    title: item.title,
                    tenant_id: item.tenant_id,
                }));
                return newList;
            });
        }
        else {
            default_select && setDropList([]);
        }
    }, [data, userTenantId]);
    useEffect(() => {
        if (defaultClasses) {
            const selected = dropList.find(item => item.id === defaultClasses);
            if (selected)
                setDropValue(selected);
        }
    }, [defaultClasses, dropList]);
    return (React.createElement(DropDown, { className: userId
            ? `dropdown-home-drop ${isMobile ? 'drop-mobile' : isTablet ? 'drop-tablet' : 'drop-pc'}`
            : 'dropdown-tablet-small', value: dropValue, list: dropList, styles: {
            itemWrap: {
                maxHeight: 200,
                overflow: 'auto',
            },
        }, setDropValue: setDropValue, option: {
            downIcon: {
                size: isTablet ? 30 : 36,
                color: Colors.white,
            },
        } }));
});
