import React from 'react';
import styled from 'styled-components';
import { ReviewClassList } from '../../DSS/DSST001/DSST001_List_Child';
import { RegistButton } from '../RegistButton';
import { ReservationTable } from './component/DSTT001_List_Child';
import { useGlobalState } from '../../../GlobalStateProvider';
import { useDispatch } from 'react-redux';
import { updateMenu } from '../../menu/action';
const DSTT001_ListStyled = styled.div `
	width: 100%;
	display: flex;
	height: min-content;
	flex: 1;
	// background: var(--color-silver, #f3f4f8);
	.reservation-table {
		width: 100%;
		height: ${props => props.$isMobile ? 'min-content' : '100%'};
		min-height: 100%;
		${props => props.$isTeacher && props.$isMobile && `padding: 20px`};
	}

	${props => props.$isTablet && `
		padding: 20px;
		`}

	${props => !props.$isTeacher &&
    `display: flex; 
		flex-direction: column; 
		gap: 40px;  
		.review_class_list {
			min-height: 342px;
			}`}
			
	${props => props.$isMobile &&
    `overflow: auto;
		background: var(--color-silver, #f3f4f8);
		gap: ${props.$isTeacher ? '0px' : '12px'};
	`}

	
`;
export const DSTT001_List = React.memo(({ isClosed = false, isPrevious = false, isTeacher = false, }) => {
    const { isMobile, isTablet } = useGlobalState();
    const dispatch = useDispatch();
    const handlerButtonClick = () => {
        dispatch(updateMenu(undefined, {
            menu: isPrevious ? 'previous-conference' : 'reservation',
            detail: 'regist',
        }));
    };
    return (React.createElement(DSTT001_ListStyled, { "$isTeacher": isTeacher, "$isMobile": isMobile, "$isTablet": isTablet },
        React.createElement(ReservationTable, { isClosed: isClosed, isPrevious: isPrevious, isTeacher: isTeacher }),
        isTeacher ? (isPrevious ? null : (React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick }))) : (React.createElement(ReviewClassList, null))));
});
