import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../Colors';
import { Button, Icon } from '../../../../common';
import { QNA_STATUS } from '../constant';
export const hideID = (name) => {
    const hiddenPart = name.slice(3); // 문자열의 세 번째 인덱스부터 끝까지 추출
    const maskedPart = '*'.repeat(hiddenPart.length); // 추출한 부분의 길이만큼 '*' 문자를 반복
    const maskedString = name.slice(0, 3) + maskedPart; // 첫 세 글자와 가려진 부분을 결합하여 최종 결과 생성
    return maskedString;
};
export const hideName = (name) => {
    //이름이 2글자인 경우 첫 글자만 노출하고 나머지 글자를 '*'로 가림
    if (name.length <= 2) {
        return name[0] + '*';
    }
    //문자열의 맨 앞과 맨 뒤 글자를 제외한 나머지 글자를 '*'로 가림
    return name[0] + '*'.repeat(name.length - 2) + name[name.length - 1];
};
export const QnAItem = React.memo(({ isHome, isAnswer, isContent, isTeacher, isMobile, isTablet, isSelected, headItem, item, number, handlerShowDetail, }) => {
    const { t } = useTranslation();
    const renderStatus = headItem => {
        if (isContent && isTeacher && item[headItem.title] === QNA_STATUS.CREATED) {
            item[headItem.title] = QNA_STATUS.CLOSED;
        }
        return (React.createElement("p", { className: `overText2 ${item[headItem.title] === QNA_STATUS.CLOSED
                ? 'pink'
                : item[headItem.title] === QNA_STATUS.CREATED
                    ? 'purple'
                    : ''}` }, t(`supportRoom.${item.status}`)));
    };
    if (isMobile &&
        ['private', 'files', 'member', isHome ? '' : 'creation_time'].includes(headItem.title))
        return null;
    switch (headItem.title) {
        case 'answerStateAndName':
            return (React.createElement("div", { className: "qna-status-display", style: { gap: isMobile ? 8 : 12 } },
                React.createElement("div", { className: `qna_status_round ${item.status}` },
                    React.createElement("p", null, t(`supportRoom.${item.status}`))),
                React.createElement("p", { className: "overText" },
                    "[",
                    t(`supportRoom.${item.category}`),
                    "] ",
                    item.title)));
        case 'categoryAndName':
            return (React.createElement("div", { className: "overText" },
                "[",
                t(`supportRoom.${item.category}`),
                "] ",
                item.title));
        case 'files':
            return item.files.length > 0 ? (React.createElement("div", { className: "file" },
                React.createElement(Icon, { icon: "file", size: isTablet ? 14 : 20, fill: isSelected ? Colors.pur : Colors.w_grey }),
                React.createElement("p", { className: "overText2" },
                    "(",
                    item.files.length,
                    ")"))) : (React.createElement("p", null, "-"));
        case 'private':
            return item[headItem.title] ? (React.createElement(Icon, { icon: "locked", className: "locked", size: isTablet ? 14 : 20, fill: isSelected ? 'var(--pur-d, #8268d9)' : '#666666' })) : null;
        case 'content':
            return (React.createElement("div", { className: "locked-wrap overText2" },
                !isMobile && item.id !== item.root_id && (React.createElement(Button, { className: "btn_tur mobile-xxs", text: 'supportRoom.req' })),
                item['private'] && (React.createElement(Icon, { icon: "locked", className: "locked", size: 14, fill: isSelected ? 'var(--pur-d, #8268d9)' : '#666666' })),
                React.createElement("p", { className: `overText2 ${isMobile ? 'bold' : ''}` }, item.title)));
        case 'creation_time':
            return (React.createElement("p", { className: "overText2" }, moment(item[headItem.title]).format(isHome ? 'YYYY-MM-DD' : 'YYYY-MM-DD, HH:mm')));
        case 'status':
            return isMobile ? (React.createElement("div", { className: "qna-list-wrap" },
                item.files.length > 0 && (React.createElement("div", { className: "file" },
                    React.createElement(Icon, { icon: "file", size: 12, fill: isSelected ? Colors.pur : Colors.w_grey }),
                    React.createElement("p", { className: "overText2" },
                        "(",
                        item.files.length,
                        ")"))),
                React.createElement("p", { className: `overText2 ${isSelected ? '' : 'grey'}` }, moment(item['creation_time']).format('YYYY-MM-DD, HH:mm')),
                renderStatus(headItem))) : (renderStatus(headItem));
        case 'category':
            return isMobile ? (React.createElement("div", { className: "locked-wrap" },
                item.id !== item.root_id && (React.createElement(Button, { className: "btn_tur mobile-xxs", text: 'supportRoom.req' })),
                React.createElement("p", { className: "overText2" }, t(`supportRoom.${item[headItem.title]}`)))) : (React.createElement("p", { className: "overText2" }, t(`supportRoom.${item[headItem.title]}`)));
        case 'etc':
            return (!isMobile && (React.createElement("p", { className: "under-line overText2", onClick: () => handlerShowDetail(item.root_id, Math.max(item.number - 1, 0)) }, "\uC790\uC138\uD788 \uBCF4\uAE30")));
        case 'author_name':
            if (isAnswer)
                return null;
            return isTeacher ? (isMobile ? (React.createElement("div", { className: "qna-member-wrap" },
                React.createElement("p", { className: "overText2" }, t('supportRoom.question_name', {
                    name: `${item[headItem.title]} (${item['author_idf']})`,
                })))) : (React.createElement("p", { className: "overText2" },
                item[headItem.title],
                " (",
                item['author_idf'],
                ")"))) : (React.createElement("p", { className: "overText2" },
                hideName(item[headItem.title]),
                " (",
                hideID(item['author_idf']),
                ")"));
        case 'respondent_name':
            return (React.createElement("p", { className: "overText2" }, isMobile
                ? t('supportRoom.answer_name', {
                    name: isAnswer ? item.author_name : item.respondent_name,
                })
                : isAnswer
                    ? item.author_name
                    : item.respondent_name));
        case 'index':
            return (React.createElement("p", { className: "overText2 no-p", style: { ...(item.importance && { color: Colors.pur, fontWeight: 700 }) } }, item['importance'] ? '필독' : item.index));
        default:
            return React.createElement("p", { className: "overText2" }, item[headItem.title]);
    }
});
