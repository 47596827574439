import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../Colors';
import { Button } from '../../../common';
import { useGlobalState } from '../../../GlobalStateProvider';
import { AttandancesDown } from './DSTL006_List_Child/AttandancesDown';
import { AttandancesTable } from './DSTL006_List_Child/AttandancesTable';
const DSTL006ListStyled = styled.div `
	display: flex;
	flex-direction: row;
	gap: 24px;
	align-items: center;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	width: 100%;
	flex: 1;
	${props => props.$isTablet && `padding: 20px;`}
	.attend-list-001 {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		// overflow: hidden;
		width: 100%;
		height: 100%;
		min-width: ${props => (props.$isTablet ? '470px' : 'unset')};

		.reservation-table {
			width: 100%;
		}
	}

	.attend-list-002 {
		border-radius: 10px;
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: ${props => (props.$isMobile ? 0 : '1px')};
		padding: ${props => (props.$isMobile ? ' 0' : '20px')};
		display: flex;
		flex-direction: column;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.status_wrap {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		overflow: ${props => props.$isMobile && props.$isTablet && 'hidden'};
	}

	.down_btn {
		margin: 0 0 0 auto;
	}

	.empty_data {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: ${props => (props.$isTablet ? '12px' : '24px')};
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;

		p {
			font-size: ${props => props.$isTablet && '14px'};
		}
	}

	.goback {
		p {
			min-width: unset;
		}
	}
`;
export const DSTL006_Detail = React.memo(({ test_id, props }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    return (React.createElement(DSTL006ListStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop, "$isDetail": true }, !test_id ? (React.createElement("div", { className: "status_wrap" },
        React.createElement("div", { className: "attend-list-002" },
            React.createElement(Button, { className: "empty_data", option: {
                    buttonBeforeIcon: {
                        show: true,
                        name: 'none',
                        size: 36,
                        color: Colors.w_grey,
                    },
                }, text: "\uC120\uD0DD\uB41C \uC218\uC5C5 \uBC0F \uCC38\uC5EC\uC790 \uC815\uBCF4\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4" })))) : (React.createElement("div", { className: "status_wrap" },
        React.createElement("div", { className: "attend-list-002" },
            React.createElement(AttandancesTable, { id: test_id }),
            !isMobile && React.createElement(AttandancesDown, { test_id: test_id }))))));
});
