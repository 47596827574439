import React from 'react';
import { DropDown } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
const STATE_DROPDOWN_LIST = [
    { id: 'select', title: 'sendSMS.sendStauts' },
    { id: 'created', title: 'sendSMS.reservation' },
    { id: 'sent', title: 'sendSMS.sent' },
    { id: 'failed', title: 'sendSMS.failed' },
];
export const SendStatusDropdown = React.memo(({ sendStatus, setSendStatus }) => {
    const { isTablet, isMobile } = useGlobalState();
    const [value, setValue] = React.useState({
        id: 'select',
        title: 'sendSMS.sendStauts',
    });
    React.useEffect(() => {
        if (sendStatus !== value.id)
            setValue(STATE_DROPDOWN_LIST.find(item => item.id === sendStatus));
    }, [sendStatus]);
    const handlerUpdateDrop = (item) => {
        setSendStatus(item.id);
        setValue(item);
    };
    return (React.createElement(DropDown, { className: "dropdown-tablet-small", value: value, list: STATE_DROPDOWN_LIST, styles: {
            ...(isMobile ? {} : { dropWrap: { width: isTablet ? 120 : 150 } }),
            itemWrap: {
                top: isTablet ? '50px' : '45px',
            },
        }, setDropValue: handlerUpdateDrop }));
});
