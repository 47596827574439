import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { MobileTabLayout } from '../../base/layout';
import { updateMenu } from '../../menu/action';
import { RegistButton } from '../RegistButton';
import { OpenMemeberList, SendSMSListTable } from './DSTL007_Send_Child';
import { SendSMSContent } from './DSTL007_Send_Child/SendSMSContent';
const DSTL007SendStyled = styled.div `
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: ${props => (props.$isMobile ? '0px' : props.$isTablet ? '24px' : '20px')};
	position: relative;
	width: ${props => (props.$isTablet ? '' : '100%')};

	${props => !props.$isMobile &&
    `
		.open_member_list {
			display: flex;
			position: absolute;
			width: 100%;
			background: rgba(16, 16, 16, 0.3);
			z-index: 2;
			border-radius: 10px;
			justify-content: center;
			align-items: center;
			min-height: 100%;
				width: 100%;
				top: 0;
				left: 0;
				border: none;
			

			
		}
		.right_box {position: relative;
			flex: 2;
			display: flex;
			flex-direction: row;
			position: relative;
			
			padding: 20px;

			width: 100%;
			
		}
	`}
	${props => props.$isMobile &&
    `	
			${props.$mobilePage === 'send' && `padding: 20px`};
	
			.tab-item {
				display:flex;
				height: 100%;
			}

			.open_member_list {
				display: flex;
				position: fixed;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: rgba(16, 16, 16, 0.3);
				z-index: 2;
				justify-content: center;
    			align-items: center;
			}

			.open-member-list {
				background: #fff;
				width: 90%;
				height: 90%;
				border-radius: 10px;
				padding: 16px 20px;
				
			}
	`}
		${props => props.$isTablet &&
    `
		margin: 0 20px;
		padding: 20px 0;
		.mobile-tab-layout {
			width: unset;
			flex: 1;
			border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 1px;
			border-radius: 10px;
			padding:20px
		}
	`};
`;
export const DSTL007_Send = React.memo(({ mobilePage, id }) => {
    const dispatch = useDispatch();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [isOpen, setIsOpen] = useState(false);
    const [openMemberList, setOpenMemberList] = useState(false);
    const [sendMemberSetting, setSendMemberSetting] = useState('detail');
    const [receiverMemberList, setReceiverMemberList] = useState([]);
    const [editId, setEditId] = useState('');
    const updateEditId = (id) => {
        setEditId(id);
        setIsOpen(true);
        setOpenMemberList(false);
    };
    useEffect(() => {
        setEditId(id);
        setIsOpen(true);
        setOpenMemberList(false);
    }, [id]);
    const renderMobileView = useMemo(() => {
        let content = null;
        switch (mobilePage) {
            case 'send':
                content = (React.createElement(Fragment, { key: "test" },
                    React.createElement(SendSMSContent, { receiverMemberList: receiverMemberList, setIsOpen: setIsOpen, editId: editId, setEditId: setEditId, openMemberList: openMemberList, setOpenMemberList: setOpenMemberList, setReceiverMemberList: setReceiverMemberList, setSendMemberSetting: setSendMemberSetting }),
                    React.createElement("div", { className: "open_member_list", style: { display: openMemberList ? 'flex' : 'none' } },
                        React.createElement(OpenMemeberList, { receiverMemberList: receiverMemberList, setSendMemberSetting: setSendMemberSetting, setReceiverMemberList: setReceiverMemberList, setOpenMemberList: setOpenMemberList, sendMemberSetting: sendMemberSetting }))));
                break;
            default:
                content = (React.createElement(SendSMSListTable, { setIsOpen: setIsOpen, setEditId: setEditId, setReceiverMemberList: setReceiverMemberList }));
                break;
        }
        return React.createElement(Fragment, { key: mobilePage || 'test' },
            content,
            " ");
    }, [mobilePage, isOpen, editId, openMemberList, sendMemberSetting, receiverMemberList]);
    const renderDesktopView = () => (React.createElement(React.Fragment, null,
        React.createElement(SendSMSContent, { receiverMemberList: receiverMemberList, editId: editId, setEditId: setEditId, setIsOpen: setIsOpen, openMemberList: openMemberList, setOpenMemberList: setOpenMemberList, setReceiverMemberList: setReceiverMemberList, setSendMemberSetting: setSendMemberSetting }),
        React.createElement("div", { className: "open_member_list", style: { display: openMemberList ? 'flex' : 'none' } },
            React.createElement(OpenMemeberList, { receiverMemberList: receiverMemberList, setReceiverMemberList: setReceiverMemberList, setOpenMemberList: setOpenMemberList })),
        React.createElement("div", { className: "right_box" },
            React.createElement(SendSMSListTable, { setIsOpen: setIsOpen, setEditId: updateEditId }))));
    const handlerButtonClick = () => {
        dispatch(updateMenu(undefined, { menu: 'sendSMS', detail: 'send' }));
    };
    return (React.createElement(DSTL007SendStyled, { "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop, "$mobilePage": mobilePage, className: "dtsl-007-send" },
        isMobile ? (React.createElement(MobileTabLayout, { tabList: [
                {
                    id: 1,
                    component: [renderMobileView],
                },
            ], showNav: mobilePage !== 'list', hideHr: true })) : (renderDesktopView()),
        isMobile && mobilePage !== 'send' && (React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick }))));
});
