import React from 'react';
import { DropDown } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
const answerStateDropdownList = [
    { id: 'select', title: 'supportRoom.answer_state' },
    { id: 'open', title: 'supportRoom.answer_wait' },
    { id: 'closed', title: 'supportRoom.answer_complete' },
];
export const QnAAnswerStateDropDown = React.memo(({ answerState, setAnswerState, }) => {
    const { isTablet, isMobile } = useGlobalState();
    const [value, setValue] = React.useState({
        id: 'select',
        title: 'supportRoom.answer_state',
    });
    React.useEffect(() => {
        setValue(answerStateDropdownList.find(item => item.id === answerState) ||
            answerStateDropdownList[0]);
    }, [answerState]);
    const handlerUpdateDrop = (item) => {
        setValue(item);
        setAnswerState(item.id);
    };
    return (React.createElement(DropDown, { className: "dropdown-tablet-small", list: answerStateDropdownList, value: value, styles: {
            ...(!isMobile && { dropWrap: { width: 150 } }),
            itemWrap: {
                top: isTablet ? '50px' : '45px',
            },
        }, setDropValue: handlerUpdateDrop }));
});
