import React from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../GlobalStateProvider';
export const ToggleSwitch = React.memo(({ text, isOn, setIsOn, }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const handleToggle = () => {
        setIsOn(!isOn);
    };
    return (React.createElement(StyledMyQnA, { className: "toggle-switch-wrap", "$isOn": isOn, "$isMobile": isMobile, "$isTablet": isTablet, onClick: handleToggle },
        React.createElement("div", { className: "toggle-switch" },
            React.createElement("div", { className: "ellipse" })),
        text && React.createElement("div", { className: "label" }, text)));
});
const StyledMyQnA = styled.div `
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	cursor: pointer;

	.toggle-switch {
		background: ${({ $isOn }) => ($isOn ? '#a08ede' : '#ccc')};
		border-radius: 100px;
		padding: 4px;
		display: flex;
		align-items: center;
		justify-content: ${({ $isOn }) => ($isOn ? 'flex-end' : 'flex-start')};
		width: 52px;
		transition: background 0.3s, justify-content 0.3s;
	}

	.ellipse {
		background: #ffffff;
		border-radius: 50%;
		width: 18px;
		height: 18px;
		flex-shrink: 0;
	}

	.label {
		color: #101010;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: ${({ $isMobile }) => ($isMobile ? '14px' : '16px')};
		line-height: 32px;
		letter-spacing: -0.02em;
		font-weight: 400;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
`;
