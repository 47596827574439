import React from 'react';
import { useTranslation } from 'react-i18next';
import { css, styled } from 'styled-components';
import { Colors } from '../../Colors';
import { useGlobalState } from '../../GlobalStateProvider';
import { Icon } from '../element';
const EmptyStyled = styled.div `
	${props => props.$isMobile
    ? css `
					& {
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						gap: 8px;
						align-items: center;
						justify-content: center;
						position: relative;
					}

					&:not(.button) {
						p {
							color: var(--color-grey, #666666);
							text-align: left;
							font-family: 'NotoSansKr-Regular', sans-serif;
							font-size: 16px;
							line-height: 150%;
							letter-spacing: -0.02em;
							font-weight: 400;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: flex-start;
						}
					}
			  `
    : props.$isTablet
        ? css `
					& {
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						gap: 8px;
						align-items: center;
						justify-content: center;
						position: relative;
					}

					&:not(.button) {
						p {
							color: var(--color-grey, #666666);
							text-align: left;
							font-family: 'NotoSansKr-Regular', sans-serif;
							font-size: 18px;
							line-height: 150%;
							letter-spacing: -0.02em;
							font-weight: 400;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: flex-start;
						}
					}
			  `
        : css `
					& {
						width: 100%;
						height: 100%;

						display: flex;
						flex-direction: column;
						gap: 20px;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
					}

					&:not(.button) {
						p {
							color: var(--color-grey, #666666);
							text-align: left;
							font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
							font-size: var(--b1-font-size, 20px);
							line-height: var(--b1-line-height, 42px);
							letter-spacing: var(--b1-letter-spacing, -0.02em);
							font-weight: var(--b1-font-weight, 400);
							position: relative;
							display: flex;
							align-items: center;
							justify-content: flex-start;
						}
					}
			  `}
`;
export const EmptyTable = React.memo(({ title, iconProps, }) => {
    const { isMobile, isTablet } = useGlobalState();
    const { t } = useTranslation();
    return (React.createElement(EmptyStyled, { className: "empty_data", "$isMobile": isMobile, "$isTablet": isTablet, style: { color: Colors.light_grey } },
        iconProps && React.createElement(Icon, { ...iconProps }),
        t(title)));
});
