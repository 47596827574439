import { ReducerRegistry } from '../../redux/regist';
import { CHANGE_MENU_PROPS, CHANGE_MOBIL_MENU_NAME, SET_MENU } from './actionType';
const initialState = {
    depths: 'home',
    menu: { menu: 'home', detail: 'list' },
    id: '',
    props: {},
    name: '',
};
ReducerRegistry.register('base/menu', (state = initialState, action) => {
    switch (action.type) {
        case SET_MENU:
            return {
                ...state,
                depths: action.depths,
                menu: action.menu,
                id: action.id,
                props: action.props,
            };
        case CHANGE_MENU_PROPS:
            return {
                ...state,
                props: action.props,
            };
        case CHANGE_MOBIL_MENU_NAME:
            return {
                ...state,
                name: action.name,
            };
    }
    return state;
});
