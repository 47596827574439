import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MenuBar } from '../../common';
import { useGlobalState } from '../../GlobalStateProvider';
import { MyPageDropDown } from '../base/account';
import { getHiddenMenus } from '../base/classes';
import { getFilterMenuList } from '../menu/function';
/**
 * 네비게이션 바 컨테이너
 * @param menu 메뉴
 * @param setMenu 메뉴 변경 함수
 *
 * @returns 네비게이션 바 컨테이너
 *
 * @example
 * 로고, 메뉴,  마이페이지 버튼이 있는 네비게이션 바 컨테이너
 */
export const NavbarContainer = React.memo(({ isFooter = false, isTeacher, setIsHovered, menu, setMenu, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const hiddenMenus = useSelector((state) => getHiddenMenus(state));
    // list를 classesMenu에 따라 필터링하여 생성
    const list = useMemo(() => {
        // menuList에 포함되는 각 메뉴에 대해 classesMenu를 확인하여 필터링
        const filteredList = getFilterMenuList(isTeacher, hiddenMenus);
        // 데스크톱이 아닌 경우 'logout' 메뉴 추가
        if (!isDesktop) {
            filteredList.push('logout');
        }
        return filteredList;
    }, [isTeacher, isDesktop, hiddenMenus]);
    return (React.createElement(React.Fragment, null,
        ((!isFooter && isDesktop) || (isFooter && (isMobile || isTablet))) && (React.createElement(MenuBar, { className: `menu ${isTablet ? 'tablet' : ''}`, isRow: true, isClickDisabled: false, list: list, value: menu, onItemClick: setMenu, setIsHovered: setIsHovered, option: {
                titleIcon: {
                    show: isMobile || isTablet ? true : false,
                    size: 24,
                },
            } })),
        !isFooter && React.createElement(MyPageDropDown, { isSidebar: true })));
});
