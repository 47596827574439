import throttle from 'lodash/throttle';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Button } from '../../../common';
import { FileDown } from '../../../common/option/FileDown';
import { useGlobalState } from '../../../GlobalStateProvider';
import { postDeletePosts, useNoticeList } from '../../../server';
import { DetailTitleInfo } from '../../base/layout';
import { DeletePopUpLayout } from '../../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../../base/popup';
import { changeMobileMenuName, goToPrevMenu, updateMenu } from '../../menu/action';
import { REFRESH_POSTS_LIST } from './constant';
import { RegistContent } from './DSTSL001_Regist_Child';
const commonStyles = css `
	width: 100%;
	flex: 1;
	${props => props.$isMobile && `height: 100%;`}
	.attach-files {
		width: 100%;
	}

	.quill_wrap {
		height: 100%;
	}

	.teacher-info {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		padding: 0px 0px 8px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		text-align: left;
		font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b4-font-size, 14px);
		line-height: var(--b4-line-height, 28px);
		letter-spacing: var(--b4-letter-spacing, -0.02em);
		font-weight: var(--b4-font-weight, 400);
		color: var(--color-grey, #666666);

		.name {
			display: flex;
			flex-direction: row;
			gap: 12px;
		}
	}

	.btn-container {
		padding-top: 0px;
		position: absolute;
		right: 28px;
	}

	.underline {
		text-decoration: underline;
	}

	.green {
		color: var(--tur-d, #479da6);
	}

	.pur-d {
		color: var(--pur-d, #8268d9);
	}

	.pink {
		color: var(--color-accent, #e7424c);
	}

	.open-contents {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 20px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		color: var(--natural-color-n-0, #2f2f2f);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 16px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		height: 100%;

		.img_wrap {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.img {
				border-radius: 4px;
				flex-shrink: 0;
				width: 100px;
				height: 100px;
				position: relative;
				object-fit: cover;
				background: #000;
			}
		}
	}

	.content-main {
		width: 100%;
		border-style: dashed;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px 0px 1px 0px;
		flex: 1;

		.fext-field {
			border: none;
		}
	}

	svg {
		min-width: 16px;
	}

	.btn-container {
		padding: 0;
	}

	.content-info-section {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 24px;

		.content-infos {
			gap: 8px;
			display: flex;
			flex-direction: column;
			width: 100%;
			overflow: hidden;
		}

		.content-info {
			gap: 12px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.content-info-title {
			font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: 16px;
			line-height: 32px;
			white-space: nowrap;
		}

		.content-info-filename {
			align-self: center;
			font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: 16px;
			line-height: 32px;
		}

		.content_empty_file {
			background: var(--color-white, #ffffff);
			color: var(--color-grey-l, #83868a);
			text-align: left;
			font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b5-font-size, 12px);
			line-height: var(--b5-line-height, 22px);
			letter-spacing: var(--b5-letter-spacing, -0.02em);
			font-weight: var(--b5-font-weight, 400);
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 100%;
			padding-left: 10px;
		}

		.content-info-value {
			display: inline-flex;
			align-items: center;
			font-size: 16px;
			line-height: 14px;
			font-weight: 400;
			flex-wrap: wrap;
			gap: 10px;
		}

		.edit-delete {
			display: flex;
			flex-direction: row;
			gap: 8px;
		}

		.no-file-wrap {
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: flex-start;
			flex: 1;
			position: relative;
			overflow: auto;
			width: 100%;
		}
	}

	.divider {
		width: 1px;
		height: 14px;
		background-color: var(--color-grey-c, #e6e8ed);
	}
`;
const mobileStyles = css `
	.open-contents {
		padding: 16px 20px;

		.img_wrap {
			gap: 8px;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
		}
		.img_pair {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
	}

	.teacher-info {
		border-width: 0px 0px 0.5px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
				font-size: 12px;
			}
		}
	}

	.content-info-section {
		.content-info {
			height: auto;
		}

		.content-infos {
			gap: 4px;
		}

		.content-info-title {
			font-size: 14px;
		}

		.content-info-value {
			font-size: 14px;
			min-width: 93px;
		}

		.content-info-filename {
			align-self: start;
			font-size: 14px;
			flex-direction: row;
		}
	}

	.content-main {
		padding: 16px 0;
	}

	.no-mw {
		.overText {
			font-size: 12px;
			min-width: 0px;
		}
	}

	.edit-delete {
		width: -webkit-fill-available;
		justify-content: flex-end;
	}

	.disable-text {
		p {
			font-size: 14px;
		}
	}
`;
const tabletStyles = css `
	padding: 20px;
	.open-contents {
		padding: 0px;
		.img_wrap {
			gap: 12px;
		}
	}

	.content-info-section {
		.content-info {
			height: auto;
		}

		.content-info-value {
			min-width: 93px;
		}

		.content-info-filename {
			align-self: start;
			line-height: 25px;
		}
	}

	.content-main {
		padding: 16px 0;
	}

	.file-container {
		.file-item-wrap {
			padding: 0px;
		}
	}

	.teacher-info {
		border-width: 0px 0px 1px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
			}
		}
	}

	.edit-delete {
		p {
			min-width: unset;
		}
		width: -webkit-fill-available;
		justify-content: flex-end;

		gap: 12px;
	}
`;
const desktopStyles = css `
	.open-contents {
		padding: 24px 0px;
		height: calc(100% - 52px);
	}

	.content-main {
		padding: 20px 0;
	}

	.teacher-info {
		border-width: 0px 0px 1px 0px;
		.active-bar {
			.button p {
				min-width: fit-content;
			}
		}
	}
`;
const DSTL001DetailStyled = styled.div `
	${commonStyles}

	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
export const DSTSL001_Detail = ({ isResource = false, isTeacher, selectedId, }) => {
    const { isDesktop, isMobile, isTablet } = useGlobalState();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [testData, setTestData] = React.useState(null);
    const deletePostMutation = useMutation(postDeletePosts);
    //조회수 증가
    const { data, refetch } = useNoticeList({
        id: selectedId,
        with_content: true,
    });
    useEffect(() => {
        selectedId && refetch();
    }, [selectedId]);
    useEffect(() => {
        if (data) {
            const { items } = data;
            if (items && items.length > 0) {
                setTestData(items[0]);
            }
        }
    }, [data]);
    useEffect(() => {
        if (testData && !isDesktop) {
            dispatch(changeMobileMenuName(testData.title));
        }
    }, [testData, isDesktop, isTablet, isMobile]);
    // (리스트 갱신
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        APP.eventManager.subscribe('REFRESH_POSTS_DETAIL_LIST', refetchList);
        return () => {
            APP.eventManager.unsubscribe('REFRESH_POSTS_DETAIL_LIST', refetchList);
        };
    }, []);
    //게시글 삭제
    const handleDelete = () => {
        const onDelete = async () => {
            const id = testData.id;
            try {
                await deletePostMutation.mutateAsync(id);
                handlerPreviousPage();
                APP.eventManager.publish(REFRESH_POSTS_LIST);
            }
            catch (error) {
                console.error('Failed to post deletePost:', error);
                alert(error);
            }
        };
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DeletePopUpLayout,
            componentProps: {
                deleteContent: t('assignment.delteInto', { count: 1 }),
                onDelete,
            },
            width: 639,
            title: t(`supportRoom.${isResource ? 'resource' : 'notice'}Delete`),
            isFit: true,
        });
    };
    const handleEdit = () => {
        dispatch(updateMenu('learningSupportRoom', {
            menu: isResource ? 'resource' : 'notice',
            detail: 'modify',
        }, testData.id, {
            item: testData,
            isModify: true,
            isTeacher,
            isResource,
            isPopup: true,
        }));
    };
    const handlerPreviousPage = () => {
        dispatch(goToPrevMenu());
    };
    /**
     *  상단 타이틀
     */
    const renderTitleInfo = useMemo(() => (React.createElement(DetailTitleInfo
    // title={t(`supportRoom.${isResource ? 'resource' : 'notice'}`)}
    , { 
        // title={t(`supportRoom.${isResource ? 'resource' : 'notice'}`)}
        title: testData?.title, previousPage: handlerPreviousPage })), [isResource, testData]);
    const creationDate = String(testData?.creation_time).split('T')[0];
    return (React.createElement(DSTL001DetailStyled, { className: "dstl001_detail", "$isMobile": isMobile, "$isTablet": isTablet, "$isDetail": selectedId ? true : false },
        isDesktop && renderTitleInfo,
        React.createElement("div", { className: "open-contents" },
            React.createElement("div", { className: "content-info-section" },
                React.createElement("div", { className: "content-infos" },
                    React.createElement("div", { className: "content-info" },
                        React.createElement("div", { className: "content-info-title" }, t('table.creation_time')),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "content-info-value" }, creationDate),
                        (isMobile || isTablet) && isTeacher && (React.createElement("div", { className: "edit-delete" },
                            React.createElement(Button, { className: "underline pur-d no-mw", text: 'common.modify', onClick: handleEdit }),
                            React.createElement(Button, { className: "underline pink no-mw", text: 'common.delete', onClick: handleDelete })))),
                    React.createElement("div", { className: "content-info" },
                        React.createElement("div", { className: "content-info-title" }, t('table.views')),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "content-info-value" }, testData?.views)),
                    React.createElement("div", { className: "content-info" },
                        React.createElement("div", { className: "content-info-title" }, t('table.attachFile')),
                        React.createElement("div", { className: "divider" }),
                        React.createElement("div", { className: "content-info-value" },
                            React.createElement(FileDown, { files: testData?.files })))),
                isDesktop && isTeacher && (React.createElement("div", { className: "edit-delete" },
                    React.createElement("div", { className: "active-bar " },
                        React.createElement(Button, { className: "button btn_line_light_grey little-border-radius", text: 'common.modify', onClick: handleEdit })),
                    React.createElement("div", { className: "active-bar " },
                        React.createElement(Button, { className: "button btn_line_pink little-border-radius", text: 'common.delete', onClick: handleDelete }))))),
            React.createElement("div", { className: "content-main" },
                React.createElement(RegistContent, { isDisbaled: true, hideTitle: true, contentName: t('supportRoom.content'), defaultValue: testData?.content, isTeacher: isTeacher, isLearningSupport: true })),
            React.createElement("div", { className: "list-button-section", style: {
                    ...(isMobile ? { height: 'unset', paddingBottom: '24px' } : {}),
                } },
                React.createElement(Button, { className: "button btn_default little-border-radius list-button", text: '목록', onClick: handlerPreviousPage })))));
};
