import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Colors } from '../../../../Colors';
import { Button, FileInput } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
const AttachFileAnswerStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	justify-content: center;
	width: 100%;

	.attach-file-column {
		display: flex;
		flex-direction: column;
		gap: ${props => props.$isMobile ? '12px' : '20px'};
		align-items: center;
		justify-content: center;
	}

	.attach-file-answer {
		width: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		gap: 12px;
	}

	.attach-file-box-render {
		display: flex;
		flex-direction: row;
		gap: 16px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.label {
			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: var(--h5-font-size, 16px);
			line-height: var(--h5-line-height, 24px);
			letter-spacing: var(--h5-letter-spacing, -0.02em);
			font-weight: var(--h5-font-weight, 700);
			position: relative;
			width: 32px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}

		.attach-file-box {
			background: var(--color-white, #ffffff);
			border-radius: 4px;
			padding: 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: flex-start;
			flex: 1;
			height: 50px;
			position: relative;
			overflow: hidden;

			.btn-file {
				background: var(--color-white, #ffffff);
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 10px;
				display: flex;
				flex-direction: row;
				gap: 10px;
				align-items: center;
				justify-content: flex-start;
				flex: 1;
				height: 50px;
				position: relative;
				overflow: hidden;

				.button {
					overflow: hidden;

					svg {
						min-width: 20px;
					}
				}
			}
		}
		.attachment-button-container {
			width: ${props => props.$isMobile ? '64px' : '80px'};
			display: flex;
			flex-direction: row;
			gap: 16px;
		}
	}
`;
// 첨부 파일 컴포넌트
export const AttachFileAnswer = React.memo(({ attachFileList, setAttachFileList, isRegist, showRegist = false, isAnswer = false, isTeacher, }) => {
    const { t } = useTranslation();
    const { isDesktop, isMobile } = useGlobalState();
    const fileRef = React.useRef(null);
    const [fileBoxes, setFileBoxes] = React.useState(attachFileList.length > 0
        ? Array.from({ length: attachFileList.length }, (_, i) => i)
        : [0]);
    const acceptedFileTypes = 'image/*,application/pdf,.xlsx, .xls, .csv, .ppt, .pptx, .pdf, .doc, .docx, .txt, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain';
    React.useEffect(() => {
        setFileBoxes(attachFileList.length > 0
            ? Array.from({ length: attachFileList.length }, (_, i) => i)
            : [0]);
    }, [attachFileList]);
    const downloadFile = (url) => {
        const formattedUrl = url.replace(/\s/g, '%20');
        const a = document.createElement('a');
        a.href = formattedUrl;
        a.download = url.split('/').pop() || 'download';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    // 파일 추가 처리
    const onChangeFile = (e, index) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
            const updatedList = [...attachFileList];
            updatedList[index] = file;
            setAttachFileList && setAttachFileList(updatedList);
        }
        if (fileRef.current)
            fileRef.current.value = '';
    };
    // 새로운 파일 박스 추가
    const addNewBox = () => {
        setFileBoxes(prev => [...prev, prev.length]);
        const updatedList = [...attachFileList];
        updatedList.push(null);
        setAttachFileList && setAttachFileList(updatedList);
    };
    // 파일 박스 삭제
    const deleteBox = (index) => {
        // 파일 리스트에서 해당 인덱스의 파일 제거
        const updatedList = attachFileList.filter((_, i) => i !== index);
        setAttachFileList && setAttachFileList(updatedList);
        // fileBoxes 재정렬 (0부터 순차적으로)
        const updatedBoxes = Array.from({ length: fileBoxes.length - 1 }, (_, i) => i);
        setFileBoxes(updatedBoxes);
    };
    const handleDrop = e => {
        e.preventDefault();
        alert('폴더를 등록할 수 없습니다.');
    };
    const handleDragOver = e => {
        e.preventDefault();
    };
    const renderAttachFileButton = (index) => (React.createElement(FileInput, { ref: fileRef, id: `dst_attach_file_${index}`, onChange: (e) => onChangeFile(e, index), accept: acceptedFileTypes },
        React.createElement(Button, { option: {
                buttonBeforeIcon: {
                    name: 'add_folder',
                    color: Colors.dark_tur,
                    size: isMobile ? 24 : 32,
                    show: true,
                },
            } })));
    const renderActionButtons = (index) => {
        const isLastBox = index === fileBoxes[fileBoxes.length - 1];
        const hasFile = attachFileList[index] != null;
        return (React.createElement(React.Fragment, null,
            isLastBox && hasFile && (React.createElement(Button, { option: {
                    buttonBeforeIcon: {
                        name: 'add_ring',
                        color: Colors.dark_tur,
                        size: isMobile ? 24 : 32,
                        show: true,
                    },
                }, onClick: addNewBox })),
            hasFile && (React.createElement(Button, { option: {
                    buttonBeforeIcon: {
                        name: 'minus_ring',
                        color: Colors.dark_tur,
                        size: isMobile ? 24 : 32,
                        show: true,
                    },
                }, onClick: () => deleteBox(index) }))));
    };
    const renderAttachFile = (index) => {
        const file = attachFileList[index];
        return (React.createElement("div", { key: index, className: "attach-file-box-render" },
            React.createElement("p", { className: "label" }, index + 1),
            React.createElement("div", { onDrop: handleDrop, onDragOver: handleDragOver, className: "attach-file-box" },
                React.createElement("div", { className: "btn-file" }, file ? (React.createElement(Button, { className: `btn btn_sliver md_round body5 cursor  ${isMobile ? 'mobile-xxs' : ''}`, text: typeof file === 'string'
                        ? file.split('/')[file.split('/').length - 1]
                        : file.name, styles: {
                        buttonWrap: {
                            height: 'fit-content',
                        },
                    }, option: {
                        buttonBeforeIcon: {
                            name: 'file',
                            color: Colors.dark_tur,
                            size: isMobile ? 12 : 20,
                            show: true,
                        },
                        buttonAfterIcon: {
                            name: !showRegist && !isAnswer && typeof file === 'string'
                                ? 'file-down'
                                : '',
                            color: Colors.accent,
                            size: !showRegist && !isAnswer && typeof file === 'string'
                                ? 16
                                : 12,
                            show: showRegist || isAnswer
                                ? true
                                : typeof file === 'string',
                        },
                    }, onClick: !showRegist && typeof file === 'string'
                        ? () => downloadFile(file)
                        : () => { } })) : (React.createElement("p", { className: "body5 noAttachFilePlaceholer", style: { color: Colors.light_grey, width: '100%' } }, isTeacher && isRegist
                    ? t('assignment.attachFilePlaceholer')
                    : t('assignment.noAttachFilePlaceholer'))))),
            React.createElement("div", { className: "attachment-button-container" },
                showRegist && !file && renderAttachFileButton(index),
                showRegist && renderActionButtons(index))));
    };
    return (React.createElement(AttachFileAnswerStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile },
        React.createElement("div", { className: "attach-file-answer" },
            !isTeacher ? (React.createElement("div", { className: "task-label" }, isRegist
                ? t('assignment.attachFilePlaceholer')
                : t('assignment.attachFile'))) : (React.createElement("div", { className: "hr" })),
            React.createElement("div", { className: "attach-file-column" }, fileBoxes.map(index => renderAttachFile(index))))));
});
