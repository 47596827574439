import { throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TOGGLE_POPUP } from '../../../base/popup';
import { Button, FileInput, Icon, InputElement } from '../../../../common';
import { Colors } from '../../../../Colors';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { getClassesId } from '../../../base/classes';
import { postExcelUpload } from '../../../../server/sms';
import { useMutation } from 'react-query';
import { useMemberInfo } from '../../../../server';
const UploadPopUpStyled = styled.div `
	height: 100%;
	.pink {
		color: var(--color-pink, #f46790);
		text-align: center;
		font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
		font-weight: var(--b1-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.text {
		color: var(--color-black, #101010);
		text-align: center;
		font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);

		font-weight: var(--b3-font-weight, 400);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.body-wrap {
		width: 100%;
		white-space: break-spaces;
		text-align: center;
	}

	.excel-download {
		border-radius: 4px;
		padding: 4px 6px;
	}

	${props => (props.$isMobile ? mobileStyled : props.$isTablet ? tabletStyled : desktopStyled)}
`;
const mobileStyled = css `
	.pink {
		font-size: var(--b1-font-size, 18px);
		line-height: 150%;
		letter-spacing: var(--b1-letter-spacing, -0.02em);
	}
	.text {
		font-size: var(--b3-font-size, 14px);
		line-height: 150%;
		letter-spacing: var(--b3-letter-spacing, -0.02em);
	}
`;
const tabletStyled = css `
	.pink {
		font-size: var(--b1-font-size, 22px);
		line-height: var(--b1-line-height, 42px);
		letter-spacing: var(--b1-letter-spacing, -0.02em);
	}
	.text {
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		letter-spacing: var(--b3-letter-spacing, -0.02em);
	}
`;
const desktopStyled = css `
	.pink {
		font-size: var(--b1-font-size, 20px);
		line-height: var(--b1-line-height, 42px);
		letter-spacing: var(--b1-letter-spacing, -0.02em);
	}

	.text {
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		letter-spacing: var(--b3-letter-spacing, -0.02em);
	}
`;
export const UploadPopUp = React.memo(({ setReceiverMemberList, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const classesId = getClassesId();
    const [file, setFile] = React.useState(); //파일
    const [fileName, setFileName] = React.useState(''); //파일명
    const [receivers, setReceivers] = React.useState([]); //업로드 할 수신자 목록
    const [memberData, setMemberData] = React.useState([]); //아이디로 조회한 수신자 목록
    const [noPhoneMemberList, setNoPhoneMemberList] = React.useState([]); //번호가 없는 사용자 목록
    const [isUpload, setIsUpload] = React.useState(false); //업로드 성공 여부
    const excelUploadMutation = useMutation(postExcelUpload);
    useEffect(() => {
        if (file) {
            setFileName(file.name);
        }
    }, [file]);
    const handleUpload = async () => {
        try {
            const response = await excelUploadMutation.mutateAsync(file);
            if (response) {
                setIsUpload(true);
                if (response.result.receivers.length === 0) {
                    setIsUpload(false);
                }
                else {
                    setReceivers(response.result.receivers);
                }
            }
            else {
                setIsUpload(false);
            }
        }
        catch (error) {
            console.error('Error Excel Upload ', error);
            setIsUpload(false);
        }
    };
    useEffect(() => {
        if (fileName !== '') {
            handleUpload();
        }
    }, [fileName]);
    const closePopup = () => APP.eventManager.publish(TOGGLE_POPUP, undefined);
    const { data, refetch } = useMemberInfo({
        class_id: classesId,
        id: receivers,
        // status: 'created',
        level: ['03'],
    });
    useEffect(() => {
        if (data) {
            const { items } = data;
            setMemberData(items.filter(data => data.phone_number !== '-'));
            setNoPhoneMemberList(items.filter(data => data.phone_number === '-'));
        }
    }, [data]);
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        refetchList();
    }, [receivers]);
    const handlerAdd = () => {
        if (receivers.length > 0) {
            const newReceiverList = receivers.filter(item => !memberData.map(member => member.id).includes(item));
            const receiverList = newReceiverList
                .filter(item => item.length <= 11) //아이디로 등록하는 사람 중 번호가 없는 사용자를 제외하기 위해
                .map((item, index) => ({
                id: item,
                creation_time: null,
                identifier: null,
                level: '',
                level_name: '',
                phone_number: item,
                nickname: item,
                status: 'inactive',
                teacher_code: '',
                tenant_id: '',
            }));
            //@ts-ignore
            setReceiverMemberList(prevData => {
                const list = [
                    ...(prevData ? [...prevData] : []),
                    ...memberData.filter(data => !prevData.some(item => item.id === data.id)),
                    ...receiverList.filter(data => !prevData.some(item => item.id === data.id)),
                ];
                return list;
            });
            if (receiverList.length + memberData.length !== receivers.length) {
                alert(`${t('sendSMS.noPhoneNumber')} (${noPhoneMemberList.map(item => item.identifier)})`);
            }
            closePopup();
        }
        else {
            alert(t('sendSMS.checkFile'));
        }
    };
    function download(url) {
        // fetch(url)
        // 	.then(response => response.blob())
        // 	.then(blob => {
        // 		const link = document.createElement('a');
        // 		link.href = URL.createObjectURL(blob);
        // 		link.download = '수신자 다운로드 양식.xlsx';
        // 		link.click();
        // 	})
        // 	.catch(console.error);
    }
    const handlerDownload = async () => {
        const url = `/api/mimac/v1/messages/download-excel-form?&class_id=${classesId}`;
        //const response = await download(url);
        window.location.href = url;
    };
    return (React.createElement(UploadPopUpStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: "upload-popup" },
        React.createElement("div", { className: "flex column center", style: { justifyContent: 'space-between', height: '100%' } },
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "fext-field excel-download search-bar", style: {
                        justifyContent: 'space-between',
                    } },
                    React.createElement(InputElement, { type: "text", placeholder: t('sendSMS.selectFile'), value: fileName, isDisable: true, style: { borderStyle: 'none' } }),
                    React.createElement(FileInput, { accept: ".xlsx, .xls", setFile: setFile },
                        React.createElement("div", { className: "flex row icon" },
                            React.createElement(Icon, { icon: "file_upload", size: 25, fill: Colors.tur })))),
                React.createElement(Button, { className: "btn_white xmd", text: t('sendSMS.downloadForm'), onClick: handlerDownload })),
            React.createElement("div", { className: "body-wrap" }, fileName !== '' && (React.createElement("div", { className: "upload-file" },
                React.createElement(React.Fragment, null,
                    React.createElement(Icon, { icon: isUpload ? 'check-circle' : 'none', size: isUpload ? 45 : 35, fill: Colors.w_grey }),
                    React.createElement("p", { className: "pink" }, isUpload
                        ? t('sendSMS.fileUploadOk')
                        : t('sendSMS.fileUploadFail')),
                    React.createElement("p", { className: "text" }, isUpload
                        ? t('sendSMS.fileAddCount', { count: receivers.length })
                        : t('sendSMS.fileReUoload')))))),
            React.createElement("div", { className: "btn-container" },
                React.createElement(Button, { className: "btn_white sm", text: t('common.cancel'), onClick: closePopup }),
                React.createElement(Button, { className: "btn_default sm", text: t('common.okay'), onClick: handlerAdd })))));
});
