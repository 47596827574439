import { css, styled } from 'styled-components';
export const QnAListTableStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isMobile ? '12px' : '24px')};
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;
	width: 100%;
	height: 100%;

	${props => props.$isHistory &&
    `
		.empty_data {
			padding: 20px;
		}
	`}

	.more_item {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;

		${props => (props.$isMobile ? `padding: 10px 0px;` : 'padding-top: 20px;')}
	}

	.body-item {
		overflow: hidden;
		${props => props.$isMobile
    ? `
					font-size: var(--b4-font-size, 12px);
					line-height: var(--b4-line-height, 24px);
					letter-spacing: var(--b4-letter-spacing, -0.02em);
					font-weight: var(--b4-font-weight, 400);
					`
    : props.$isTablet
        ? `
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);`
        : `

					font-size: var(--b3-font-size, 16px);
					line-height: var(--b3-line-height, 32px);
					letter-spacing: var(--b3-letter-spacing, -0.02em);
					font-weight: var(--b3-font-weight, 400);
				`}
	}

	.locked-wrap {
		.button {
			padding: 4px 8px 4px 8px;
			p {
				min-width: fit-content;
			}
		}
	}

	.qna-status-display {
		display: flex;
		flex-direction: row;
		flex: 1;
		overflow: hidden;
		align-items: center;
	}

	${({ $isHome }) => $isHome &&
    `
		.creation_time {
			min-width: 100px;
			flex: unset!important;
			p {
				min-width: 100px;
				text-align: right !important;
			}
		}

	`}

	.qna_status_round {
		border-radius: 99px;
		border-style: solid;
		border-width: 1px;
		padding: 0px 4px 0px 4px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		text-align: center;

		p {
			font-family: var(--b-l-medium-font-family, 'NotoSansKr-Regular', sans-serif);
			width: ${props => (props.$isHome ? '76px' : '74px')};
		}

		&.closed {
			border-color: var(--color-pink, #f46790);

			color: var(--color-pink, #f46790);
		}

		&.open {
			border-color: var(--color-grey-l, #83868a);

			color: var(--color-grey-l, #83868a);
		}
	}

	.no-p {
		white-space: unset !important;
	}

	.qna-list-info {
		display: flex;
		flex-direction: row;
		gap: 16px;
		row-gap: 12px;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		align-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		${({ $isTablet }) => $isTablet &&
    css `
				flex-direction: column;
				justify-content: flex-start;

				.toggle-switch-wrap {
					flex: 1;

					.label {
						font-size: 14px;
					}
				}
			`}

		${({ $isMobile, $isRetry }) => $isMobile &&
    css `
				padding: 20px;
				background: var(--color-silver, #f3f4f8);
			`}
	}

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.body-item {
		.pink {
			color: var(--color-pink, #f46790);
		}

		.purple {
			color: var(--pur-d, #8268d9);
		}
	}

	.qna-list-001,
	.qna-list-002 {
		width: 100%;
		overflow: auto;

		.item:nth-last-child(1) {
			border-width: 0px 0px 0px 0px;
		}
	}

	.locked-wrap {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.qna-section {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 20px;
		flex: 1;
	}

	.dropdown-section {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
	}

	.date-field {
		.button {
			p {
				font-size: 14px;
			}
		}
	}

	${props => (props.$isTablet ? tabletStyles : props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	.qna-list-002,
	.qna-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: auto;

		${props => props.$isHome &&
    css `
				.scroll-content {
					display: flex;
				}
			`}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 12px);
				line-height: var(--b4-line-height, 18px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.file {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;
				overflow: hidden;
				p {
					color: var(--color-grey-w, #b6bac0);
				}
			}

			.button {
				p {
					font-size: var(--b3-font-size, 10px);
					color: var(--color-white, #ffffff);
					display: flex;
					justify-content: center;
				}
			}

			.bold {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'NotoSansKr-SemiBold', sans-serif;
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 700;
				position: relative;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}

			.grey {
				color: var(--color-grey, #666666);
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 10px 0px 10px 0px;
			display: flex;
			flex-direction: ${props => (props.$isHome ? 'row' : 'column')};
			gap: ${props => (props.$isHome ? '8px' : '4px')};
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			&:nth-child(1) {
				padding: ${props => (props.$isHome ? '10px 0' : '0 0px 10px 0px')};
			}
		}

		.bold-pur {
			.file {
				p {
					color: var(--pur-d, #8268d9);
					font-weight: 700;
				}
			}
			p {
				color: var(--pur-d, #8268d9);
				font-weight: 700;
			}
		}

		.locked-post {
			background: #fcfcfc;
			border-radius: 0px 0px 12px 12px;
			padding: 40px 98px 40px 98px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.locked-text {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.qna-list-wrap {
		display: flex;
		flex-direction: row;
		gap: 6px;
	}

	.locked-wrap {
		gap: 4px;
		svg {
			min-width: 14px;
		}
	}

	.qna-section {
		width: 100%;
		display: flex;
		flex-direction: column;

		&:not(&.history) {
			padding: ${props => (props.$isHome ? '0' : '20px')};
		}

		&.history {
			.scroll-content {
				padding: 0px;
			}

			.content {
				min-height: unset !important;
			}
		}
	}

	.dropdown-section {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-end;
	}
`;
const tabletStyles = css `
	${props => !props.$isRetry && !props.$isHome && `padding: 20px;`}

	.qna-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 12px 20px 12px 20px;
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 14px);
			line-height: 150%;
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 14px);
				line-height: 28px;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.file {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 4px;
				overflow: hidden;
				p {
					color: var(--color-grey-w, #b6bac0);
				}
			}

			.button {
				p {
					font-size: var(--b3-font-size, 12px);
					color: var(--color-white, #ffffff);
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: ${props => props.$isHome ? '12px 20px' : props.$isHistory ? '20px 12px' : '20px '};
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.bold-pur {
			.file {
				p {
					color: var(--pur-d, #8268d9);
					font-weight: 700;
				}
			}
			p {
				color: var(--pur-d, #8268d9);
				font-weight: 700;
			}
		}

		.locked-post {
			background: #fcfcfc;
			border-radius: 0px 0px 12px 12px;
			padding: 40px 98px 40px 98px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.locked-text {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.qna-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.under-line {
				color: var(--color-pink, #f46790);
				text-decoration: underline;
				cursor: pointer;
			}

			button {
				p {
					font-size: 10px;
					line-height: var(--b4-line-height, 24px);

					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.locked-wrap {
		gap: 10px;
	}

	.search-bar-mobile {
		flex: 1;
	}
`;
const desktopStyles = css `
	overflow: auto;

	.qna-list-001 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 6px 28px 6px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}

			.file {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				overflow: hidden;
				p {
					color: var(--color-grey-w, #b6bac0);
				}
			}

			.button {
				p {
					font-size: var(--b3-font-size, 14px);
					color: var(--color-white, #ffffff);
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: ${({ $isHistory, $isHome }) => $isHistory
    ? '18px 12px 18px 12px'
    : $isHome
        ? '10px 6px 10px 6px'
        : '18px 28px 18px 28px'};
			display: flex;
			flex-direction: row;
			gap: ${props => (props.$isHome ? '8px' : '24px')};
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.bold-pur {
			.file {
				p {
					color: var(--pur-d, #8268d9);
					font-weight: 700;
				}
			}
			p {
				color: var(--pur-d, #8268d9);
				font-weight: 700;
			}
		}

		.locked-post {
			background: #fcfcfc;
			border-radius: 0px 0px 12px 12px;
			padding: 40px 98px 40px 98px;
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}

		.locked-text {
			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b2-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b2-font-size, 18px);
			line-height: var(--b2-line-height, 36px);
			letter-spacing: var(--b2-letter-spacing, -0.02em);
			font-weight: var(--b2-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.qna-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-grey, #666666);
			text-align: center;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.body-item {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			p {
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.under-line {
				color: var(--color-pink, #f46790);
				text-decoration: underline;
				cursor: pointer;
			}

			button {
				p {
					font-size: 10px;
					line-height: var(--b4-line-height, 24px);
					width: 35px;
					height: 25px;
					display: flex;
					justify-content: center;
				}
			}
		}

		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.locked-wrap {
		gap: 10px;
	}
`;
