import { styled } from 'styled-components';
export const DSTL004ViewStyled = styled.div `
	&.dsts004-detail {
		display: flex;
		flex-direction: column;
		gap: 24px;
		align-items: ${props => (props.$isPopup ? `center` : 'flex-start')};
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		width: 100%;
		height: max-content;
		overflow: auto;

		&.no-data {
			height: 100%;
		}
	}

	.btn_groups {
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: center;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.qna-container {
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	${({ $isMobile, $isTablet }) => $isMobile
    ? `
		background: var(--color-white, #ffffff);
		flex-shrink: 0;
		position: relative;
	`
    : $isTablet
        ? `
		& {
			background: var(--color-white, #ffffff);
			overflow: hidden;
		}
	`
        : ``}

	&:not(&.history) {
		${({ $isMobile, $isTablet }) => ($isMobile || $isTablet) && `padding: 20px;`}

	&.qna-detail-list {
		.history {
			${({ $isMobile, $isTablet }) => ($isMobile ? `padding: 20px 0;` : `padding-bottom: 20px;`)}
		}
	}`;
