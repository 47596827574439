import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OpenTable } from '../../base/layout/OpenTable';
import { updateMenu } from '../../menu/action';
import { RegistButton } from '../RegistButton';
export const DSTSL001_List = React.memo(({ defaultOpen, isResource, isTeacher, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handlerButtonClick = () => {
        dispatch(updateMenu('learningSupportRoom', {
            menu: isResource ? 'resource' : 'notice',
            detail: 'regist',
        }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OpenTable, { isResource: isResource, isTeacher: isTeacher, defaultOpen: defaultOpen }),
        isTeacher && React.createElement(RegistButton, { handlerButtonClick: handlerButtonClick })));
});
