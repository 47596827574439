import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Colors } from '../../../../Colors';
import { EmptyTable } from '../../../../common/option';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { useStatistics } from '../../../../server';
import { ErrorRateGraph, ErrorRateTable, } from '../../../DST/DSTL001/component/DSTL001_Detail_Child';
export const ErrorContainerTeacherStyled = styled.div `
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column !important;

	.header {
		display: flex;
		flex-direction: row;
		gap: 0px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}
	.title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 20px);
		line-height: var(--h4-line-height, 32px);
		letter-spacing: var(--h4-letter-spacing, -0.02em);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
`;
export const ErrorContainerTeacher = React.memo(({ isClosed, isTest, item, isSubmit, test_id, setIsErrorGraph, }) => {
    const { t } = useTranslation();
    const [statisticItem, setStatisticItem] = useState(null);
    const { data: statisticData, refetch: statisticRefetch } = useStatistics({ test_id });
    const { isMobile, isDesktop } = useGlobalState();
    useEffect(() => {
        statisticRefetch();
    }, [statisticRefetch, test_id]);
    useEffect(() => {
        if (statisticData) {
            setStatisticItem(statisticData);
        }
    }, [statisticData]);
    useEffect(() => {
        const hasHardestRank = !!statisticItem?.hardest_rank?.length;
        setIsErrorGraph(isClosed && hasHardestRank);
    }, [isClosed, statisticItem, setIsErrorGraph]);
    const renderErrorRate = () => {
        if (!item)
            return null;
        if (item.status === 'created') {
            const title = t('assignment.notOpen', {
                name: t(`assignment.${isTest ? 'test' : 'name'}`),
            });
            return (React.createElement(EmptyTable, { title: title, iconProps: {
                    icon: 'detail-delete',
                    size: isMobile ? 28 : 32,
                    fill: Colors.w_grey,
                } }));
        }
        if (!statisticItem?.hardest_rank || statisticItem.hardest_rank.length <= 0) {
            const title = item.status === 'open'
                ? t('assignment.assignmentCheck', {
                    name: t(`assignment.${isTest ? 'test' : 'name'}`),
                })
                : t('assignment.noSubmitInfo');
            return (React.createElement(EmptyTable, { title: title, iconProps: {
                    icon: 'detail-delete',
                    size: isMobile ? 28 : 32,
                    fill: Colors.w_grey,
                } }));
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(ErrorRateTable, { hardest: statisticItem.hardest_rank }),
            React.createElement(ErrorRateGraph, { isVertical: false, hardest: statisticItem.hardest_rank, width: isMobile ? 243 : 340, height: 280 })));
    };
    return (React.createElement(ErrorContainerTeacherStyled, { className: "error-rate-box" },
        isDesktop && (React.createElement("div", { className: "header" },
            React.createElement("div", { className: "title" }, t('assignment.errorRate')))),
        renderErrorRate()));
});
