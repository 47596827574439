import styled, { css } from 'styled-components';
export const Container002Styled = styled.div `
	${props => (props.$isDesktop ? desktopStyles : props.$isTablet ? tabletStyles : mobileStyles)}

	${props => props.$isHome &&
    props.$isMobile &&
    `
			.mobile_item {
				flex-direction: row !important;
			    justify-content: space-between !important;
			}
			.body-item {
				overflow: hidden;
    			flex: unset !important;

				
			}
		`}

	${props => props.$isHome &&
    `
					.body-item {
						&.creation_time, &.submitDate {
							width: 100px!important;
							flex: unset !important;
	
							p {
								width: 100%;
								text-align: right;
							}
						}
					}
				`}

	.body-item {
		p {
			${props => props.$isDesktop
    ? `
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);		
			`
    : props.$isTablet
        ? `
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);`
        : `
				font-size: var(--b4-font-size, 12px);
				line-height: var(--b4-line-height, 18px);
				letter-spacing: var(--b4-letter-spacing, -0.02em);
				font-weight: var(--b4-font-weight, 400);`}
		}
	}

	display: flex;
	width: 100%;
	flex-direction: column;
	flex: 1;

	.contents {
		display: flex;
		flex-direction: row;
		gap: 8px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: hidden;
	}

	.task-list-001 {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
	}

	.home-list-003 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			display: flex;
			position: relative;
			flex-shrink: 0;

			&:nth-last-child(1) {
				border-width: 0px 0px 0px 0px;
			}
		}

		.selected {
			background: #f1f9fa;
			border-width: 0px !important;
		}

		.home-hr {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			width: 100%;
		}
	}

	.underline_blue {
		color: var(--color-tur-p, #2d39aa);
		text-decoration: underline;
		font-family: 'NotoSansKr', sans-serif;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;

		&.bold {
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-weight: 700 !important;
		}
	}

	.empty_data {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}
`;
const mobileStyles = css `
	margin-top: ${props => (props.$isHome ? '10px' : 'unset')};

	&.home {
		height: 100%;
	}

	.btn-container {
		justify-content: center;
		button {
			flex: 1;
		}
	}

	.popup-list-006 {
		${props => props.$isReserve &&
    css `
				flex: 1;
				.table-header {
					padding-bottom: 14px;
				}
				.scroll-content {
					max-height: 600px;
					overflow: auto;
				}
			`}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: center;
			align-self: stretch;
			flex: 1;
			position: relative;
			box-sizing: border-box;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;

			${props => props.$isReserve &&
    css `
					&:nth-child(1) {
						border-width: 0px;
					}

					&:nth-last-child(1) {
						border-width: 0px;
					}
				`}

			.date-wrap-mobile {
				display: flex;
				flex-direction: row;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: 'NotoSansKr-Regular', sans-serif;
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				align-items: center;
				justify-content: flex-start;
				gap: 4px;
			}

			.status-wrap-mobile {
				display: flex;
				flex-direction: row;
				gap: 4px;

				.status-text {
					color: var(--color-grey-l, #83868a);
				}
			}

			.title-text-mobile {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'NotoSansKr-Regular', sans-serif;

				${({ $isHome }) => !$isHome &&
    `
					font-family: 'NotoSansKr-SemiBold', sans-serif;
					font-weight: 600;`}
				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				position: relative;
				flex: 1;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				-webkit-line-clamp: 1;
				overflow-wrap: break-word;
			}
		}
	}

	.home-list-003 {
		align-self: stretch;

		.item {
			justify-content: center;
			align-items: ${props => (props.$isHome ? 'center' : 'flex-start')};
			align-self: stretch;
			flex-direction: column;
			padding: 10px 6px 10px 6px;
			gap: 24px;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
		}

		.title-text-mobile {
			color: var(--color-black, #101010);
			text-align: left;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-family: 'NotoSansKr-Regular', sans-serif;

			${({ $isHome }) => !$isHome &&
    `
			font-family: 'NotoSansKr-SemiBold', sans-serif;
			font-weight: 600;`} position: relative;
			align-self: stretch;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.task-list-001 {
		padding: 12px 20px;
		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: center;
			align-self: stretch;
			flex: 1;
			position: relative;
			box-sizing: border-box;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 4px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 12px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;

			.date-wrap-mobile {
				display: flex;
				flex-direction: row;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: 'NotoSansKr-Regular', sans-serif;
				font-size: 12px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 400;
				position: relative;
				align-items: center;
				justify-content: flex-start;
				gap: 4px;
			}

			.status-wrap-mobile {
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: center;

				.button {
					width: 54px;
					height: 24px;
				}

				.status-text {
					color: var(--color-grey-l, #83868a);
				}
			}

			.title-text-mobile {
				color: var(--color-black, #101010);
				text-align: left;

				font-size: 14px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-family: 'NotoSansKr-Regular', sans-serif;

				${({ $isHome }) => !$isHome &&
    `
					font-family: 'NotoSansKr-SemiBold', sans-serif;
					font-weight: 600;`}
				position: relative;
				flex: 1;
				/* display: flex;
				align-items: center;
				justify-content: flex-start; */
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				-webkit-line-clamp: 1;
				overflow-wrap: break-word;
			}
		}
	}

	${props => props.$isTeacher
    ? css `
					.task-list-001 {
						gap: 24px;

						.table-header {
							width: 100%;
							display: flex;
							flex-direction: row;
							gap: 20px;
							align-items: center;
							justify-content: flex-start;
							flex-shrink: 0;
							position: relative;
						}
					}

					.item {
						border-width: 1px 0px 0px 0px;
					}
			  `
    : css `
					.task-list-001 {
						border-style: solid;
						border-color: var(--color-silver, #f3f4f8);
						border-width: 1px 0px 0px 0px;
						gap: 0px;
					}

					.item {
						border-width: 0px 0px 1px 0px;
					}
			  `}

	.underline_blue {
		font-size: 14px;
		line-height: 32px;
	}
`;
const tabletStyles = css `
	height: 100%;

	${props => !(props.$isHome || props.$isReserve) &&
    css `
			padding: 20px;
		`}

	${props => props.$isReserve && props.$isTest
    ? css `
					.popup-list-006 {
						flex: 1;
						height: unset;
						.scroll-content {
							max-height: 600px;
							overflow: auto;
						}
					}
					.btn-container {
						padding-bottom: 24px;
					}
			  `
    : css `
					.popup-list-006 {
						overflow: hidden;
						/* flex: 1; */
					}
			  `}

	.popup-list-006 {
		border-style: solid;
		border-color: var(--color-grey-c, #e6e8ed);
		border-width: 0px 0px 1px 0px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.home-list-003 {
		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			padding: 10px 6px 10px 6px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 14px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			flex: 1;
		}

		.item {
			justify-content: flex-start;
			align-items: center;
			padding: 12px 20px;
			flex-direction: row;
			gap: 20px;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 14px);
			line-height: 150%;
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);
		}

		.selected {
			border-radius: 40px;
		}
	}

	.task-list-001 {
		gap: 0px;
		/* overflow: auto; */
		height: unset;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			align-self: stretch;
			padding: 12px 20px 12px 20px;
			gap: 24px;
			align-items: center;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: 14px;
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			justify-content: flex-start;
			flex: 1;
			position: relative;
			/* overflow: auto; */
			box-sizing: border-box;
			align-self: stretch;
			height: unset;
		}

		.item {
			box-sizing: border-box;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: ${props => props.$isTeacher || (!props.$isTeacher && props.$isTest)
    ? '12px 20px 12px 20px'
    : '24px 20px 24px 20px'};
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 14px);
			line-height: 150%;
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);

			.typeText {
				color: var(--color-black, #101010);
				text-align: left;
				font-family: 'NotoSansKr-Regular', sans-serif;
				font-size: var(--b3-font-size, 12px);
				line-height: 150%;
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
			}
		}
	}

	.popup-list-006 {
		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.underline_blue {
		font-size: 14px;
		line-height: 150%;
		display: flex;
	}
`;
const desktopStyles = css `
	height: 100%;

	${props => props.$isReserve && props.$isTest
    ? css `
					.popup-list-006 {
						flex: 1;
						height: unset;
						.scroll-content {
							max-height: 600px;
							overflow: auto;
						}
					}
					.btn-container {
						padding-bottom: 24px;
					}
			  `
    : css `
					.popup-list-006 {
						border-style: solid;
						border-color: var(--color-grey-c, #e6e8ed);
						border-width: 0px 0px 1px 0px;
						overflow: hidden;
						flex: 1;
					}
			  `}

	.popup-list-006 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 16px 12px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
		}
	}

	.home-list-003 {
		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			padding: 10px 6px 10px 6px;
			gap: 24px;
			align-items: flex-start;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				letter-spacing: var(--b3-letter-spacing, -0.02em);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			flex: 1;
		}

		.item {
			justify-content: flex-start;
			align-items: center;
			padding: 10px 6px 10px 6px;
			flex-direction: row;
			gap: 24px;

			color: var(--color-black, #101010);
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			letter-spacing: var(--b3-letter-spacing, -0.02em);
			font-weight: var(--b3-font-weight, 400);
		}

		.selected {
			border-radius: 40px;
		}
	}

	.task-list-001 {
		gap: 0px;
		overflow: auto;
		height: unset;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-radius: 40px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			align-self: stretch;
			padding: 6px 28px 6px 28px;
			gap: 24px;
			align-items: center;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b3-font-size, 16px);
				line-height: var(--b3-line-height, 32px);
				font-weight: var(--b3-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.scroll-content {
			display: flex;
			flex-direction: column;
			gap: 0px;
			align-items: flex-start;
			align-self: stretch;
			justify-content: flex-start;
			flex: 1;
			position: relative;
			box-sizing: border-box;
			height: unset;
		}

		.item {
			box-sizing: border-box;
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 20px 28px 20px 28px;
			display: flex;
			flex-direction: row;
			gap: 24px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			width: 100%;
		}
	}

	.underline_blue {
		font-size: 16px;
		line-height: 32px;

		&.bold {
			font-size: 17px;
		}
	}
`;
