import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Colors } from '../../Colors';
import { useGlobalState } from '../../GlobalStateProvider';
import { Button, FileInput } from '../element';
import ImageTag from './ImageTag';
const AttachFileRegistStyled = styled.div `
	width: 100%;

	.field-global-001 {
		${props => props.$forceTitle &&
    `padding: 20px 0;
    border-style: solid;
    border-color: var(--color-grey-c, #e6e8ed);
    border-width: 0.5px 0px 0px 0px;`}
	}

	.file-container {
		width: 100%;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		.file-wrap {
			background: var(--color-white, #ffffff);
			border-radius: 4px;
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: ${props => (props.$isOnlyFile ? '0px' : '1px')};
			display: flex;
			flex-direction: ${props => (props.$isOnlyFile ? 'column' : 'row')};
			gap: ${props => (props.$isOnlyFile ? '20px' : '10px')};
			align-items: center;
			justify-content: flex-start;
			flex: 1;
			position: relative;
			overflow: auto;
			width: 100%;
		}

		.file-item-wrap {
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: ${props => (props.$isMobile ? 'flex-start' : 'center')};
			justify-content: flex-start;
			flex: 1;
			position: relative;
			overflow: auto;
			width: 100%;
			flex-wrap: wrap;
			padding: ${props => props.$isOnlyFile ? '0px' : props.$isMobile ? '6px 10px' : '10px'};

			${props => props.$isOnlyFile &&
    !props.$forceTitle &&
    `border-style: solid;
			border-color: var(--color-grey-c, #e6e8ed);
			border-width: 0.5px 0px 0px 0px;
			padding-top: 20px;`}

			${props => props.$isMobile
    ? css `
							button {
								padding: 2px 4px;
								svg {
									min-width: 12px;
									min-height: 12px;
								}
								p {
									font-size: 10px;
									min-width: unset;
								}
							}
					  `
    : props.$isTablet
        ? css `
							button {
								svg {
									min-width: 14px;
									min-height: 14px;
								}
								p {
									font-size: 12px;
									min-width: unset;
								}
							}
					  `
        : css `
							button {
								svg {
									min-width: 14px;
									min-height: 14px;
								}
								p {
									font-size: 12px;
									min-width: unset;
								}
							}
					  `}
		}
	}

	.img_wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		flex-wrap: wrap;
		width: 100%;

		.img {
			border-radius: 4px;
			flex-shrink: 0;
			width: 100px;
			height: 100px;
			position: relative;
			object-fit: cover;
			background: #000;
		}
	}

	svg {
		min-width: 16px;
		min-height: 16px;
	}

	.empty_file {
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		border-radius: 4px;
		background: var(--color-white, #ffffff);
		padding: 10px;

		color: var(--color-grey-l, #83868a);
		text-align: left;
		font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b5-font-size, 12px);
		line-height: ${props => (props.$isMobile ? '150%' : 'var(--b5-line-height, 22px)')};
		letter-spacing: var(--b5-letter-spacing, -0.02em);
		font-weight: var(--b5-font-weight, 400);
		position: relative;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		width: 100%;
	}
`;
export const FileAttach = React.memo(({ id, className, forceTitle = false, isOnlyFile = false, isRegist, fileList, isQnA = false, isNotice = false, isContent = false, setAttachFileList, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useGlobalState();
    const fileRef = useRef(null);
    //이미지, pdf, 엑셀, 워드, PPT, 텍스트 파일
    const acceptedFileTypes = 'image/*,application/pdf,.xlsx, .xls, .csv, .ppt, .pptx, .pdf, .doc, .docx, .txt, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain';
    const onChangeFile = (e) => {
        const files = e.target.files;
        if (files) {
            const newFileList = Array.from(files);
            setAttachFileList &&
                // @ts-ignore
                setAttachFileList(prev => prev ? [...prev, ...newFileList] : [...newFileList]);
        }
        e.target.value = '';
    };
    const handleFileClick = (item) => {
        if (isRegist && setAttachFileList) {
            // @ts-ignore
            setAttachFileList(fileList.filter(file => file !== item));
        }
        if (!isRegist) {
            const a = document.createElement('a');
            a.href = item;
            a.download = item.split('/').pop() || 'download'; // 다운로드 파일명 설정
            document.body.appendChild(a); // a 요소를 문서에 추가
            a.click();
            document.body.removeChild(a); // a 요소를 문서에서 제거
        }
    };
    //드래그 앤 드롭 파일 막기
    const handleDrop = e => {
        e.preventDefault();
        const dt = e.dataTransfer;
        // 드롭된 항목이 폴더인지 파일인지 확인
        for (let i = 0; i < dt.items.length; i++) {
            const item = dt.items[i];
            if (item.kind === 'file') {
                const file = item.getAsFile();
                // 파일 확장자 확인 (예시: mp4, mov 확장자만 허용)
                if (file.type.startsWith('video/') &&
                    (file.type.includes('mp4') || file.type.includes('quicktime'))) {
                    // 파일 처리 로직 추가
                }
                else {
                    alert('폴더를 등록할 수 없습니다.');
                    return;
                }
            }
            else {
                alert('폴더를 등록할 수 없습니다.');
                return;
            }
        }
    };
    const handleDragOver = e => {
        e.preventDefault();
    };
    const renderFileButton = useMemo(() => (React.createElement(FileInput, { key: id, className: id, ref: fileRef, id: id, onChange: onChangeFile, accept: acceptedFileTypes },
        React.createElement(Button, { className: className
                ? className
                : `btn_tur ${isMobile || (isQnA && isTablet) ? 'mobile-sm' : 'xmd'}`, text: t('common.regist'), styles: {
                buttonWrap: {
                    padding: isMobile && isQnA ? '10px' : '10px 12px 10px 12px',
                    minHeight: isQnA && isTablet ? '48px' : 'unset',
                    // minWidth: isMobile && isQnA ? '55px' : 'unset',
                    width: isMobile && isQnA ? '100%' : 'unset',
                },
            } }))), [id, fileRef]);
    const renderImgList = (files) => files.map((file, index) => (React.createElement(ImageTag, { key: index, file: typeof file === 'string' ? file : URL.createObjectURL(file) })));
    const renderFileList = (files) => files.map((file, index) => (React.createElement("div", { key: index, className: "file-item" },
        React.createElement(Button, { className: "btn_sliver md_round center body5 cursor attach_item", text: typeof file === 'string' ? file.split('/').pop() : file.name, styles: {
                buttonWrap: {
                    height: 'fit-content',
                    maxWidth: isMobile ? 180 : 220,
                },
            }, option: {
                buttonBeforeIcon: {
                    name: 'file',
                    color: Colors.dark_tur,
                    size: 12,
                    show: true,
                },
                buttonAfterIcon: {
                    name: isRegist ? 'close' : 'file-down',
                    color: Colors.accent,
                    size: 12,
                    show: true,
                },
            }, onClick: () => handleFileClick(file) }))));
    const renderContent = (title, isPicture = false) => (React.createElement("div", { className: "content-regist-width", style: {
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '10px' : '10px',
            alignItems: isMobile ? 'flex-start' : 'center',
        } },
        !isOnlyFile || forceTitle ? (isMobile && isQnA ? (renderFileButton) : (React.createElement("div", { className: isContent ? 'content-info-filename' : 'task-label', style: {
                minWidth: 70,
            } }, t(title)))) : (React.createElement(React.Fragment, null)),
        React.createElement("div", { className: "fext-field file", style: { overflow: 'hidden', width: '100%' } },
            React.createElement("div", { onDrop: handleDrop, onDragOver: handleDragOver, className: "file-container" },
                fileList && fileList.length > 0 ? (React.createElement("div", { className: isContent ? 'no-file-wrap' : 'file-wrap' },
                    forceTitle && !isPicture
                        ? null
                        : isOnlyFile && (React.createElement("div", { className: "img_wrap" }, renderImgList(fileList))),
                    forceTitle && isPicture ? null : (React.createElement("div", { className: "file-item-wrap" }, renderFileList(fileList))))) : isPicture ? (React.createElement("p", { className: "empty_file" }, t('assignment.noAttachPicturePlaceholer'))) : (React.createElement("p", { className: isContent ? 'content_empty_file' : 'empty_file' }, t('assignment.noAttachFilePlaceholer'))),
                isRegist && (!isMobile || (isMobile && isNotice)) && renderFileButton))));
    return (React.createElement(AttachFileRegistStyled, { className: className, "$isRegist": isRegist, "$isOnlyFile": isOnlyFile, "$forceTitle": forceTitle, "$isMobile": isMobile, "$isTablet": isTablet },
        forceTitle && renderContent('supportRoom.pictuer', true),
        renderContent('assignment.attachFile')));
});
