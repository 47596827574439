import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Colors } from '../../Colors';
import { useGlobalState } from '../../GlobalStateProvider';
import { DropDown, MenuBar } from '../../common';
import { DSBodyLeftLayout, DSBodyRightLayout } from '../base/layout';
import MenuBody from './MenuBody';
import { updateMenu } from './action';
import { getMenuData } from './function';
export const MenuBox = React.memo(({ isTeacher, name, isWhite, menuList, hideBody = false, hideMenu = false, }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const dispatch = useDispatch();
    const { depths, menu } = useSelector((state) => getMenuData(state), shallowEqual);
    const updateNewMenu = (menu, id) => {
        dispatch(updateMenu(depths, menu, id));
    };
    useEffect(() => {
        const handlerUpdateMenu = (data) => {
            const { menuDetail, id, props } = data;
            dispatch(updateMenu(depths, { menu: menu.menu, detail: menuDetail || 'list' }, id, props));
        };
        APP.eventManager.subscribe('UPDATE_DETAIL_MENU', handlerUpdateMenu);
        return () => {
            APP.eventManager.unsubscribe('UPDATE_DETAIL_MENU', handlerUpdateMenu);
        };
    }, [depths, menu]);
    const questionTypeList = useMemo(() => {
        return menuList.map(menu => {
            return {
                id: menu,
                title: `menu.${menu}`,
            };
        });
    }, [menuList]);
    const questionType = useMemo(() => {
        return questionTypeList.find(item => item.id === menu?.menu);
    }, [menu, questionTypeList]);
    const { isDesktop } = useGlobalState();
    return (React.createElement(React.Fragment, null,
        isDesktop && !hideMenu ? (React.createElement(DSBodyLeftLayout, null,
            React.createElement(MenuBar, { className: `menu-l ${name}`, isRow: false, isClickDisabled: false, list: menuList, value: menu?.menu, onItemClick: (changeMenu) => {
                    updateNewMenu({
                        menu: changeMenu === 'learningSupportRoom'
                            ? 'notice'
                            : changeMenu === 'learningManagement' ||
                                changeMenu === 'myRoom'
                                ? 'test'
                                : changeMenu === 'liveRoom'
                                    ? 'reservation'
                                    : changeMenu,
                        detail: 'list',
                    });
                }, option: {
                    titleIcon: {
                        show: true,
                        size: 34,
                        color: Colors.w_grey,
                        focus: Colors.light_tur,
                    },
                } }))) : (hideBody && (React.createElement(DropDown, { className: `dropdown-mobile ${name}`, value: questionType, styles: {
                titleWrap: {
                    display: 'flex',
                    paddingTop: 3,
                },
            }, option: {
                titleIcon: {
                    show: true,
                    name: menuOpen ? 'menu_close' : 'menu_open',
                    size: 18,
                    color: isWhite
                        ? Colors.white
                        : menuOpen
                            ? Colors.light_grey
                            : Colors.black,
                },
                downIcon: {
                    show: false,
                },
                itemIcon: {
                    show: true,
                    size: 35,
                },
            }, list: questionTypeList, setDropValue: (value) => {
                const changeMenu = value.id;
                updateNewMenu({
                    menu: changeMenu === 'learningSupportRoom'
                        ? 'notice'
                        : changeMenu === 'learningManagement' ||
                            changeMenu === 'myRoom'
                            ? 'test'
                            : changeMenu === 'liveRoom'
                                ? 'reservation'
                                : changeMenu,
                    detail: 'list',
                });
            }, isHeaderTitle: true, setMenuOpen: setMenuOpen }))),
        !hideBody &&
            (isDesktop ? (React.createElement(DSBodyRightLayout, null,
                React.createElement(MenuBody, { isTeacher: isTeacher }))) : (React.createElement(MenuBody, { isTeacher: isTeacher })))));
});
