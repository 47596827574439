import React from 'react';
export const DoughnutChart = React.memo(({ percentage, size = 200, fill = '#458C94', isHome, fontSize = null }) => {
    const radius = size / 2 - 20; // Adjust radius to fit within the SVG size
    const circumference = Math.PI * 2 * radius;
    const dashArray = typeof percentage === 'string' ? `${(0 / 100) * circumference} ${circumference}` : `${(percentage / 100) * circumference} ${circumference}`;
    return (React.createElement("svg", { width: size, height: size },
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "linear", x1: "0%", y1: "0%", x2: "100%", y2: "0%" },
                React.createElement("stop", { offset: "0%", stopColor: fill }),
                React.createElement("stop", { offset: "100%", stopColor: fill }))),
        React.createElement("circle", { cx: size / 2, cy: size / 2, r: radius, stroke: "#E6F0F1", strokeWidth: size / 10, fill: "none" }),
        React.createElement("path", { d: `M ${size / 2},${size / 2 - radius} A ${radius} ${radius} 0 1 1 ${size / 2},${size / 2 + radius} A ${radius} ${radius} 0 1 1 ${size / 2},${size / 2 - radius}`, stroke: fill, strokeLinecap: "round", strokeWidth: size / 10, fill: "none", strokeDasharray: dashArray }),
        React.createElement("text", { x: size / 2, y: isHome ? size / 2 : size / 2 + 10, textAnchor: "middle", fontSize: fontSize || size / 5, fontWeight: "bold", fill: fill }, typeof percentage === 'string' ? percentage : isNaN(percentage) ? 0 : `${percentage}%`),
        isHome && (React.createElement("text", { x: size / 2, y: size / 2 + 20, textAnchor: "middle", fill: fill, fontSize: size / 12 }, "\uC644\uB8CC"))));
});
