import React from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const TestSettingStyled = styled.div `
	&.header {
		display: flex;
		flex-direction: ${props => (props.$isMobile ? 'column' : 'row')};
		align-items: flex-end;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		gap: ${props => (props.$isMobile ? '12px' : '0')};

		.test-setting {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--h5-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: var(--h5-font-size, 16px);
			line-height: var(--h5-line-height, 24px);
			font-weight: var(--h5-font-weight, 700);
			position: relative;
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
`;
export const TestSetting = React.memo(({ tableName, children }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    return (React.createElement(TestSettingStyled, { className: "header test-setting", "$isMobile": isMobile, "$isDesktop": isDesktop, "$isTablet": isTablet },
        !isMobile && React.createElement("div", { className: "test-setting" }, tableName),
        React.createElement("div", { className: "btn-container", style: { padding: 0, margin: 0 } }, children)));
});
