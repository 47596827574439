import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button, InputElement } from '../../../../../common';
import { EvaluationCountTable } from './EvaluationCountTable';
// 평가 항목 수
export const EvaluationCount = React.memo(({ isReserve, evalItemCnt, evalData, subjectId, setEvalItemCnt, setEvalData, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet } = useGlobalState();
    const evalRef = React.useRef(null);
    // (onClick) 평가 항목 수 업데이트
    const applyEvalItemCnt = () => {
        if (evalRef.current) {
            const element = evalRef.current;
            const nubmer = Number(element.value);
            if (nubmer <= 0) {
                alert(t('assignment.errMoreThan'));
                return;
            }
            else if (nubmer > 100) {
                alert(t('assignment.errLessThan'));
                return;
            }
            setEvalItemCnt(nubmer);
        }
    };
    React.useEffect(() => {
        if (evalRef.current)
            evalRef.current.value = evalItemCnt.toString();
    }, [evalItemCnt]);
    const evalCntInfo = useMemo(() => (React.createElement("div", { style: {
            display: 'flex',
            flexDirection: 'row',
            gap: 16,
            width: isMobile ? '100%' : undefined,
        } },
        React.createElement(InputElement, { ref: evalRef, type: "number", isEnterPrevent: true, onSubmit: applyEvalItemCnt, min: 0 }),
        React.createElement(Button, { className: `btn_tur ${isMobile ? 'mobile-sm' : 'sm'}`, text: "common.apply", onClick: applyEvalItemCnt, styles: {} }))), [evalRef]);
    const evalTable = useMemo(() => (React.createElement(EvaluationCountTable, { evalItemCnt: evalItemCnt, evalData: evalData, subjectId: subjectId, setEvalData: setEvalData })), [evalItemCnt, subjectId, evalData]);
    const evalCntInfoWrap = useMemo(() => (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "task-label", style: isMobile
                ? {
                    width: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: 'fit-content',
                }
                : { minWidth: 'fit-content' } }, t('assignment.evalItemCnt')),
        React.createElement("div", { className: "fext-field", style: { flexDirection: 'column', alignItems: 'flex-start' } },
            evalCntInfo,
            !isMobile && evalTable))), [evalCntInfo, isMobile, evalTable]);
    return (React.createElement("div", { className: "field-global-001", style: {
            alignItems: 'flex-start',
            flexDirection: 'column',
        } }, isMobile ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "eval-info-wrap" }, evalCntInfoWrap),
        React.createElement("div", { className: "eval-table-wrap" }, evalTable))) : (evalCntInfoWrap)));
});
