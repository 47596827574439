import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Colors } from '../../../../Colors';
import { EmptyTable } from '../../../../common/option';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { useStatistics } from '../../../../server';
import { ErrorRateTable } from '../../../DST/DSTL001/component/DSTL001_Detail_Child';
const ErrorContainerStyled = styled.div `
	border-style: solid;
	border-color: var(--color-grey-w, #b6bac0);
	border-width: ${({ $isDesktop }) => (!$isDesktop ? '0' : ' 0px 0px 1px 0px;')};

	${({ $isMobile }) => $isMobile &&
    `
		width: 100%;
		height: 100%;
	`}

	.title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);

		letter-spacing: var(--h4-letter-spacing, -0.02em);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		${({ $isMobile, $isTablet }) => $isMobile
    ? `
		font-size: var(--h6-font-size, 14px);
		line-height: var(--h6-line-height, 18px);
		`
    : $isTablet
        ? `
			font-size: var(--h5-font-size, 16px);
			line-height: var(--h5-line-height, 24px);`
        : `

			font-size: var(--h4-font-size, 20px);
			line-height: var(--h4-line-height, 32px);
		`}
	}

	${({ $isDesktop }) => $isDesktop &&
    `
	.empty_data {
		flex: unset;
		height: 240px;
	}
	`}
`;
export const ErrorContainer = React.memo(({ item, isTest, isSubmit, test_id, }) => {
    const { t } = useTranslation();
    const [statisticItem, setStatisticItem] = React.useState(null);
    const { data: statisticData, refetch: statisticRefetch } = useStatistics({ test_id });
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    useEffect(() => {
        statisticRefetch();
    }, [test_id]);
    useEffect(() => {
        if (!statisticData)
            return;
        setStatisticItem(statisticData);
    }, [statisticData]);
    const renderContent = React.useMemo(() => {
        if (!item)
            return null;
        if (item.status === 'created' ||
            (item.status === 'open' && !isSubmit) ||
            !statisticItem?.hardest_rank ||
            statisticItem?.hardest_rank.length <= 0) {
            const title = item.status === 'created'
                ? t('assignment.notOpen', {
                    name: t(`assignment.${isTest ? 'test' : 'name'}`),
                })
                : item.status === 'open' && !isSubmit
                    ? t('assignment.notSubmit')
                    : item.status === 'open'
                        ? t('assignment.assignmentCheck', {
                            name: t(`assignment.${isTest ? 'test' : 'name'}`),
                        })
                        : t('assignment.noSubmitInfo');
            return (React.createElement(EmptyTable, { title: title, iconProps: {
                    icon: 'detail-delete',
                    size: isMobile ? 28 : 32,
                    fill: Colors.w_grey,
                } }));
        }
        else {
            return React.createElement(ErrorRateTable, { hardest: statisticItem?.hardest_rank, showMore: true });
        }
    }, [item, isSubmit, statisticItem?.hardest_rank]);
    return (React.createElement(ErrorContainerStyled, { className: "wrong-rate-list", "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        isDesktop && React.createElement("div", { className: "title" }, t('assignment.errorRates')),
        renderContent));
});
