import React, { useEffect, useMemo, useState } from 'react';
//@ts-ignore
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { MenuBarOption } from '../option';
import Icon from './Icon';
import { useGlobalState } from '../../GlobalStateProvider';
const MenuBar = React.memo(({ isRow, isClickDisabled = false, className, option, value, list, styles, onItemClick, setIsHovered, }) => {
    const { t } = useTranslation();
    const { isDesktop } = useGlobalState();
    const [menu, setMenu] = useState(value);
    const menubarOption = useMemo(() => _.merge({}, MenuBarOption, option), [option]);
    useEffect(() => {
        if (!value)
            return;
        if (value !== menu)
            setMenu(value);
    }, [value]);
    // 메뉴 변경 함수
    const changeMenu = (changeMenu) => {
        if (!isClickDisabled) {
            onItemClick(changeMenu);
        }
    };
    // render Items
    const renderItems = (data) => {
        const { item, isFocus, className, changeMenu } = data;
        return (React.createElement("div", { key: item, className: `menubar-item ${className}`, onMouseEnter: setIsHovered ? () => isDesktop && setIsHovered(true) : undefined, style: styles?.menuItem, onClick: e => changeMenu(item) },
            menubarOption.titleIcon.show && (React.createElement(Icon, { icon: `${item}_${isFocus ? 'on' : 'off'}`, size: menubarOption.titleIcon.size, fill: isFocus
                    ? menubarOption.titleIcon.focus
                    : menubarOption.titleIcon.color })),
            React.createElement("p", null, t(`menu.${item}`))));
    };
    return (React.createElement("div", { className: `menubar-wrap flex ${isRow ? 'row' : 'column'} ${className}`, style: styles?.menuWrap }, list.map(item => renderItems({
        item,
        isFocus: menu === item,
        className: menu === item ? 'active' : '',
        changeMenu: !isClickDisabled ? () => changeMenu(item) : undefined,
    }))));
});
export { MenuBar };
