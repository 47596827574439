import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { SearchBar } from '../../../../../common';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { AssignmentStateDropdown } from './AssignmentStateDropdown';
import { AssignmentTypeDropdown } from './AssignmentTypeDropdown';
import { DeleteListBtn } from './DeleteListBtn';
const Container001Styled = styled.div `
	${props => (props.$isDesktop ? desktopStyles : props.$isTablet ? tabletStyles : mobileStyles)}
`;
const mobileStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.active-bar {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: flex-start;
			justify-content: center;
			flex: 1;
			position: relative;
			padding: 20px;
			background: var(--color-silver, #f3f4f8);
		}

		.drop-wrap {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
const tabletStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.active-bar {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-end;
			flex: 1;
			position: relative;
		}
	}
`;
const desktopStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;

		.active-bar {
			display: flex;
			flex-direction: row;
			gap: 14px;
			align-items: center;
			justify-content: flex-end;
			flex-shrink: 0;
			position: relative;
			flex-wrap: wrap;
			flex: 1;
		}
	}
`;
export const AssignmentTitle = React.memo(({ isHideSelectDelBtn = false, isBackOffice = false, isTest = false, isTeacher = false, checkList = [], status, type, keyword, setStatus, setType, setKeyword, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const renderDropDown = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            React.createElement(AssignmentStateDropdown, { status: status, setStatus: setStatus }),
            React.createElement(AssignmentTypeDropdown, { type: type, setType: setType })));
    }, [type, status]);
    const handlerUpdateSearch = (value) => {
        // @ts-ignore
        setKeyword(value?.keyword);
    };
    return (React.createElement(Container001Styled, { className: "header", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        !isHideSelectDelBtn && isTeacher && !isMobile ? (React.createElement(DeleteListBtn, { isTest: isTest, isOneDel: false, deleteList: checkList })) : (React.createElement("div", null)),
        !isBackOffice && (React.createElement("div", { className: "active-bar", style: { gap: !isMobile && 14 } },
            isMobile ? (React.createElement("div", { className: "drop-wrap" }, renderDropDown)) : (renderDropDown),
            React.createElement(SearchBar, { className: `${!isMobile ? 'search-bar-qna' : 'search-bar-mobile'}`, filterData: "keyword", defaultSearchContent: keyword, handlerFilterList: handlerUpdateSearch })))));
});
