import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '../../../../../common';
import { DSTL001DetailTableStyled } from './DSTL001DetailStyled';
import { TOGGLE_POPUP } from '../../../../base/popup';
import i18next from 'i18next';
import { useGlobalState } from '../../../../../GlobalStateProvider';
function formatNumber(value) {
    const formattedValue = value.toFixed(1); // Format the number to two decimal places
    if (formattedValue.endsWith('00')) {
        return parseInt(formattedValue, 10); // Convert to integer if no decimal part
    }
    if (formattedValue.endsWith('0')) {
        return parseFloat(formattedValue.slice(0, -1)); // Remove trailing zero if exists
    }
    return parseFloat(formattedValue); // Return the formatted value with up to two decimals
}
export const ErrorRateTable = React.memo(({ hardest, showMore = false }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const openPopup = (hardest) => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: ErrorRateTable,
            componentProps: { showMore: true, hardest },
            width: 1200,
            height: isTablet && 'unset',
            title: i18next.t('assignment.errorRate'),
            isFit: false,
        });
    };
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'rankings', space: 1, sort: false },
            { title: 'questionNumbers', space: 1.5, sort: false },
            ...(!showMore ? [] : [{ title: 'title', space: 2 }]),
            { title: 'errorRates', space: 2, sort: false },
            {
                title: 'choiceRatios',
                space: 6,
                sort: false,
                children: ['1번', '2번', '3번', '4번', '5번'],
            },
        ];
        const tableReadDataKeys = [
            { title: 'rankings', space: 1 },
            { title: 'number', space: 1.5 },
            ...(!showMore ? [] : [{ title: 'subject_item', space: 2 }]),
            { title: 'incorrect_rate', space: 2 },
            { title: 'distribution', space: 6 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    React.useEffect(() => {
        if (!hardest)
            return;
        if (hardest && hardest.length < 0) {
            setTestData([]);
            setTotalCount(0);
            return;
        }
        ;
        const data = showMore ? hardest : hardest.slice(0, 5);
        const list = data.sort((a, b) => b.incorrect_rate - a.incorrect_rate);
        setTestData(list);
        setTotalCount(list.length);
    }, [hardest, showMore]);
    const handlerMoreData = () => {
        setPage(prevPage => prevPage + 1);
    };
    const renderItem = (item, styles, index) => {
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, className: "flex row body-item", style: {
                flex: headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
                gap: `${headItem.title === 'distribution' ? '20px' : ''}`,
                ...(headItem.title === 'number'
                    ? { flexDirection: 'column', gap: 12 }
                    : {}),
            } }, headItem.title === 'distribution' ? (item[headItem.title].map((choice, index) => {
            return (React.createElement("p", { key: index, className: "overText", style: { ...styles?.bodyText, flex: 1, textAlign: 'center' } },
                formatNumber(choice),
                "%"));
        })) : (React.createElement("p", { className: "overText", style: styles?.bodyText }, headItem.title === 'rankings'
            ? index + 1
            : headItem.title === 'incorrect_rate'
                ? `${formatNumber(item[headItem.title])}%`
                : item[headItem.title])))));
    };
    const renderMobileItem = (item, styles, index) => {
        return tableReadDataKeys.map(headItem => headItem.title === 'rankings' || headItem.title === 'number' ? null : (React.createElement("div", { key: headItem.title, className: "flex row body-item", style: {
                flex: headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
                gap: `${headItem.title === 'distribution' ? '20px' : ''}`,
            } }, headItem.title === 'distribution' ? (React.createElement("div", { className: "ratio" },
            React.createElement("div", { className: "choice-ratio-text" }, t('assignment.choiceRatios')),
            React.createElement("div", { className: "choice-ratio-wrap", style: {
                    display: 'flex',
                    width: '100%',
                } }, item[headItem.title].map((choice, index) => {
                return (React.createElement("div", { key: index, style: {
                        width: '100%',
                    } },
                    React.createElement("div", { className: "choice-ratio-index" },
                        index + 1,
                        "\uBC88"),
                    React.createElement("p", { key: index, className: "overText", style: {
                            ...styles?.bodyText,
                            flex: 1,
                            textAlign: 'center',
                        } }, formatNumber(choice))));
            })))) : headItem.title === 'incorrect_rate' ? (React.createElement("div", { className: "error-info-wrap" },
            React.createElement("div", { className: "error-frame" },
                React.createElement("div", { className: "error-rate-text" }, t('assignment.questionNumbers')),
                React.createElement("div", { className: "overText" },
                    item['number'],
                    t('assignment.number'))),
            React.createElement("div", { className: "error-frame" },
                React.createElement("div", { className: "error-rate-text" }, t('assignment.errorRates')),
                React.createElement("div", { className: "overText" }, `${formatNumber(item[headItem.title])}`)))) : (React.createElement("p", { className: "overText", style: styles?.bodyText }, item[headItem.title])))));
    };
    return (React.createElement(DSTL001DetailTableStyled, { "$isMobile": isMobile, "$isTablet": isTablet, className: `field-global-001 detail_table ${showMore && !isMobile ? 'popup-table' : ''} `, style: { flexDirection: 'column', height: !isMobile && '100%' } },
        !showMore && (React.createElement("div", { className: "task-label", style: { width: '100%', alignItems: 'space-between', minHeight: 'min-content' } },
            React.createElement("p", { className: "error-rate-txt" }, t('assignment.errorRate')),
            React.createElement("p", { className: "detail_txt", onClick: () => openPopup(hardest) }, t('assignment.showAllErrRate')))),
        React.createElement("div", { className: "fext-field" },
            React.createElement(Table, { className: isMobile ? showMore ? 'task-list-mobile task-list-mobile-popup' : 'task-list-mobile' : 'task-list-002', showIndex: false, isInifinite: true, page: page, data: testData, sortDefault: "member_id", totalCount: totalCount, headTitleList: headTitleList, styles: isMobile
                    ? {}
                    : {
                        headWrapper: {
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        headItem: {
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                        bodyItem: {
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    }, renderItem: isMobile ? renderMobileItem : renderItem, handlerMoreData: handlerMoreData, forceShowheader: isMobile ? false : true }))));
});
