import i18next from 'i18next';
import { MiddlewareRegistry } from '../../redux/regist';
import { APP_WILL_LOAD, APP_WILL_UNMOUNT } from '../base/app/actionTypes';
import { DeletePopUpLayout } from '../base/layout/DeletePopUpLayout';
import { TOGGLE_POPUP } from '../base/popup';
import { goToPrevMenu, updateMenu } from './action';
import { GO_TO_PREV_MENU, SET_MENU } from './actionType';
import { getDepths, getMenuData } from './function';
let activeRef = false;
// 전역 변수
let pageHistory = []; // 페이지 기록
let currentMenu = null; // 현재 메뉴
// 메뉴 비교 함수
const areMenusEqual = (menu1, menu2) => {
    if (!menu1 || !menu2)
        return false;
    return (menu1.depths === menu2.depths &&
        menu1.id === menu2.id &&
        JSON.stringify(menu1.menu) === JSON.stringify(menu2.menu));
};
const menuConditions = {
    qna: ['answer_modify', 'answer', 'regist', 're_regist'],
    resource: ['regist', 'modify'],
    notice: ['regist', 'modify'],
};
const isMenuConditionMatched = (menu, detail) => {
    return menuConditions[menu]?.some(condition => detail === condition || detail.includes(condition));
};
// 미들웨어 등록
MiddlewareRegistry.register(store => next => (action) => {
    const { dispatch } = store;
    switch (action.type) {
        case APP_WILL_LOAD: {
            const savedMenus = localStorage.getItem('test_menu');
            if (savedMenus) {
                const prevMenu = JSON.parse(savedMenus);
                if (Array.isArray(prevMenu)) {
                    pageHistory = prevMenu;
                    currentMenu = pageHistory[pageHistory.length - 1] || null; // 마지막 메뉴 복원
                }
                if (currentMenu) {
                    dispatch(updateMenu(currentMenu.depths, currentMenu.menu, currentMenu.id, currentMenu.props));
                }
            }
            APP.eventManager.subscribe('MENU_MOVE_ACTIVE', isActive => {
                activeRef = isActive;
            });
            break;
        }
        case APP_WILL_UNMOUNT: {
            pageHistory = [];
            currentMenu = null;
            break;
        }
        case SET_MENU: {
            const newMenu = {
                depths: action.depths || getDepths(store.getState()),
                menu: action.menu,
                id: action.id,
                props: action.props,
                timestamp: Date.now(), // 타임스탬프 추가
            };
            // 최근 메뉴와 다른 경우에만 추가
            const lastMenu = pageHistory[pageHistory.length - 1];
            const prevMenu = getMenuData(store.getState());
            if (prevMenu && prevMenu.menu && !activeRef) {
                if (isMenuConditionMatched(prevMenu.menu.menu, prevMenu.menu.detail)) {
                    APP.eventManager.publish(TOGGLE_POPUP, {
                        component: DeletePopUpLayout,
                        componentProps: {
                            isQnA: true,
                            deleteContent: i18next.t(`supportRoom.answerPopUpcancel`),
                            previosPage: () => {
                                activeRef = true;
                                dispatch(goToPrevMenu());
                                dispatch(updateMenu(newMenu.depths, newMenu.menu, newMenu.id, newMenu.props));
                                APP.eventManager.publish(TOGGLE_POPUP, null);
                                activeRef = false;
                            },
                        },
                        width: 639,
                        title: i18next.t(`common.menuMove`),
                        isFit: true,
                    });
                    return;
                }
            }
            if (!lastMenu || !areMenusEqual(lastMenu, newMenu)) {
                // 히스토리 길이 제한
                if (pageHistory.length >= 50) {
                    pageHistory.shift(); // 가장 오래된 메뉴 제거
                }
                if (pageHistory.length > 1) {
                    const prevMenu = pageHistory[pageHistory.length - 1];
                    if (prevMenu.depths === newMenu.depths &&
                        prevMenu.menu.menu === newMenu.menu.menu &&
                        prevMenu.menu.detail === newMenu.menu.detail) {
                        currentMenu = newMenu;
                        return next({ ...action, ...newMenu });
                    }
                }
                pageHistory.push(newMenu);
                localStorage.setItem('test_menu', JSON.stringify(pageHistory));
            }
            currentMenu = newMenu;
            return next({ ...action, ...newMenu });
        }
        case GO_TO_PREV_MENU: {
            if (pageHistory.length > 1) {
                pageHistory.pop(); // 현재 메뉴 제거
                const prevMenu = pageHistory[pageHistory.length - 1];
                localStorage.setItem('test_menu', JSON.stringify(pageHistory));
                if (prevMenu) {
                    dispatch(updateMenu(prevMenu.depths, prevMenu.menu, prevMenu.id, prevMenu.props));
                }
            }
            else {
                // 기본 메뉴로 돌아가기
                dispatch(updateMenu('home', { menu: 'home', detail: 'list' }));
                pageHistory = []; // 히스토리 초기화
                localStorage.removeItem('test_menu');
            }
            break;
        }
    }
    return next(action);
});
