import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { useScoreStatus } from '../../../../server';
const SubmitResultStyled = styled.div `
	${({ $isHome, $isMobile, $isTablet }) => $isMobile
    ? `
				.submit-wrap {
					border-radius: 10px;
					display: flex;
					flex-direction: row;
					gap: 12px;
					align-items: flex-start;
					justify-content: flex-start;
					align-self: stretch;
					flex: 1;
					position: relative;
				}

				.submit-item {
					background: #feffff;
					border-radius: 8px;
					border-style: solid;
					border-color: var(--tur, #3ab2bf);
					border-width: 1px;
					padding: 20px;
					display: flex;
					flex-direction: column;
					gap: 32px;
					align-items: self-end;
					justify-content: center;
					flex: 1;
					position: relative;
				}

				.submit-label {
					display: flex;
					flex-direction: column;
					gap: 0px;
					align-items: flex-start;
					justify-content: flex-start;
					align-self: stretch;
					flex-shrink: 0;
					min-width: 102px;
					position: relative;
				}

				.label {
					color: var(--color-grey, #666666);
					text-align: center;
					font-family: var(--b3-font-family, "NotoSansKr-Regular", sans-serif);
					font-size: var(--b3-font-size, 16px);
					line-height: var(--b3-line-height, 32px);
					letter-spacing: var(--b3-letter-spacing, -0.02em);
					font-weight: var(--b3-font-weight, 400);
					position: relative;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				.value {
					color: var(--tur-d, #479da6);
					text-align: center;
					font-family: var(--h2-font-family, "NotoSansKr-Bold", sans-serif);
					font-size: var(--h2-font-size, 32px);
					line-height: var(--h2-line-height, 46px);
					letter-spacing: var(--h2-letter-spacing, -0.02em);
					font-weight: var(--h2-font-weight, 700);
					position: relative;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				  }
			`
    : `

			.divider {
				background: #d9d9d9;
				flex-shrink: 0;
				width: 1px;
				height: 24px;
				position: relative;
			}

			.submit-wrap {
				display: flex;
				flex-direction: row;
				gap: 0px;
				align-items: center;
				justify-content: center;
				position: relative;
				flex: 1;
			}

			.submit-label {
				display: flex;
				flex-direction: column;
				gap: 0px;
				align-items: center;
				justify-content: center;
				align-self: stretch;
				flex-shrink: 0;
				min-width: 102px;
				position: relative;
			}
			.submit-item {
				display: flex;
				flex-direction: column;
				gap: 0px;
				align-items: center;
				justify-content: center;
				flex: 1;
				min-width: 120px;
				position: relative;
			}
			.label {
				color: var(--color-grey, #666666);
				text-align: center;
				font-family: var(--b2-font-family, "NotoSansKr-Regular", sans-serif);
				letter-spacing: var(--b2-letter-spacing, -0.02em);
				font-weight: var(--b2-font-weight, 400);
				position: relative;
				text- overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				${$isTablet
        ? css `
								font-size: var(--b3-font-size, 16px);
								line-height: var(--b3-line-height, 32px);
						  `
        : css `
								font-size: var(--b2-font-size, 18px);
								line-height: var(--b2-line-height, 36px);
						  `}
			}

			.value {
				color: var(--tur-d, #479da6);
				text- align: center;
				font-family: var(--h2-font-family, "NotoSansKr-Bold", sans-serif);
				letter-spacing: var(--h2-letter-spacing, -0.02em);
				font-weight: var(--h2-font-weight, 700);
				position: relative;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;

				${$isTablet
        ? css `
								font-size: var(--h4-font-size, 20px);
								line-height: var(--h4-line-height, 32px);
						  `
        : css `
								font-size: var(--h2-font-size, 32px);
								line-height: var(--h2-line-height, 46px);
						  `}

			}
	`}
`;
export const SubmitResult = React.memo(({ isSubmit, selectName, test_id, member_id, isHome = false, }) => {
    const { isMobile, isTablet } = useGlobalState();
    const [allGrades, setAllGrades] = useState({
        score: 0,
        correctIndex: 0,
        allIndex: 0,
        allScore: 0,
    });
    const { data: scoreData, refetch: scoreRefetch } = useScoreStatus({
        id: test_id,
        member_id,
        valid_correction: true,
    });
    useEffect(() => {
        if (!scoreData)
            return;
        // let result: Map<string, Grade> = new Map();
        let allResult = { score: 0, correctIndex: 0, allIndex: 0, allScore: 0 };
        scoreData.forEach((item) => {
            const score = item.correction === 'O' ? item.point : 0;
            const correctIndex = item.correction === 'O' ? 1 : 0;
            const allScore = item.point;
            const allIndex = 1;
            // if (result.has(item.subject)) {
            // 	const existing = result.get(item.subject)!;
            // 	result.set(item.subject, {
            // 		score: score + existing.score,
            // 		correctIndex: correctIndex + existing.correctIndex,
            // 		allScore: allScore + existing.allScore,
            // 		allIndex: existing.allIndex + allIndex,
            // 	});
            // } else {
            // 	result.set(item.subject, { score, correctIndex, allScore, allIndex });
            // }
            allResult = {
                score: allResult.score + score,
                correctIndex: allResult.correctIndex + correctIndex,
                allScore: allResult.allScore + allScore,
                allIndex: allResult.allIndex + allIndex,
            };
        });
        setAllGrades(allResult);
    }, [scoreData]);
    useEffect(() => {
        scoreRefetch();
    }, [test_id, member_id, isSubmit]);
    return (React.createElement(SubmitResultStyled, { className: "status-panel", "$isHome": isHome, "$isMobile": isMobile, "$isTablet": isTablet },
        React.createElement("div", { className: "submit-wrap" },
            React.createElement("div", { className: "submit-item" },
                React.createElement("div", { className: "submit-label" },
                    React.createElement("div", { className: "label" }, "\uC815\uB2F5 \uC218"),
                    React.createElement("div", { className: "value" }, isSubmit ? allGrades.correctIndex : '-')),
                isMobile && React.createElement(Icon, { icon: "answerCount", size: 48 })),
            !isMobile && React.createElement("div", { className: "divider" }),
            React.createElement("div", { className: "submit-item" },
                React.createElement("div", { className: "submit-label" },
                    React.createElement("div", { className: "label" }, "\uC6D0\uC810\uC218 (\uCD1D\uC810)"),
                    React.createElement("div", { className: "value" }, isSubmit ? allGrades.allScore : '-')),
                isMobile && React.createElement(Icon, { icon: "totalCount", size: 48 })))));
});
