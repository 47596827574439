import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../Colors';
import { Button } from '../../../../common';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { QnAAnswerStyled } from './styled';
export const QnAAnswer = React.memo(({ item }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    return (React.createElement(QnAAnswerStyled, null,
        React.createElement("div", { className: "question-wrapper", style: { height: '100%' } },
            React.createElement("div", { className: "field-global-001", style: { height: '100%', alignItems: 'start' } },
                React.createElement("div", { className: "task-label" }, t('supportRoom.content')),
                React.createElement("div", { className: "question-content" },
                    React.createElement("div", null,
                        item ? item[0].content : '',
                        " "),
                    React.createElement("div", { className: "img_wrap" }, item &&
                        item[0].files.map((file, index) => (React.createElement("div", { key: index, className: "img" })))))),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "task-label" }, t('supportRoom.attachFile')),
                React.createElement("div", { className: "attach-wrapper" }, item &&
                    item[0].files.map((attach, index) => (React.createElement("div", { className: "attach-files", key: index },
                        React.createElement(Button, { key: index, className: "btn_sliver md_round center body5 cursor attach_item", text: attach, styles: {
                                buttonWrap: {
                                    height: 'fit-content',
                                    maxWidth: isMobile ? 180 : 220,
                                },
                            }, option: {
                                buttonBeforeIcon: {
                                    name: 'file',
                                    color: Colors.dark_tur,
                                    size: 12,
                                    show: true,
                                },
                                buttonAfterIcon: {
                                    name: 'file-down',
                                    color: Colors.accent,
                                    size: 12,
                                    show: true,
                                },
                            } })))))))));
});
