import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DropDown, InputElement, } from '../../../../common';
import { DEFAULT_SURVEY_DROP, SURVEY_DROP_LIST, SURVEY_TYPE } from './constant';
import { Colors } from '../../../../Colors';
import _ from 'lodash';
import { useGlobalState } from '../../../../GlobalStateProvider';
import PieChart from '../../../../common/element/PieChart';
import Paging from '../../../../common/element/Paging';
// SurveyResultItem 컴포넌트
const SurveyResultItem = React.memo(({ index, item, isSubmit, isRegist, frequencies }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const [data, setData] = React.useState();
    const [tableList, setTableList] = useState([]);
    const titleRef = React.useRef(null);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(5);
    // 드롭다운 값 상태
    const [dropValue, setDropValue] = React.useState(DEFAULT_SURVEY_DROP);
    useEffect(() => {
        if (_.isEqual(data, item))
            return;
        const newDropValue = SURVEY_DROP_LIST.find(drop => drop.id === item.type) || DEFAULT_SURVEY_DROP;
        setDropValue(newDropValue);
        if (titleRef.current)
            titleRef.current.value = item.title || '';
        setData(item);
        setTableList(frequencies[item.id]?.slice(page * count, (page + 1) * count));
    }, [item]);
    // 드롭다운 변경 핸들러
    const handleDropChange = (dropItem) => {
        setDropValue(dropItem);
        APP.eventManager.publish('UPDATE_SURVEY_ITEM', {
            id: item.id,
            data: { type: dropItem.id },
        });
    };
    const renderQuestion = useMemo(() => (React.createElement("div", { className: "survey-title" },
        React.createElement("p", null,
            " Q",
            index + 1,
            ". "),
        isRegist ? (React.createElement(InputElement, { ref: titleRef, className: `survey-input ${isMobile ? '' : 'body4 '}`, type: "text", defaultValue: data?.title, placeholder: t('survey.inputQuestionPlaceholder'), onChange: value => {
                setData({ ...data, title: value });
                APP.eventManager.publish('UPDATE_SURVEY_ITEM', {
                    id: data.id,
                    data: { title: value },
                });
            } })) : (React.createElement("p", null, data?.title)))), [index, titleRef, data]);
    const renderType = useMemo(() => (
    /* 드롭다운 */
    React.createElement(DropDown, { className: "dropdown-mini", value: dropValue, list: SURVEY_DROP_LIST, setDropValue: handleDropChange, option: {
            titleIcon: { show: false },
            itemIcon: { show: false },
        }, styles: {
            dropWrap: { width: isMobile ? '100%' : 85 },
            titleWrap: { backgroundColor: Colors.transparent, height: '100%' },
        }, isDisable: !isRegist })), [dropValue]);
    const pieData = DataValue => {
        const data = DataValue.map(item => ({
            id: item.value,
            value: item.count,
        }));
        return data;
    };
    // const pagingCount = 3;
    // const showCount = frequencies ? frequencies[item.id].length : 0;
    // const showCount = 5;
    const handlerMoreData = (page) => {
        setPage(page);
    };
    const renderEmpty = useMemo(() => (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } }, t(`assignment.noSubmit`))), []);
    if (!data)
        return null;
    return (React.createElement("div", { className: "survey body4", key: index },
        React.createElement("div", { className: "survey-header" }, isMobile ? (React.createElement(React.Fragment, null,
            renderType,
            renderQuestion)) : (React.createElement(React.Fragment, null,
            renderQuestion,
            renderType))),
        React.createElement("div", { className: "survey-result" },
            React.createElement("div", { className: "survey-content" }, dropValue.id === SURVEY_TYPE.CHECKBOX ||
                dropValue.id === SURVEY_TYPE.OBJECTIVE ? (React.createElement("div", null, frequencies[data.id] ? (React.createElement("div", null,
                React.createElement("div", { className: "survey_option_detail active", onClick: () => console.log(frequencies[data.id]) },
                    React.createElement(PieChart, { data: pieData(frequencies[data.id]) })),
                React.createElement("table", { className: "survey-table" },
                    React.createElement("thead", { className: "survey-data-header" },
                        React.createElement("tr", null,
                            React.createElement("th", null, "\uB2F5\uBCC0"),
                            React.createElement("th", { className: "count-column" }, "\uAC1C\uC218"))),
                    React.createElement("tbody", null, frequencies[data.id].map((answer, index) => (React.createElement("tr", { key: index },
                        React.createElement("td", null,
                            React.createElement("p", { className: "overText" }, answer.value)),
                        React.createElement("td", { className: "count-column" }, answer.count)))))))) : (renderEmpty))) : frequencies[data.id] ? (React.createElement("div", null,
                React.createElement("table", { className: "survey-table" },
                    React.createElement("thead", { className: "survey-data-header" },
                        React.createElement("tr", null,
                            React.createElement("th", null, "\uB2F5\uBCC0"),
                            React.createElement("th", { className: "count-column" }, "\uAC1C\uC218"))),
                    React.createElement("tbody", null, frequencies[data.id]
                        .slice(page * count, (page + 1) * count)
                        .map((answer, index) => (React.createElement("tr", { key: index },
                        React.createElement("td", null,
                            React.createElement("p", { className: "overText" }, answer.value)),
                        React.createElement("td", { className: "count-column" }, answer.count)))))),
                frequencies[data.id].length > 5 && (React.createElement(Paging, { defaultShowCount: 3, showCount: 5, page: page, pagingCount: 3, data: frequencies[data.id], totalCount: frequencies[data.id].length, handlerMoreData: handlerMoreData })))) : (renderEmpty)),
            isRegist && (React.createElement("div", { className: "survey-footer" },
                React.createElement(Button, { styles: {
                        buttonWrap: {
                            background: Colors.transparent,
                        },
                    }, onClick: () => APP.eventManager.publish('DELETE_SURVEY_ITEM', { id: item.id }), option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'del-survey',
                            size: isMobile ? 14 : 20,
                            color: Colors.black,
                        },
                    } }),
                React.createElement(Button, { styles: {
                        buttonWrap: {
                            background: Colors.transparent,
                        },
                    }, onClick: () => APP.eventManager.publish('ADD_SURVEY_ITEM'), option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'add-survey',
                            size: isMobile ? 14 : 20,
                            color: Colors.black,
                        },
                    } }))))));
});
export default SurveyResultItem;
