import { objectToQueryString } from '../../../../server/functions';
import { getClassesId } from '../../../base/classes';
/**
 * HOME
 *  - 강사 / 조교
 *      카테고리, 리스트, 날짜
 *  - 학생
 *      답변 상태, 리스트, 날짜
 *
 * Q&A 리스트
 *  - 강사 / 조교 / 학생
 *      No, 등록일, 카테고리, 제목, 첨부파일, 등록자, 답변 상태
 *
 * 재질문 리스트
 * - 강사 / 조교 / 학생
 *      No, 등록일, 카테고리, 제목, 등록자, 답변 상태, 비고
 */
export const getQnAListOption = (isHome, isRetry, isTeacher, isMobile) => {
    /**
     * isHome : 홈 화면에서 사용
     * isRetry : 재질문 리스트에서 사용
     * isTeacher : 강사와 학생 보이는것 안보이는것 구분을 위해 사용
     */
    // 기본적인 항목들
    const headTitleList = isHome
        ? []
        : isRetry
            ? isMobile
                ? []
                : [
                    { title: 'no', space: 1, maxWidth: 60 },
                    { title: 'creation_time', space: 2, maxWidth: 150 },
                    { title: 'category', space: 1 },
                    { title: 'live_title', space: 3 },
                    { title: 'author_name_id', space: 2 },
                    { title: 'answer_state', space: 1 },
                    { title: 'etc', space: 1 },
                ]
            : [
                { title: 'no', space: 1, maxWidth: 60 },
                { title: 'creation_time', space: 2, maxWidth: 150 },
                { title: 'category', space: 1 },
                { title: 'live_title', space: 4 },
                { title: 'attachFile', space: 1 },
                { title: 'author_name_id', space: 2 },
                ...(isTeacher ? [{ title: 'answer_name', space: 1 }] : []),
                { title: 'answer_state', space: 1 },
            ];
    const tableReadDataKeys = isHome
        ? [
            { title: 'answerStateAndName', space: 3 },
            { title: 'creation_time', space: 1 },
        ]
        : isRetry
            ? [
                { title: 'index', space: 1, maxWidth: 60 },
                { title: 'creation_time', space: 2, maxWidth: 150 },
                { title: 'category', space: 1 },
                { title: 'content', space: 3 },
                { title: 'author_name', space: 2 },
                { title: 'status', space: 1 },
                { title: 'etc', space: 1 },
            ]
            : [
                { title: 'index', space: 1, maxWidth: 60 },
                { title: 'creation_time', space: 2, maxWidth: 150 },
                { title: 'category', space: 1 },
                { title: 'content', space: 4 },
                { title: 'files', space: 1 },
                { title: 'author_name', space: 2 },
                ...(isTeacher ? [{ title: 'respondent_name', space: 1 }] : []),
                { title: 'status', space: 1 },
            ];
    return { headTitleList, tableReadDataKeys };
};
/**
 * Q&A 리스트 -> 다운로드 버튼
 */
export const downloadQnAList = (data, access_token) => {
    const classesId = getClassesId();
    const parseString = objectToQueryString({ download_tz: '+09:00', ...data });
    const url = `/api/mimac/v1/qnas?${parseString}&class_id=${classesId}`;
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${access_token}`, // 필요한 경우 헤더에 토큰 추가
        },
    })
        .then(async (res) => {
        if (res.status === 200) {
            const contentDisposition = res.headers.get('Content-Disposition');
            let filename = 'download.xlsx'; // 기본 파일명 설정
            if (contentDisposition) {
                const filenameRegex = /filename(?:\*=([^;\n]*)|[^;\n]*=["']?([^"\';\n]*)["']?)/;
                const match = filenameRegex.exec(contentDisposition);
                if (match) {
                    const encodedFilename = match[1] || match[2];
                    if (encodedFilename) {
                        try {
                            filename = decodeURIComponent(encodedFilename.replace(/['"]/g, ''));
                        }
                        catch (e) {
                            console.error('Filename decoding error:', e);
                        }
                    }
                }
            }
            const blob = await res.blob();
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename; // 동적으로 파일명 설정
            document.body.appendChild(a); // a 요소를 문서에 추가
            a.click();
            document.body.removeChild(a); // a 요소를 문서에서 제거
            URL.revokeObjectURL(url); // 메모리 해제
        }
        else if (res.status === 404) {
            throw new Error('검색된 Q&A가 없습니다.');
        }
        else {
            throw new Error('파일을 다운로드할 수 없습니다.');
        }
    })
        .catch(error => {
        console.error('Error fetching file:', error);
        alert(error);
    });
};
