import i18next from 'i18next';
import moment from 'moment';
import React from 'react';
import { Colors } from '../../../../../Colors';
import { Button } from '../../../../../common';
import { goToConference } from '../../funtions';
import { useGlobalState } from '../../../../../GlobalStateProvider';
const renderTextContent = (headItem, item, progressTime, isTablet, isTeacher, isPrevious) => {
    switch (headItem.title) {
        case 'participant':
            return (React.createElement("span", null,
                item.members.filter(item => item.role_name === 'participant').length || 0,
                "\uBA85"));
        case 'dateAndParticipant':
            return (React.createElement("div", { className: "overText" },
                React.createElement("span", { style: { color: Colors.light_grey } }, moment(item.start_time).format('YYYY-MM-DD HH:mm')),
                '   ',
                React.createElement("span", null,
                    item.members.filter(item => item.role_name === 'participant').length || 0,
                    "\uBA85")));
        case 'status':
            if (isTeacher || progressTime) {
                if (item.status === 'closed')
                    return isPrevious ? (React.createElement("p", { style: {
                            fontSize: isTablet ? 14 : 12,
                            lineHeight: isTablet ? '21px' : '18px',
                            color: Colors.black,
                        } }, "\uC885\uB8CC")) : null;
                return (React.createElement(Button, { className: "reservation-btn", text: "\uC785\uC7A5", onClick: () => goToConference(item.id) }));
            }
            return (React.createElement("p", { className: "contents overText2" }, i18next.t(`conference.${item[headItem.title]}`)));
        case 'title':
            return React.createElement("p", { className: "big overText2" }, item[headItem.title]);
        case 'dutaionTime':
            return (React.createElement("p", { className: "desc overText2" },
                moment(item['start_time']).format('YYYY-MM-DD HH:mm'),
                " ",
                item.progress_duration,
                "\uBD84 (",
                Math.floor(item.pre_entering_duration),
                "\uBD84 \uC804 \uC785\uC7A5)"));
        case 'start_time':
            return (React.createElement("p", { className: "overText2" }, moment(item[headItem.title]).format('YYYY-MM-DD, HH:mm')));
        case 'classDate':
            return React.createElement("p", { className: "overText2" }, moment(item[headItem.title]).format('YYYY-MM-DD'));
        case 'classTime':
            return React.createElement("p", { className: "overText2" }, moment(item[headItem.title]).format('HH:mm'));
        case 'type':
            return React.createElement("p", { className: "overText2" }, i18next.t(`conference.${item[headItem.title]}`));
        case 'progress_duration':
            return (React.createElement("p", { className: "overText2" }, `${item.progress_duration}분 ${item.progress_duration >= 60
                ? `(${Math.floor(item.progress_duration / 60)}시간${item.progress_duration % 60 !== 0
                    ? `${item.progress_duration % 60}분`
                    : ''})`
                : ''}`));
        case 'qna_managers_id':
            return React.createElement("p", { className: "overText2" }, item.qna_managers?.length ? '진행' : '-');
        case 'members':
            return React.createElement("p", { className: "overText2" }, `${item.members} / ${item.members.length}명`);
        default:
            return React.createElement("p", { className: "desc overText2" }, item[headItem.title]);
    }
};
const ReservationItem = ({ item, styles, isMobile, isAttendance, isTeacher, isClosed, tableReadDataKeys, handlerOneDel, savePreviousPage, isPrevious, }) => {
    const { isTablet } = useGlobalState();
    // 상세 페이지로 이동
    const handlerDetailPage = (id, isModify = false) => {
        if (!isAttendance)
            savePreviousPage();
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: isModify ? 'modify' : 'detail',
            id,
            props: isAttendance && isMobile ? item : undefined,
        });
    };
    const progressTime = moment().isBetween(moment(item.start_time).subtract(item.pre_entering_duration, 'minutes'), moment(item.start_time).add(item.progress_duration, 'minutes'));
    const renderMobileView = () => tableReadDataKeys.map((headItem, index) => {
        return (React.createElement("div", { key: headItem.title, className: "mobile-item-wrap", style: { width: '100%' } },
            headItem.title === 'etc' && isTeacher && (React.createElement("div", { className: "flex row ", style: { gap: 6, position: 'absolute', right: '10px', top: '27px' } },
                item.status === 'booked' && !progressTime && (React.createElement(Button, { option: {
                        buttonAfterIcon: {
                            show: true,
                            name: 'edit',
                            color: Colors.dark_blue,
                            size: 15,
                        },
                    }, onClick: () => handlerDetailPage(item.id, true) })),
                item.status !== 'held' && !progressTime && (React.createElement(Button, { option: {
                        buttonAfterIcon: {
                            show: true,
                            name: 'delete',
                            size: 15,
                        },
                    }, onClick: () => handlerOneDel(item.id) })))),
            headItem.title === 'status' && !isTeacher && (React.createElement("div", { className: "flex row ", style: {
                    width: 60,
                    gap: 6,
                    position: 'absolute',
                    right: '10px',
                    top: progressTime ? '34px' : '41px',
                    justifyContent: 'center',
                } }, renderTextContent(headItem, item, progressTime, isTablet, isTeacher, isPrevious))),
            React.createElement("div", { className: `${headItem.title} ${headItem.title === 'start_time' ? 'desc' : ''}`, key: index, onClick: !isTeacher ||
                    (!isClosed && headItem.title === 'status') ||
                    headItem.title === 'etc'
                    ? undefined
                    : () => handlerDetailPage(item.id) }, !(headItem.title === 'status' && !isTeacher) &&
                renderTextContent(headItem, item, progressTime, isTablet, isTeacher, isPrevious))));
    });
    const renderDesktopView = () => tableReadDataKeys.map((headItem, index) => (React.createElement("div", { className: `${headItem.title} reservation-item`, key: headItem.title + index, style: {
            flex: headItem.space,
            ...styles?.bodyItem,
            overflow: 'hidden',
            cursor: !isAttendance && (!isTeacher || (!isClosed && headItem.title === 'status'))
                ? 'default'
                : 'pointer',
            ...(headItem.title === 'status' && { pointerEvents: 'auto' }),
        }, onClick: !isTeacher ||
            (!isClosed && headItem.title === 'status') ||
            headItem.title === 'etc'
            ? undefined
            : () => handlerDetailPage(item.id) }, headItem.title === 'etc' ? (React.createElement("div", { className: "flex row", style: { gap: 10 } },
        item.status === 'booked' && !progressTime && (React.createElement(Button, { option: {
                buttonAfterIcon: {
                    show: true,
                    name: 'edit',
                    color: Colors.dark_blue,
                    size: 20,
                },
            }, onClick: () => handlerDetailPage(item.id, true) })),
        item.status !== 'held' && !progressTime && (React.createElement(Button, { option: {
                buttonAfterIcon: {
                    show: true,
                    name: 'delete',
                    size: 20,
                },
            }, onClick: () => handlerOneDel(item.id) })))) : (React.createElement(React.Fragment, null, renderTextContent(headItem, item, progressTime, isTablet, isTeacher, isPrevious))))));
    if (!item)
        return null;
    if (isMobile)
        return React.createElement(React.Fragment, null, renderMobileView());
    return React.createElement(React.Fragment, null, renderDesktopView());
};
export default React.memo(ReservationItem);
