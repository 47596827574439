import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Button } from '../../../common';
import { useAssignmentList, useSubmitStatus } from '../../../server';
import { getIsTeacher } from '../../base/account';
import { SurveyContainer } from '../../base/layout/Survey/SurveyContainer';
import { TOGGLE_POPUP } from '../../base/popup';
import { ErrataTable, NameTagContainer } from './component/DSTL001_View_Child';
import { AttachFileContainer } from '../../DSS/DSSL001/DSSL001_Detail_Child/AttachFileContainer';
const DSTL001ViewStyled = styled.div `
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: ${props => (props.$isMobile ? 'hidden' : props.$isDetail ? 'unset' : 'auto')};
	gap: ${props => (props.$isMobile ? '24px' : 'unset')};
	${props => (props.$isMobile && 'padding: 10px 20px;')};

	&.hidetitle {
		height: 100%;
		flex: 1;
	}

	input:disabled,
	textarea:disabled {
		background: var(--color-white, #ffffff);
	}

	.field-short {
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
		padding: 0px 0px 20px 0px;
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
	}

	.errataTable_wrap {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		height: 100%;

		.score {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;

			padding: 20px 0px 20px 0px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			${props => props.$isMobile
    ? css `
							.label {
								color: var(--color-grey-d, #4d4d4d);
								text-align: right;
								font-family: 'NotoSansKr-Bold', sans-serif;
								font-size: 16px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 700;
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
							.value {
								color: #f46790;
								text-align: right;
								font-family: 'NotoSansKr-Bold', sans-serif;
								font-size: 20px;
								line-height: 150%;
								letter-spacing: -0.02em;
								font-weight: 700;
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
					  `
    : css `
							height: 100px;

							.label {
								color: var(--color-grey-d, #4d4d4d);
								text-align: right;
								font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
								font-size: var(--h3-font-size, 24px);
								line-height: var(--h3-line-height, 36px);
								font-weight: var(--h3-font-weight, 700);
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
							.value {
								color: #f46790;
								text-align: right;
								font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
								font-size: var(--h3-font-size, 24px);
								line-height: var(--h3-line-height, 36px);
								font-weight: var(--h3-font-weight, 700);
								position: relative;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
					  `}

			.fixed {
				color: #f46790;
				text-align: right;
				font-family: var(--h3-font-family, 'NotoSansKr-Bold', sans-serif);
				font-size: var(--h3-font-size, 24px);
				line-height: var(--h3-line-height, 36px);
				font-weight: var(--h3-font-weight, 700);
				position: relative;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}

	&.hidetitle {
		.errataTable_wrap {
			margin-top: 12px;
		}
	}

	.btn_default {
		margin-top: 36px;
	}

	.no_submit_wrap {
		width: 100%;
		justify-content: center;
		align-items: center;
		display: flex;
		color: var(--color-grey-l, #83868a);
		text-align: left;
		font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
		font-size: var(--b3-font-size, 16px);
		line-height: var(--b3-line-height, 32px);
		font-weight: var(--b3-font-weight, 400);
		position: relative;
		height: ${props => (props.$isTablet ? '100%' : undefined)};
	}

	.name-tag-info {
		gap: 12px;
		display: flex;
		flex-direction: column;
	}

	.att_submit_view {
		display: flex;
		flex-direction: column;
		gap: 12px;
		flex: 1;
	}

	.feedback-submit-button {
		display: flex;
		justify-content: flex-end;
	}

	.feedback-file {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.submit-false {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.survey_option_detail {
		display: none;

		.radio_wrap {
			label {
				> span {
					margin-left: 6px;
					font-size: 12px;
					line-height: 1.41;
					letter-spacing: -0.01em;
				}
			}

			.mark {
				background: var(--color-grey-c);
				border-color: var(--color-grey-c);
			}
		}

		.chk_wrap {
			label {
				> span {
					margin-left: 6px;
					font-size: 12px;
					line-height: 1.41;
					letter-spacing: -0.01em;
				}
			}
		}
	}

	.survey_option_detail.active {
		display: block;
	}

	.survey_option_detail[survey-option='1'].active {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 5px 20px;
	}

	.survey_option_detail[survey-option='4'].active {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		gap: 5px 20px;
	}

	.survey-submit-view {
		// height: calc(100% - 70px);
		overflow: auto;
		gap: 12px;
		display: grid;
		padding: 10px;
	}

	.survey-table {
		width: 100%;
		border-collapse: collapse;
	}

	.survey-table th,
	.survey-table td {
		font-weight: 100;
		padding: 10px;
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 0px 1px 0px;
	}

	.survey-data-header {
		background: var(--color-silver, #f3f4f8);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px 0px 0px 0px;
		position: relative;
		width: 100%;
	}

	.survey-data-header th {
		background: var(--color-silver, #f3f4f8);
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px 0px 0px 0px;
	}

	.count-column {
		width: 50px;
		text-align: center;
	}

	.survey-table td,
	.survey-table th,
	.survey-title p {
		white-space: nowrap; /* Prevents text from wrapping to the next line */
		overflow: hidden; /* Hides the overflow content */
		text-overflow: ellipsis; /* Adds ellipsis (…) for overflow text */
	}

	.survey-table td {
		white-space: nowrap; /* Prevents text from wrapping */
		overflow: hidden; /* Hides overflow content */
		text-overflow: ellipsis; /* Adds ellipsis for overflow text */
		max-width: 200px; /* Set a max width for the cells, adjust as needed */
	}

	.survey-title {
		display: flex;
		align-items: center;
		width: 100%; /* Adjust the width if needed */
	}

	.survey-result {
		padding: 20px 20px 20px 20px;
		border-radius: 0px 0px 8px 8px;
		border-style: solid;
		border-color: var(--color-silver, #f3f4f8);
		border-width: 0px 1.5px 1.5px 1.5px;
	}
`;
// 제출 결과 테이블
const count = 20;
export const DSTL001_Submit_View = React.memo(({ hideTitle = false, test_id, member, subjectId = '', isDetail = false, isPreview = false, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile } = useGlobalState();
    const [testInfo, setTestInfo] = useState();
    const [score, setScore] = useState(0); // 총점 조회
    const [submitted, setSubmitted] = useState(false);
    // const postFeedbackMutation = useMutation(postFeedback);
    const { member_id, member_name } = useMemo(() => {
        return { member_id: member?.id || '', member_name: member?.name };
    }, [member]);
    const isTeacher = useSelector((state) => getIsTeacher(state));
    // 과제 정보 조회
    const { data, refetch } = useAssignmentList({ id: test_id });
    // 제출 현황 정보 조회
    const { data: statisticData, refetch: statisticRefetch } = useSubmitStatus({
        id: test_id,
        member_id,
        ...(member_id !== '' ? { subject_item: subjectId } : {}),
        offset: 0,
        limit: 1,
    });
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    const refetchStatisticList = throttle(() => {
        statisticRefetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (!hideTitle && test_id)
            refetchList();
        if (test_id)
            refetchStatisticList();
    }, [hideTitle, test_id, member]);
    useEffect(() => {
        if (!data)
            return;
        const { total_count, items } = data;
        if (total_count >= 1) {
            setTestInfo(items[0]);
        }
    }, [data]);
    useEffect(() => {
        if (!statisticData)
            return;
        const { total_count, items, total_score } = statisticData;
        if (!member_id) {
            setScore(total_score);
            setSubmitted(true);
            return;
        }
        if (total_count >= 1) {
            const memberItem = items.find(item => item.member_id === member_id);
            const score = member_id !== '' ? memberItem?.score || 0 : total_score;
            const submitted = memberItem ? (memberItem?.not_submitted ? false : true) : false;
            setScore(score === -1 ? 0 : score);
            setSubmitted(submitted);
        }
        else {
            setScore(0);
            setSubmitted(false);
        }
    }, [statisticData, member_id]);
    const hidePopup = () => {
        APP.eventManager.publish(TOGGLE_POPUP);
    };
    const test_type = useMemo(() => testInfo
        ? testInfo.type +
            `${testInfo.type === 'OMR' && testInfo.subject !== ''
                ? ' - ' + t(`assignment.${testInfo.subject}`)
                : ''}`
        : '', [testInfo]);
    const renderSurveyContent = () => {
        return (React.createElement(SurveyContainer, { isPreview: isPreview, item: testInfo, test_id: test_id, member_id: member_id, submitted: submitted }));
    };
    const renderFileAttachmentContent = () => {
        return (React.createElement("div", { className: "att_submit_view" },
            React.createElement(AttachFileContainer, { isPreview: isPreview, item: testInfo, test_id: test_id, member_id: member_id, submitted: submitted })));
    };
    const renderOmrContent = () => {
        return (React.createElement("div", { className: "errataTable_wrap" },
            !hideTitle && (React.createElement("div", { className: "score" },
                React.createElement("div", { className: "label" }, t('assignment.allScore')),
                React.createElement("div", { className: "value" }, t('assignment.scoreValue', { score })))),
            React.createElement(ErrataTable, { subject_name: testInfo.subject, isDetail: !!member_id, test_id: test_id, member_id: member_id, isTeacher: isTeacher }),
            !hideTitle && !isMobile && !isTeacher && (React.createElement(Button, { className: "btn_default xmd", text: "common.complete", onClick: hidePopup }))));
    };
    if (!testInfo) {
        return (React.createElement(DSTL001ViewStyled, { "$isDetail": isDetail, "$isTablet": isTablet, "$isMobile": isMobile }, t('assignment.noAssignment')));
    }
    return (React.createElement(DSTL001ViewStyled, { "$isDetail": isDetail, "$isTablet": isTablet, "$isMobile": isMobile, className: `${hideTitle ? 'hidetitle' : ''}` },
        hideTitle ? (React.createElement("div", { className: "score-title" }, hideTitle && !member_id
            ? t('assignment.scoreStatus2')
            : t('assignment.scoreStatus', { score, member_name }))) : (React.createElement(NameTagContainer, { test_type: test_type, member_name: member_name })),
        testInfo.type === '설문형'
            ? renderSurveyContent()
            : testInfo.type === '파일첨부형'
                ? renderFileAttachmentContent()
                : renderOmrContent()));
});
