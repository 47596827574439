import { throttle } from 'lodash';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Colors } from '../../../../Colors';
import { EmptyTable } from '../../../../common/option';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { useAnswerList } from '../../../../server';
import { ErrataTable } from '../../../DST/DSTL001/component/DSTL001_View_Child';
import { AttachmentQuestionTable } from './AttachmentQuestionTable';
import { DetailQuestionTable } from './DetailQuestionTable';
import { SurveyQuestionTable } from './SurveyQuestionTable';
const SubmissionPopupStyled = styled.div `
	border-width: ${({ $isDesktop }) => (!$isDesktop ? '0' : ' 0px 0px 1px 0px;')};
	border-style: solid;
	border-color: var(--color-grey-w, #b6bac0);

	${({ $isMobile }) => $isMobile &&
    `
		width: 100%;
		height: 100%;
	`}

	.title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);

		letter-spacing: var(--h4-letter-spacing, -0.02em);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;

		${({ $isMobile, $isTablet }) => $isMobile
    ? `
		font-size: var(--h6-font-size, 14px);
		line-height: var(--h6-line-height, 18px);
		`
    : $isTablet
        ? `
			font-size: var(--h5-font-size, 16px);
			line-height: var(--h5-line-height, 24px);`
        : `

			font-size: var(--h4-font-size, 20px);
			line-height: var(--h4-line-height, 32px);
		`}
	}

	${({ $isDesktop }) => $isDesktop &&
    `
	.empty_data {
		flex: unset;
		height: 240px;
	}
	`}
`;
export const SubmissionPopup = React.memo(({ test_id, member_id, item, props, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [isSubmit, setIsSubmit] = React.useState(false);
    const { data: answerData, refetch: answerRefetch } = useAnswerList({
        test_id,
        from: member_id,
        orderby: 'value',
    });
    const refetchList = throttle(answerRefetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        member_id && test_id && refetchList();
    }, [member_id, test_id, isSubmit]);
    useEffect(() => {
        if (!answerData)
            return;
        if (answerData.found_count > 0) {
            setIsSubmit(true);
        }
        else {
            setIsSubmit(false);
        }
    }, [answerData]);
    const type = React.useMemo(() => item?.type, [item?.type]);
    const renderContent = React.useMemo(() => {
        if (!item)
            return null;
        if (item.status === 'created' || (item.status === 'closed' && !isSubmit)) {
            const title = item.status === 'created'
                ? t('assignment.notOpen', {
                    name: t(`assignment.${props.isTest ? 'test' : 'name'}`),
                })
                : t('assignment.notSubmit');
            return (React.createElement(EmptyTable, { title: title, iconProps: {
                    icon: 'detail-delete',
                    size: isMobile ? 28 : 32,
                    fill: Colors.w_grey,
                } }));
        }
        else {
            switch (type) {
                case '설문형':
                    return (React.createElement(SurveyQuestionTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, answerData: answerData, isSubmit: isSubmit }));
                case '파일첨부형':
                    return (React.createElement(AttachmentQuestionTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, answerData: answerData, isSubmit: isSubmit }));
                default:
                    if (isSubmit) {
                        return (React.createElement(ErrataTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, isSubmit: isSubmit }));
                    }
                    return (React.createElement(DetailQuestionTable, { ...props, item: item, isOpen: item?.status === 'open' ? true : false, subject_name: props.subject_name, isSubmit: isSubmit }));
            }
        }
    }, [item, isSubmit, answerData, props]);
    return (React.createElement(SubmissionPopupStyled, { className: "frame-987345", "$isMobile": isMobile, "$isTablet": isTablet, "$isDesktop": isDesktop },
        isDesktop && React.createElement("div", { className: "title" }, t('assignment.detailQuestions')),
        item && renderContent));
});
