import React from 'react';
import styled from 'styled-components';
import { Colors } from '../../Colors';
import { useGlobalState } from '../../GlobalStateProvider';
import { Button } from '../../common';
const RegistButtonStyled = styled.div `
	.button {
		width: 48px;
		height: 48px;
	}
`;
export const RegistButton = React.memo(({ handlerButtonClick }) => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    return (React.createElement(RegistButtonStyled, { "$isMobile": isMobile, "$isTablet": isTablet },
        React.createElement(Button, { className: "btn_pink round", option: {
                buttonBeforeIcon: {
                    show: true,
                    name: 'penEdit',
                    size: 18,
                    color: Colors.white,
                },
            }, styles: {
                buttonWrap: {
                    position: 'fixed',
                    bottom: isDesktop ? 55 : 139,
                    right: isMobile ? 26 : isTablet ? 46 : 62,
                    width: 48,
                    height: 48,
                },
            }, onClick: handlerButtonClick })));
});
