import React, { useState } from 'react';
import styled from 'styled-components';
import { useGlobalState } from '../../../GlobalStateProvider';
import { AttachContentButton } from './DSTS006_List_Child/AttachContentButton';
import { ContentTab } from './DSTS006_List_Child/ContentTab';
import { ContentTable } from './DSTS006_List_Child/ContentTable';
const DSTS006ListStyled = styled.div `
	display: flex;
	flex-direction: column;
	gap: ${props => (props.$isMobile ? '0px' : '24px')};
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	position: relative;
	flex: 1;

	${props => props.$isTablet && `padding: 20px`};

	.regist_btn {
		position: fixed;
		right: ${props => (props.$isMobile ? '26px' : props.$isTablet ? '46px' : '62px')};
		bottom: ${props => (props.$isDesktop ? '55px' : '139px')};
		overflow: visible;

		.button {
			width: 48px;
			height: 48px;
		}

		.attach-field {
			display: flex;
			flex-direction: row;
			width: 100%;
			gap: 15px;
		}
	}
`;
export const DSTS006_List = React.memo(() => {
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const [menu, setMenu] = useState('document');
    return (React.createElement(DSTS006ListStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile, "$isTablet": isTablet, className: "contents-list" },
        React.createElement(ContentTab, { menu: menu, setMenu: setMenu }),
        React.createElement(ContentTable, { menu: menu }),
        React.createElement(AttachContentButton, { menu: menu })));
});
