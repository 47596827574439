import { throttle } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../Colors';
import { Button, InputElement } from '../../../common';
import { useGlobalState } from '../../../GlobalStateProvider';
import { postModifyReplay, useReplyList } from '../../../server';
import { getClassesId } from '../../base/classes';
import { DetailTitleInfo, MobileTabLayout } from '../../base/layout';
import { TOGGLE_POPUP } from '../../base/popup';
import { changeMobileMenuName, goToPrevMenu, updateMenu } from '../../menu/action';
import { RegistFileTable, SetPublicRadio, WatchHistoryTable, WatchMember, WeeksDropDown, } from './component/DSTT007_Detail_Child';
import { ReplayDropDown } from './component/DSTT007_Detail_Child/ReplayDropDown';
import { fetchVideoUrl } from './functions';
const DSTT007_DetailStyled = styled.div `
	width: 100%;
	${({ $isMobile }) => $isMobile && `padding: 20px;`}

	.video-area {
		border-radius: 12px;
		flex-shrink: 0;
		height: 284px;
		max-width: 500px;
		position: relative;
		overflow: hidden;
		width: 100%;

		video {
			background: var(--color-black, #010101);

			width: 100%;
			height: 100%;
			object-fit: contain;
			z-index: 1;
		}

		.txt {
			color: #ffffff;
			text-align: left;
			font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
			font-size: var(--h4-font-size, 20px);
			line-height: var(--h4-line-height, 32px);
			letter-spacing: var(--h4-letter-spacing, -0.02em);
			font-weight: var(--h4-font-weight, 700);
			position: absolute;
			left: 22.83px;
			top: 14px;
		}
	}

	.loading {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.dst007-container {
		display: flex;
		flex-direction: row;
		gap: 24px;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		height: 100%;

		.left-wrap {
			display: flex;
			flex-direction: column;
			gap: 24px;
			align-items: flex-end;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
			flex: 1;
			width: 100%;

			.title-info {
				display: flex;
				flex-direction: column;
				gap: 24px;
				align-items: flex-start;
				justify-content: flex-start;
				align-self: stretch;
				flex-shrink: 0;
				position: relative;
				flex: 1;
			}

			.field-short {
				flex: 0;
			}

			.title-wrap {
				border-style: solid;
				border-color: var(--color-grey-c, #e6e8ed);
				border-width: 0px 0px 1px 0px;
				padding: 0px 0px 28px 0px;

				.fext-field {
					.dropdown {
						max-width: 184px;
					}
				}

				.dropdown {
					min-width: 157px;
				}
			}

			.file-wrap {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;

				.task-label {
					min-height: 50px;
				}
			}
		}

		.right-wrapper {
			max-width: 600px;
		}

		.file-container {
			background: var(--color-white, #ffffff);
			border-radius: 4px;
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px;
			padding: 10px;
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: center;
			justify-content: flex-start;
			flex: 1;
			position: relative;
			overflow: hidden;
			flex-wrap: wrap;

			.user-name {
				background: var(--bg-bgw1, #f3f4f8);
				border-radius: 100px;
				padding: 4px 8px 4px 8px;
				display: flex;
				flex-direction: row;
				gap: 4px;
				align-items: center;
				justify-content: flex-start;
				flex-shrink: 0;
				position: relative;

				.name {
					color: var(--font-dgrey, #4d4d4d);
					text-align: left;
					font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
					font-size: var(--b5-font-size, 12px);
					line-height: var(--b5-line-height, 22px);
					letter-spacing: var(--b5-letter-spacing, -0.02em);
					font-weight: var(--b5-font-weight, 400);
				}
			}
		}
	}

	.member-wrap {
		display: flex;
		flex-direction: column;
	}

	.task-label-btn {
		flex-direction: row;
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 20px;
	}

	${props => (props.$isMobile ? mobileStyles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileStyles = css `
	.title-wrap {
		flex-direction: column;
		gap: 12px;
		.fext-field {
			flex-direction: column;
		}
	}

	.replay-info-wrap {
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		width: 100%;
	}

	.title-info {
		display: flex;
		flex-direction: column;
		gap: 14px;
	}

	.file-wrap {
		flex-direction: column;
	}
	.mobile-full {
		padding: 6px 12px;
		border-radius: 4px;
		.overText {
			justify-content: center;
		}
	}

	.file-container {
		background: var(--color-white, #ffffff);
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		gap: 10px;
		align-items: center;
		justify-content: flex-start;
		flex: 1;
		position: relative;
		overflow: hidden;

		.user-name {
			background: var(--bg-bgw1, #f3f4f8);
			border-radius: 100px;
			padding: 4px 8px 4px 8px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.name {
				color: var(--font-dgrey, #4d4d4d);
				text-align: left;
				font-family: var(--b5-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b5-font-size, 12px);
				line-height: var(--b5-line-height, 22px);
				letter-spacing: var(--b5-letter-spacing, -0.02em);
				font-weight: var(--b5-font-weight, 400);
			}
		}
	}

	.task-label-btn {
		min-height: 40px !important;
		gap: 14px;
	}
`;
const tabletStyles = css `
	padding: 20px;
	.task-label-btn {
		min-height: 50px !important;
	}
`;
const desktopStyles = css ``;
async function readableStreamToBlob(readableStream) {
    const reader = readableStream.getReader();
    const chunks = [];
    let done = false;
    while (!done) {
        const { value, done: streamDone } = await reader.read();
        if (value) {
            chunks.push(value);
        }
        done = streamDone;
    }
    return new Blob(chunks);
}
export const DSTT007_Detail = React.memo(({ id, isRegist = false, isModify = false, }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const titleRef = useRef(null);
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    const [item, setItem] = React.useState({});
    const [title, setTitle] = React.useState('');
    const [week, setWeek] = React.useState('');
    const [publicValue, setPublicValue] = React.useState('');
    const [startDate, setStartDate] = React.useState('');
    const [startTime, setStartTime] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [endTime, setEndTime] = React.useState('');
    const [registFile, setRegistFile] = React.useState([]);
    const [keepMediaList, setKeepMediaList] = React.useState();
    const [members, setMembers] = React.useState();
    const [previewId, setPreviewId] = React.useState(); //미리보기 아이디
    const [loading, setLoading] = React.useState(false);
    const [selectDay, setSelectDay] = React.useState('');
    const [selectYear, setSelectYear] = React.useState('');
    const [selectMonth, setSelectMonth] = React.useState('');
    const [resultFileId, setResultFileId] = React.useState(); //다시보기 등록 - 파일 분할 업로드 시 id값
    const [isEmpty, setIsEmpty] = React.useState(false);
    const isDetail = isRegist === false && isModify === false ? true : false;
    const videoRef = useRef();
    const now = useMemo(() => moment(), []);
    const modifyReplayMutation = useMutation(postModifyReplay);
    const { data, refetch } = useReplyList({
        id,
        with_more_info: true,
    });
    const refetchList = throttle(() => {
        refetch();
    }, 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (id) {
            title && dispatch(changeMobileMenuName(title));
            refetchList();
            return;
        }
        setPublicValue('open');
        setStartDate(now.format('YYYY-MM-DD'));
        setStartTime(now.format('HH:mm'));
        setEndDate(now.format('YYYY-MM-DD'));
        setEndTime(now.format('HH:mm'));
    }, [id, isRegist, isModify]);
    useEffect(() => {
        APP.eventManager.subscribe('REPLAY_DETAIL_REFRESH', refetchList);
        return () => {
            APP.eventManager.unsubscribe('REPLAY_DETAIL_REFRESH', refetchList);
        };
    }, []);
    useEffect(() => {
        if (id) {
            if (data) {
                const { items } = data;
                if (items.length > 0) {
                    const item = items[0];
                    if (item) {
                        if (titleRef.current)
                            titleRef.current.value = item.title;
                        setItem(item);
                        setTitle(item.title);
                        setWeek(String(item.round));
                        setPublicValue(item.status);
                        setStartDate(moment(item.open_start_time).format('YYYY-MM-DD'));
                        setStartTime(moment(item.open_start_time).format('HH:mm'));
                        setEndDate(moment(item.open_end_time).format('YYYY-MM-DD'));
                        setEndTime(moment(item.open_end_time).format('HH:mm'));
                        setMembers(item?.allowed_viewers);
                        setSelectYear(item.year.toString());
                        setSelectMonth(item.month.toString());
                        setKeepMediaList(item.medias.map(media => media.id));
                        return;
                    }
                }
            }
            setItem(undefined);
        }
    }, [data]);
    useEffect(() => {
        dispatch(changeMobileMenuName(title));
    }, [title]);
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.src = undefined;
            videoRef.current.controls = false;
        }
        if (previewId) {
            setLoading(true);
            fetchVideoUrl(id, previewId).then(async (videoStream) => {
                try {
                    videoRef.current.src = `/api/media/v1/medias/${previewId}/source`;
                    videoRef.current.controls = true;
                    videoRef.current.play();
                    setLoading(false);
                }
                catch (error) {
                    alert('비디오를 로드하는 동안 오류가 발생했습니다. - 리소스에 없는 파일입니다. ');
                    console.error('비디오를 로드하는 동안 오류가 발생했습니다:', error);
                }
            });
        }
    }, [previewId]);
    const previousPage = () => {
        dispatch(goToPrevMenu());
    };
    const modifyPage = () => {
        dispatch(updateMenu(undefined, { menu: 'reply', detail: 'modify' }, id));
    };
    const openMemberList = () => {
        setIsEmpty(false);
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: WatchMember,
            componentProps: {
                members,
                setMembers,
            },
            width: isMobile && 400,
            height: 'unset',
            title: '참여자 추가',
        });
    };
    const onSubmit = async () => {
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            const choiceMember = members?.map(member => member.id);
            const files = registFile.map(file => ({
                name: file.name,
                size: file.size,
            }));
            if (!id && isModify)
                throw new Error('id가 없습니다.');
            const response = await modifyReplayMutation.mutateAsync({
                id: isModify ? id : resultFileId,
                data: {
                    class_id: getClassesId(),
                    //...(isModify ? { files: files } : {}),
                    title: title,
                    round: parseInt(week),
                    year: parseInt(selectYear),
                    month: parseInt(selectMonth),
                    open_type: publicValue,
                    open_start_time: moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                    open_end_time: moment(`${endDate} ${endTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                    viewer_user_id: choiceMember && choiceMember.map(member => member),
                    ...(keepMediaList ? { media_id: keepMediaList } : {}),
                },
            });
            if (response) {
                setTimeout(() => {
                    dispatch(updateMenu(undefined, { menu: 'reply', detail: 'detail' }, isModify ? id : resultFileId));
                    APP.eventManager.publish('SHOW_LOADING', false);
                }, 1000);
            }
        }
        catch (err) {
            APP.eventManager.publish('SHOW_LOADING', false);
            console.log('err');
            alert(err);
        }
        finally {
        }
    };
    const renderReplayInfoTitle = useMemo(() => {
        return (React.createElement(InputElement, { ref: titleRef, type: "text", onChange: setTitle, isDisable: isRegist ? false : true, style: {
                background: Colors.white,
            } }));
    }, [isRegist, setTitle]);
    const renderDateDropdown = useMemo(() => (React.createElement(ReplayDropDown, { setDropValue: setSelectDay, setSelectYear: setSelectYear, setSelectMonth: setSelectMonth, selcetMonth: isModify ? selectMonth : undefined, selectYear: isModify ? selectYear : undefined })), [isModify, selectMonth, selectYear, setSelectDay, setSelectYear, setSelectMonth]);
    const renderWeeksDropdown = useMemo(() => (React.createElement(WeeksDropDown, { isDisable: isRegist ? false : true, defaultWeek: item?.round, handlerChange: setWeek })), [isRegist, item?.round]);
    const renderDateInput = useMemo(() => (React.createElement(InputElement, { type: "text", value: `${item?.year}년 ${item?.month}월`, isDisable: true, style: {
            background: Colors.white,
        } })), [item?.year, item?.month]);
    const renderWeeksinput = useMemo(() => (React.createElement(InputElement, { type: "text", value: item?.round === 0 ? '-' : `${item?.round}주차`, isDisable: true, style: {
            background: Colors.white,
        } })), [item?.round]);
    const renderReplayInfo = useMemo(() => (React.createElement("div", { key: 'repalyInfo', className: "title-info", style: { flex: 'unset' } },
        React.createElement("div", { className: "field-short title-wrap" },
            React.createElement("div", { className: "field-global-001" },
                !isMobile && (React.createElement("div", { className: "task-label" }, t('conference.liveMode'))),
                React.createElement("div", { className: "fext-field" }, isMobile ? (React.createElement("div", { className: "replay-info-wrap" },
                    React.createElement("div", { className: "task-label" }, "\uC81C\uBAA9"),
                    renderReplayInfoTitle)) : (renderReplayInfoTitle))),
            React.createElement("div", { className: "field-global-001" },
                React.createElement("div", { className: "fext-field" }, isRegist ? (React.createElement(React.Fragment, null,
                    isMobile ? (React.createElement("div", { className: "replay-info-wrap" },
                        React.createElement("div", { className: "task-label" }, "\uB0A0\uC9DC\uC124\uC815"),
                        renderDateDropdown)) : (renderDateDropdown),
                    isMobile ? (React.createElement("div", { className: "replay-info-wrap" },
                        React.createElement("div", { className: "task-label" }, "\uC8FC\uCC28\uC124\uC815"),
                        renderWeeksDropdown)) : (renderWeeksDropdown))) : (React.createElement(React.Fragment, null,
                    isMobile ? (React.createElement("div", { className: "replay-info-wrap" },
                        React.createElement("div", { className: "task-label" }, "\uB0A0\uC9DC\uC124\uC815"),
                        renderDateInput)) : (renderDateInput),
                    isMobile ? (React.createElement("div", { className: "replay-info-wrap" },
                        React.createElement("div", { className: "task-label" }, "\uC8FC\uCC28\uC124\uC815"),
                        renderWeeksinput)) : (renderWeeksinput)))))),
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, t('conference.settingPublic')),
            React.createElement("div", { className: "fext-field", style: { gap: 14 } },
                React.createElement(SetPublicRadio, { isDisable: isRegist ? false : true, defaultPublic: item?.status, handlerChange: setPublicValue }))),
        publicValue !== 'hide' && (React.createElement("div", { className: "field-global-001 date-wrap" },
            React.createElement("div", { className: "task-label" }, t('conference.publicDate')),
            isMobile ? (React.createElement("div", { className: "replay-info-wrap", style: { flexDirection: 'column' } },
                React.createElement("div", { className: "fext-field", style: { gap: 14 } },
                    React.createElement(InputElement, { type: "date", value: startDate, onChange: setStartDate, isDisable: isRegist ? false : true, min: now.format('YYYY-MM-DD'), style: {
                            background: Colors.white,
                        } }),
                    React.createElement(InputElement, { type: "time", value: startTime, isDisable: isRegist ? false : true, onChange: setStartTime, style: {
                            background: Colors.white,
                        } })),
                React.createElement("div", { className: "fext-field", style: { gap: 14 } },
                    React.createElement(InputElement, { type: "date", value: endDate, onChange: setEndDate, isDisable: isRegist ? false : true, min: now.format('YYYY-MM-DD'), style: {
                            background: Colors.white,
                        } }),
                    React.createElement(InputElement, { type: "time", value: endTime, isDisable: isRegist ? false : true, onChange: setEndTime, style: {
                            background: Colors.white,
                        } })))) : (React.createElement("div", { className: "fext-field", style: { gap: 14 } },
                React.createElement(InputElement, { type: "date", value: startDate, onChange: setStartDate, isDisable: isRegist ? false : true, min: now.format('YYYY-MM-DD'), style: {
                        background: Colors.white,
                    } }),
                React.createElement(InputElement, { type: "time", value: startTime, isDisable: isRegist ? false : true, onChange: setStartTime, min: now.format('HH:mm'), style: {
                        background: Colors.white,
                    } }),
                React.createElement(InputElement, { type: "date", value: endDate, onChange: setEndDate, isDisable: isRegist ? false : true, min: now.format('YYYY-MM-DD'), style: {
                        background: Colors.white,
                    } }),
                React.createElement(InputElement, { type: "time", value: endTime, isDisable: isRegist ? false : true, onChange: setEndTime, min: now.format('HH:mm'), style: {
                        background: Colors.white,
                    } }))))),
        publicValue === 'open_partial' && (React.createElement("div", { className: "field-global-001 member-wrap" },
            React.createElement("div", { className: "task-label task-label-btn", style: { flexDirection: 'row' } },
                React.createElement("p", null, t('conference.invite')),
                !isDetail && (React.createElement(Button, { className: `btn_white ${isMobile ? 'mobile-full' : 'xmd'}`, text: "\uCC38\uC5EC\uC790 \uCD94\uAC00", onClick: openMemberList }))),
            React.createElement("div", { className: "fext-field", style: { gap: 14 } }, members && !isEmpty && (React.createElement("div", { className: "file-container" }, members && members.length <= 0 ? (React.createElement("div", null, " \uC2DC\uCCAD \uAC00\uB2A5\uD55C \uC0AC\uC6A9\uC790\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4.")) : (members.map(member => {
                return (React.createElement("div", { key: member.id, className: "member user-name" },
                    React.createElement("span", { className: "name" }, member.name),
                    React.createElement(Button, { className: "xsm", option: {
                            buttonAfterIcon: {
                                show: isDetail ? false : true,
                                name: 'close',
                                color: Colors.accent,
                                size: 16,
                            },
                        }, onClick: () => {
                            setMembers(members.filter(m => m.id !== member.id));
                            setIsEmpty(true);
                        } })));
            }))))))))), [
        isModify,
        item,
        selectDay,
        week,
        isRegist,
        publicValue,
        members,
        startDate,
        startTime,
        endDate,
        endTime,
        renderDateDropdown,
        renderDateInput,
        renderWeeksDropdown,
        renderWeeksinput,
    ]);
    const renderReplayTable = useMemo(() => (React.createElement(RegistFileTable, { id: id, key: 'repalyTable', isRegist: isRegist, medias: item?.medias, previewId: previewId, setPreviewId: (id) => {
            !loading && setPreviewId(id);
        }, setRegistFile: setRegistFile, setKeepMediaList: setKeepMediaList, setResultFileId: setResultFileId, isModify: isModify, keepMediaList: keepMediaList })), [
        id,
        isRegist,
        isModify,
        item?.medias,
        previewId,
        loading,
        setPreviewId,
        setRegistFile,
        setKeepMediaList,
        keepMediaList,
    ]);
    const rednerVideo = useMemo(() => (React.createElement("div", { key: 'video' }, !isRegist ? (React.createElement("div", { className: "video-area", style: {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        } },
        React.createElement("video", { ref: videoRef, src: previewId
                ? `/api/media/v1/medias/${id}/source?media_id=${previewId}`
                : '', disablePictureInPicture: true, controlsList: "nodownload", onContextMenu: e => e.preventDefault() }),
        loading && React.createElement("div", { className: "loading" }, "\uB85C\uB529\uC911..."),
        React.createElement("div", { className: "txt" }, "\uBBF8\uB9AC\uBCF4\uAE30"))) : null)), [videoRef, previewId, loading, id, isRegist]);
    const renderWatchHistoryTable = useMemo(() => !isRegist && React.createElement(WatchHistoryTable, { key: id, id: id }), [id, isRegist]);
    const renderDetailInfo = useMemo(() => (React.createElement(DetailTitleInfo
    // title={t('conference.reply')}
    , { 
        // title={t('conference.reply')}
        title: `${t('conference.reply')} ${isRegist
            ? isModify
                ? t('common.modify')
                : t('common.regist')
            : t('common.detail')}`, previousPage: previousPage })), [isRegist, isModify]);
    const renderButton = useMemo(() => (React.createElement("div", { key: 'button', style: isMobile ? { display: 'flex', justifyContent: 'flex-end' } : {} }, !isRegist ? (React.createElement(Button, { className: `btn_default ${isMobile ? 'sm' : 'xmd'}`, text: t('common.modify'), onClick: modifyPage })) : (React.createElement("div", { className: "btn-container" },
        React.createElement(Button, { className: `btn_white ${isMobile ? 'sm' : 'xmd'}`, text: t('common.cancel'), onClick: previousPage }),
        React.createElement(Button, { className: `btn_default ${isMobile ? 'sm' : 'xmd'}`, text: t('common.okay'), onClick: onSubmit }))))), [
        isRegist,
        isModify,
        registFile,
        title,
        week,
        selectYear,
        selectMonth,
        publicValue,
        startDate,
        startTime,
        endDate,
        endTime,
        members,
        keepMediaList,
    ]);
    if (isMobile) {
        return (React.createElement(DSTT007_DetailStyled, { className: "dst007-detail", "$isRegist": isRegist, "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        title: '자세히보기',
                        component: [
                            renderReplayInfo,
                            renderReplayTable,
                            rednerVideo,
                            renderWatchHistoryTable,
                            renderButton,
                        ],
                        styles: { gap: '24px', display: 'flex', flexDirection: 'column' },
                    },
                ], hideHr: true })));
    }
    return (React.createElement(DSTT007_DetailStyled, { className: "dst007-detail", "$isRegist": isRegist, "$isTablet": isTablet, "$isMobile": isMobile },
        React.createElement("div", { className: "dst007-container" },
            React.createElement("div", { className: "left-wrap" },
                isDesktop && renderDetailInfo,
                React.createElement("div", { className: "title-info" },
                    renderReplayInfo,
                    isTablet ? (React.createElement(React.Fragment, null,
                        renderReplayTable,
                        rednerVideo)) : (React.createElement("div", { className: "field-short" },
                        renderReplayTable,
                        rednerVideo))),
                renderWatchHistoryTable,
                renderButton))));
});
