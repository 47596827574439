//@ts-ignore
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../GlobalStateProvider';
import { DropDownOption } from '../option';
import Icon from './Icon';
export const DropDown = React.memo(({ noShowButtonArea = false, className, value, list, styles, option, isDisable = false, setDropValue, isHeaderTitle = false, setMenuOpen, excludeList, isSidebar, }) => {
    const { t } = useTranslation();
    const dropDownRef = useRef(null);
    const dropDownChildRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [dropItem, setDropItem] = useState(value);
    const { isDesktop, isMobile, isTablet } = useGlobalState();
    useEffect(() => {
        if (dropItem?.id !== value?.id)
            setDropItem(value);
    }, [value]);
    const dropOption = useMemo(() => _.merge({}, DropDownOption, option), [option]);
    const handlerDropDownClick = () => {
        if (noShowButtonArea) {
            const dropdownContent = dropDownRef.current;
            const dropdownChild = dropDownChildRef.current;
            if (dropdownContent && dropdownChild) {
                // 드롭다운을 표시 상태로 설정
                dropdownChild.style.display = 'block'; // 드롭다운을 표시 상태로 변경
                const parentRect = dropdownContent.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const dropdownHeight = dropdownChild.offsetHeight;
                // 드롭다운의 위치를 초기화
                dropdownChild.style.position = 'fixed';
                dropdownChild.style.top = 'auto';
                dropdownChild.style.bottom = 'auto';
                // 드롭다운이 아래로 넘치면 위로 표시
                if (parentRect.bottom + dropdownHeight > viewportHeight) {
                    dropdownChild.style.bottom = `${viewportHeight - parentRect.top + 30}px`;
                    dropdownChild.style.top = 'auto';
                }
                else {
                    // 그 외의 경우는 아래로 표시
                    dropdownChild.style.top = `${parentRect.bottom}px`;
                    dropdownChild.style.bottom = 'auto';
                }
                dropdownChild.style.left = `${parentRect.left}px`;
            }
            // 드롭다운을 숨김 상태로 되돌리기
            dropdownChild.style.display = ''; // 기본 상태로 변경
            const parentRect = dropdownContent?.getBoundingClientRect();
            dropdownChild.style.width = !isHeaderTitle ? ` ${parentRect.width}px` : undefined;
            setOpen(!open);
        }
        else {
            const dropdownContent = dropDownRef.current;
            if (dropdownContent) {
                const dropChild = dropDownChildRef.current;
                const parentRect = dropdownContent?.getBoundingClientRect();
                if (dropChild) {
                    dropChild.style.width = !isHeaderTitle
                        ? ` ${parentRect.width}px`
                        : undefined;
                    // dropChild.style.top = `${parentRect.y + parentRect.height + 10}px`; // 부모 요소의 아래에 위치하도록 top 값 설정
                    // dropChild.style.left = `${parentRect.x}px`; // 좌측 정렬
                }
            }
            setOpen(!open);
        }
    };
    const handleClickOutside = (event) => {
        const dropdownContent = dropDownRef.current;
        if (dropdownContent && !dropdownContent.contains(event.target)) {
            setOpen(false);
        }
    };
    const handleResize = () => setOpen(false);
    useEffect(() => {
        APP.eventManager.subscribe('scroll', handleResize);
        window.addEventListener('click', handleClickOutside);
        return () => {
            APP.eventManager.unsubscribe('scroll', handleResize);
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const handlerItemClick = (item) => {
        setDropValue(item);
        setDropItem(item);
        setOpen(false);
    };
    useEffect(() => {
        if (!setMenuOpen)
            return;
        setMenuOpen(open);
    }, [open]);
    /**
     * (Component) Select Title
     */
    const renderTitle = useMemo(() => {
        return (React.createElement(React.Fragment, null,
            dropOption.titleIcon.show && (React.createElement(Icon, { icon: dropOption.titleIcon.name, size: dropOption.titleIcon.size, fill: dropOption.titleIcon.color })),
            !isHeaderTitle && (React.createElement("p", { className: "placeholder overText", style: { flex: 1, ...styles?.titleText } }, t(`${dropItem?.title}`)))));
    }, [dropItem, dropOption.titleIcon, isHeaderTitle]);
    const renderItem = (item, index) => {
        if (excludeList && excludeList.includes(item.id))
            return null;
        const selected = dropItem?.id === item.id;
        return (React.createElement("div", { key: `${item.id}_${index}`, className: `${selected ? 'dropdown-label2' : 'dropdown-label'} non-hover`, style: styles?.bodyItem, onClick: () => handlerItemClick(item) },
            dropOption.itemIcon.show && (React.createElement(Icon, { icon: isDesktop
                    ? dropOption.itemIcon.name
                    : selected
                        ? `${item.id}_on`
                        : `${item.id}_off`, size: dropOption.itemIcon.size, fill: selected ? dropOption.itemIcon.focus : dropOption.itemIcon.color })),
            React.createElement("p", { className: `${selected ? 'placeholder-selected placeholder2' : 'placeholder2'} overText`, style: { flex: 1, ...styles?.itemText } }, t(`${item.title}`))));
    };
    return (React.createElement("div", { ref: dropDownRef, className: `${isHeaderTitle ? '' : 'dropdown'} ${isDisable ? 'disabled' : ''} ${className} ${isMobile ? 'mobile_drop' : isTablet ? 'tablet_drop' : 'pc_drop'}`, style: { ...styles?.dropWrap, cursor: isDisable ? 'default' : 'pointer' }, onMouseDown: open ? handleClickOutside : undefined },
        React.createElement("div", { className: isHeaderTitle ? '' : 'dropdown-field', style: styles?.titleWrap, onClick: isDisable ? undefined : handlerDropDownClick },
            renderTitle,
            !isDisable && dropOption.downIcon.show && (React.createElement(Icon, { icon: dropOption.downIcon.name, className: isSidebar ? open ? 'arrow-down' : 'arrow-up' : open ? 'arrow-up' : 'arrow-down', size: dropOption.downIcon.size, stroke: dropOption.downIcon.color, fill: dropOption.downIcon.color }))),
        list && list.length > 0 && (React.createElement("div", { ref: dropDownChildRef, className: `dropdown-list ${isSidebar ? 'sidebar-dropdown-list' : ''}`, style: {
                display: open ? 'flex' : 'none',
                ...styles?.itemWrap,
            } }, list.map((item, index) => renderItem(item, index))))));
});
