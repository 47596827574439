import React from 'react';
import { Colors } from '../../Colors';
import { useGlobalState } from '../../GlobalStateProvider';
import { Button } from '../element';
export const FileDown = React.memo(({ files }) => {
    const { isMobile } = useGlobalState();
    const downloadFile = (url) => {
        const formattedUrl = url.replace(/\s/g, '%20');
        const a = document.createElement('a');
        a.href = formattedUrl; // 다운로드 URL 설정
        a.download = url.split('/').pop() || 'download'; // 다운로드 파일명 설정
        document.body.appendChild(a); // a 요소를 문서에 추가
        a.click();
        document.body.removeChild(a); // a 요소를 문서에서 제거
    };
    return !files || (files && files.length <= 0) ? (React.createElement("div", { className: "value" }, " - ")) : (files?.map((item, index) => (React.createElement(Button, { key: index, className: `btn_sliver md_round center body5 cursor attach_item`, text: typeof item === 'string'
            ? item.split('/')[item.split('/').length - 1]
            : //@ts-ignore
                item.name, styles: {
            buttonWrap: {
                height: 'fit-content',
                maxWidth: isMobile ? 180 : 220,
            },
        }, option: {
            buttonBeforeIcon: {
                name: 'file',
                color: Colors.w_grey,
                size: isMobile ? 12 : 20,
                show: true,
            },
        }, onClick: () => downloadFile(item) }))));
});
